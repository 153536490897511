import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getAllTestimonials = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/testimonial/all-testimonials`, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createTestimonial = async ({
  tName,
  tImage,
  tDescription,
  storeName,
  }) => {
  let formData = new FormData();
  formData.append("tImage", tImage);
  formData.append("tName", tName);
  formData.append("storeName",storeName);
  formData.append("tDescription", tDescription);

  try {
    let res = await axios.post(
      `${apiURL}/api/testimonial/add-testimonial`,
      formData,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editTestimonial = async (tId, des) => {
  let data = { tId: tId, tDescription: des };
  try {
    let res = await axios.post(
      `${apiURL}/api/testimonial/edit-testimonial`,
      data,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteTestimonial = async (tId) => {
  try {
    let res = await axios.post(
      `${apiURL}/api/testimonial/delete-testimonial`,
      { tId },
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
