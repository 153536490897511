import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getAllUser = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/user/all-user`, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createUser = async ({
  cName,
  cImage,
  cDescription,
  cStatus,
}) => {
  let formData = new FormData();
  formData.append("cImage", cImage);
  formData.append("cName", cName);
  formData.append("cDescription", cDescription);
  formData.append("cStatus", cStatus);

  try {
    let res = await axios.post(
      `${apiURL}/api/user/add-user`,
      formData,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editUser = async (uId, des, status) => {
  let data = { uId: uId, uDescription: des, uStatus: status };
  try {
    let res = await axios.post(
      `${apiURL}/api/user/edit-user`,
      data,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteUser = async (uId) => {
  try {
    let res = await axios.post(
      `${apiURL}/api/user/delete-user`,
      { uId },
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
