import React, { Fragment, createContext} from "react";
// import { Navber, Footer, CartModal } from "../partials";
import { Navber, Footer } from "../partials";
import { CartModal } from "../../market/partials";
//for time being i'm calling cart from market since i have changed only there
import LoginSignup from "../../market/auth/LoginSignup";
import "./style.css";

export const LayoutContext = createContext();

const Layout = ({ children }) => {
  return (
    <Fragment>
      <div className="store_wrapper">
        <Navber />
        <LoginSignup />
        <CartModal />
        
        {/* All Children pass from here */}
        
        {children}
        {/*<div className="push"></div>*/}
      </div>
      <Footer />
    </Fragment>
  );
};

export default Layout;

export const Layout1 = ({ children }) => {
  return (
    <Fragment>
      <div> 
        <Navber />
        <LoginSignup />
        <CartModal />
        {/* All Children pass from here */}
        {children}
        
      </div>
      
      <Footer/>
    </Fragment>
  );
};
export const Layout2 = ({ children }) => {
  return (
    <Fragment>
      <div className="wrapper">
        <Navber />
        <LoginSignup />
        <CartModal />

        {/* All Children pass from here */}
        
        {children}
        <div className="push"></div>
      </div>
      <Footer />
    </Fragment>
  );
};

