import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};


export const getAllBlogs = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/blog/all-blogs`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createBlog = async ({
  bName,
  bImage,
  bDescription,
  }) => {
  let formData = new FormData();
  formData.append("bImage", bImage);
  formData.append("bName", bName);
  formData.append("bDescription", bDescription);

  try {
    let res = await axios.post(
      `${apiURL}/api/blog/add-blog`,
      formData,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editBlog = async (bId, des) => {
  let data = { bId: bId, bDescription: des };
  try {
    let res = await axios.post(
      `${apiURL}/api/blog/edit-blog`,
      data,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteBlog = async (bId) => {
  try {
    let res = await axios.post(
      `${apiURL}/api/blog/delete-blog`,
      { bId },
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
