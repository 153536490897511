import { addItemToCart, getCartItems } from "./FetchApi";

export const cartList = (carts) => {
  return carts.map((cart) => cart.productId)
};



//change this logic if you want to implement eligible products also
let updateStoreWiseProduct = new Map();
export const updateStoreWiseProducts = (carts) => {
  updateStoreWiseProduct.clear()
  if (carts !== null) {
    for (let cart of carts) {
      if (!updateStoreWiseProduct.has(cart.productStoreId))
        updateStoreWiseProduct.set(cart.productStoreId, cart.productPrice * cart.productQuantity);
      else
        updateStoreWiseProduct.set(cart.productStoreId, updateStoreWiseProduct.get(cart.productStoreId) + cart.productPrice * cart.productQuantity)
    }

    const mapToObject = (map) => {
      let obj = {};
      map.forEach((value, key) => {
        obj[key] = value;
      });

      return obj;
    };

    return mapToObject(updateStoreWiseProduct);
  } else {
    return (updateStoreWiseProduct = null);
  }
}



export const updateQuantity = (
  type,
  totalQuantitiy,
  quantitiy,
  setQuantitiy,
  setAlertq
) => {
  if (type === "increase") {
    if (quantitiy === totalQuantitiy) {
      setAlertq(true);
    } else {
      setQuantitiy(quantitiy + 1);
    }
  } else if (type === "decrease") {
    if (quantitiy === 1) {
      setQuantitiy(1);
      setAlertq(false);
    } else {
      setQuantitiy(quantitiy - 1);
    }
  }
};

export const slideImage = (type, active, count, setCount, pImages) => {
  if (active === count) {
    return true;
  }
  if (type === "increase") {
    if (count === pImages.length - 1) {
      setCount(0);
    } else if (count < pImages.length) {
      setCount(count + 1);
    }
  } else if (type === "decrease") {
    if (count === 0) {
      setCount(pImages.length - 1);
    } else if (count < pImages.length) {
      setCount(count - 1);
    }
  } else {
    setCount(active);
  }
};

export const inCart = (id) => {
  if (localStorage.getItem("cart")) {
    let cartProducts = JSON.parse(localStorage.getItem("cart"));
    for (let product of cartProducts) {
      if (product.id === id) {
        return true;
      }
    }
  }
  return false;
};

export const addToCart = async (
  id,
  quantitiy,
  price,
  storeId,
  size,
  productPhotoUrl,
  productName,
  layoutData,
  layoutDispatch,
  setQuantitiy,
  setAlertq,
  fetchData,
  totalCost,
  affiliateId,
) => {
  const data = {
    "productId": id,
    "productSize": size,
    "productQuantity": quantitiy,
    "productPrice": price,
    "productStoreId": storeId,
    "productPhotoUrl":productPhotoUrl,
    "productName" : productName,
  }
  let isObj = false;
  let cart = await addItemToCart(data) || [];
  cart = cart.cartProducts || []

  if (cart.length > 0) {
    cart.forEach((item) => {
      if (item.productId === id) {
        isObj = true;
      }
    });
    if (!isObj) {
      cart = await addItemToCart(data)
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  } else {
    // cart.push({ id, quantitiy, price, storeId });
    cart = await addItemToCart(data)
    localStorage.setItem("cart", JSON.stringify(cart));
  }
  // const cartListItems = await 
  // console.log("cartList : ",cartListItems)
  layoutDispatch({ type: "cartItems", payload: cart })
  layoutDispatch({ type: "inCart", payload: cartList(cart) });
  layoutDispatch({ type: "storeWiseProducts", payload: updateStoreWiseProducts(cart) });
  layoutDispatch({ type: "cartTotalCost", payload: totalCost(layoutData) });
  setQuantitiy(1);
  setAlertq(false);
  fetchData();
};
