import {
  DashboardData,
  postUploadImage,
  getSliderImages,
  postDeleteImage,
  getBlogs,
  getSliderImagesMobile
} from "./FetchApi";
import { getAllOrder } from "../orders/FetchApi.js";

export const GetAllData = async (dispatch) => {
  let responseData = await DashboardData();
  if (responseData) {
    dispatch({ type: "totalData", payload: responseData });
  }
};

export const todayAllOrders = async (dispatch) => {
  let responseData = await getAllOrder();
  if (responseData) {
    dispatch({ type: "totalOrders", payload: responseData });
  }
};

export const sliderImages = async (dispatch) => {
  try {
    let responseData = await getSliderImages("market");
    let responseDataM = await getSliderImagesMobile("market");
    if (responseData && responseData.Images) {
      console.log("slides:",responseData.Images);
      dispatch({ type: "sliderImages", payload: responseData.Images });
    }
    if (responseDataM && responseDataM.Images) {
      dispatch({ type: "sliderImagesMobile", payload: responseDataM.Images });
    }
  } catch (error) {
    console.log(error);
  }
};

export const blogsData = async (dispatch) => {
  try {
    let responseData = await getBlogs();
    if (responseData && responseData.Blogs) {
      dispatch({ type: "blogsData", payload: responseData.Blogs });
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteImage = async (id, dispatch) => {
  dispatch({ type: "imageUpload", payload: true });
  try {
    let responseData = await postDeleteImage(id);
    if (responseData && responseData.success) {
      setTimeout(function () {
        sliderImages(dispatch);
        dispatch({ type: "imageUpload", payload: false });
      }, 1000);
    }
  } catch (error) {
    console.log(error);
  }
};

export const uploadImage = async (image, deviceType, storeId, dispatch) => {
  dispatch({ type: "imageUpload", payload: true });
  let formData = new FormData();
  formData.append("image", image);
  formData.append("device", deviceType);
  formData.append("storeId", storeId);
  try {
    let responseData = await postUploadImage(formData);
    if (responseData && responseData.success) {
      setTimeout(function () {
        dispatch({ type: "imageUpload", payload: false });
        sliderImages(dispatch);
      }, 1000);
    }
  } catch (error) {
    console.log(error);
  }
};
