import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getAllStoreSection = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/storeSection/all-storeSection`, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createStoreSection = async ({
  ssName,
  ssImage,
  ssDescription,
  ssStatus,
}) => {
  let formData = new FormData();
  formData.append("ssImage", ssImage);
  formData.append("ssName", ssName);
  formData.append("ssDescription", ssDescription);
  formData.append("ssStatus", ssStatus);

  try {
    let res = await axios.post(
      `${apiURL}/api/storeSection/add-storeSection`,
      formData,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editStoreSection = async (name, ssId, des, status) => {
  let data = {ssName: name, ssId: ssId, ssDescription: des, ssStatus: status };
  console.log("edit_data:", data);
  try {
    let res = await axios.post(
      `${apiURL}/api/storeSection/edit-storeSection`,
      data,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const StoreByStoreSection = async (ssId) => {
  try {
    let res = await axios.post(`${apiURL}/api/storeSection/store-by-storeSection`, { ssId });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};


export const deleteStoreSection = async (ssId) => {
  try {
    let res = await axios.post(
      `${apiURL}/api/storeSection/delete-storeSection`,
      { ssId },
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
