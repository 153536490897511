import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getAllStore = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/store/all-store`, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createStore = async ({
  sName,
  sImage,
  GST,
  sAddress,
  sPincode,
  sSignature,
  sLogo,
  sDescription,
  sStatus,
  sSection,
  sInvoceCount,
  commisionRate,
  Admin,
}) => {
  let formData = new FormData();
  formData.append("sImage", sImage);
  formData.append("sSignature", sSignature);
  formData.append("sLogo", sLogo);
  formData.append("sAddress", sAddress);
  formData.append("sPincode", sPincode);
  formData.append("sName", sName);
  formData.append("GST", GST);
  formData.append("sDescription", sDescription);
  formData.append("sStatus", sStatus);
  formData.append("Admin", Admin);
  formData.append("sSection",sSection);
  formData.append("sInvoceCount",sInvoceCount);
  formData.append("commisionRate",commisionRate);

  try {
    let res = await axios.post(
      `${apiURL}/api/store/add-store`,
      formData,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editStore = async (store) => {
  let formData = new FormData();
  if (store.editImage) {
    console.log("editImage:",store.editImage);
    formData.append("editImage", store.editImage);
  }
   formData.append("sName", store.name);
   formData.append("sId", store.sId);
   formData.append("sImage", store.image);
   formData.append("sDescription", store.des);
   formData.append("Admin", store.admin);
   formData.append("sSection",store.section)
   formData.append("sStatus", store.status);

  try {
    let res = await axios.post(
      `${apiURL}/api/store/edit-store`,
      formData,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getStoreByStoreSection = async (ssId) => {
  try {
    let res = await axios.post(`${apiURL}/api/store/store-by-storeSection`, { ssId });
    console.log("Section Id : ",ssId)
    return res.data;
  } catch (error) {
    console.log(error);
  }
};


export const deleteStore = async (sId) => {
  try {
    let res = await axios.post(
      `${apiURL}/api/store/delete-store`,
      { sId },
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
