import React, { Fragment, useEffect, useContext, useState } from "react";
import OrderSuccessMessage from "./OrderSuccessMessage";
import gif from '../../../images/a3ceb0b2-0442-43fe-8442-93a46923d92a.gif'
import gif2 from '../../../images/download.gif'
import TextBanner from '../../../images/THE_STREET_REVELUTION.png'
import { prevSlide, nextSlide } from "./Mixins";
import { LayoutContext } from "../layout";
import { sliderImages } from "../../superAdmin/dashboardAdmin/Action";

const apiURL = process.env.REACT_APP_API_URL;

const Slider = (props) => {
  const { data, dispatch } = useContext(LayoutContext);
  const [slide, setSlide] = useState(0);
  const [slideM, setSlideM] = useState(0);

  useEffect(() => {
    sliderImages(dispatch);
  }, []);

  return (
    <Fragment>
      {/* <div className="relative bg-gray-100 hide" style={{marginTop:"10vh"}}>
        {data.sliderImages.length > 0 ? (
          <img
            className="slider"
            style={{ margin: "0 auto"}}
            src={`${data.sliderImages[slide].url}`}
            alt="sliderImage" 
          />
        ) : (
          ""
        )}
        <svg
          onClick={(e) => prevSlide(data.sliderImages.length, slide, setSlide)}
          className={`z-10 absolute top-50 left-0 mt-0 flex justify-end hide items-center box-border flex justify-center w-12 h-12 text-gray-700  cursor-pointer hover:text-yellow-700`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
        <svg
          onClick={(e) => nextSlide(data.sliderImages.length, slide, setSlide)}
          className={`z-10 absolute top-50 right-0 mt-0 flex justify-start hide items-center box-border flex justify-center w-12 h-12 text-gray-700 cursor-pointer hover:text-yellow-700`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
      <div className="relative bg-gray-100 hide_m" style={{marginTop:"10vh"}}>
        {data.sliderImagesMobile && data.sliderImagesMobile.length > 0 ? (
          <img
            className="slider hide_m"
            style={{ margin: "0 auto"}}
            src={`${data.sliderImagesMobile[slideM].url}`}
            alt="sliderImage" 
          />
        ) : (
          ""
        )}
        <svg
          onClick={(e) => prevSlide(data.sliderImagesMobile.length, slideM, setSlideM)}
          className={`z-10 absolute top-50 left-0 mt-0 flex justify-end items-center box-border flex justify-center w-12 h-12 text-gray-700  cursor-pointer hover:text-yellow-700 hide_m`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
        <svg
          onClick={(e) => nextSlide(data.sliderImagesMobile.length, slideM, setSlideM)}
          className={`z-10 absolute top-50 right-0 mt-0 flex items-center box-border flex justify-center w-12 h-12 text-gray-700 cursor-pointer hover:text-yellow-700 hide_m`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div> */}
      <div className="h-20 md:h-24" style={{background: "#FBEBDB"}}>
jasl
      </div>
      <div className="flex items-center justify-center p-2 md:p-8 md:pb-12 " style={{background: "#FBEBDB"}}>
        <img src={TextBanner} className=" absolute w-5/12"/>
        <img src={gif2} className=" h-56 md:h-80  w-6/12" />
        <img src={gif} className="w-6/12  h-56 md:h-80 " />
      </div>
      <OrderSuccessMessage />
    </Fragment>
  );
};

export default Slider;
