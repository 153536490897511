import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const DashboardData = async () => {
  try {
    let res = await axios.post(`${apiURL}/api/customize/dashboard-data`,{},{ withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSliderImages = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/customize/get-slide-image`,{ withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getSliderImagesMobile = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/customize/get-slide-image-mobile`,{ withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBlogs = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/blog/all-blogs`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const postUploadImage = async (formData) => {
  try {
    let res = await axios.post(
      `${apiURL}/api/customize/upload-slide-image_market`,
      formData,
      { withCredentials: true }
    );
    console.log("res:",res.json);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const postDeleteImage = async (id) => {
  try {
    let res = await axios.post(`${apiURL}/api/customize/delete-slide-image`, {
      id,
    },
    { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSuperAdmin = async () => {
  try {
    let res = await axios.post(`${apiURL}/api/get-super-admin`,{},{ withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
