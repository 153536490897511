import axios from "axios";
import { useEffect, useState } from "react";
import AsyncLocalStorage from '@createnextapp/async-local-storage'
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
      ? JSON.parse(localStorage.getItem("jwt")).token
      : false;
const Headers = () => {
  return {
      headers: {
          token: `Bearer ${BearerToken()}`
      },
  };
};

export const isAuthenticate = () =>
  localStorage.getItem("jwt") ? true : false;


export const isAdmin = async(storeName) =>{
  try{
    if(isAuthenticate()){
      let res = await axios.post(`${apiURL}/api/get-store-admin`,{},{ withCredentials: true });      
      return res.data.isAdmin && res.data.storeNames.includes(storeName)
    }
    else
      return false;
   
  }catch(err){
    console.log("kuchiku : ",err)
    console.log(err)
  }
}
export const loginReq = async ({ email, password }) => {
  const data = { email, password };
  try {
    let res = await axios.post(`${apiURL}/api/signin`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const signupReq = async ({ fullname, email, password, cPassword, mobile }) => {
  const data = { fullname, email, password, cPassword, mobile };
  try {
    let res = await axios.post(`${apiURL}/api/signup`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
