import React, { Fragment, useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { LayoutContext } from "../layout";
import { subTotal, quantity, totalCost } from "../partials/Mixins";
import { cartListProduct } from "../partials/FetchApi";
import { deleteAddress, getBrainTreeToken, getPaymentProcess, getSavedAddresses, saveNewAddress } from "./FetchApi";
import { fetchData, fetchbrainTree, pay, fetchLocationData } from "./Action";
import CouponModal from "./CouponModal";
import { Button, Modal, Paper } from "@material-ui/core";
import ChangeSelectedAddressModal from "./ChangeSelectedAddressModal";


//BUG : Cannot type pincode


export const CheckoutComponent = (props) => {
  const history = useHistory();
  const [savedAddressAvailable, setSavedAddressAvailable] = useState(false)
  const [savedAddresses, setSavedAddress] = useState(null)
  const [selectedAddressId, setSelectedAddressId] = useState(null)
  const [savedAddressModal, setSavedAddressModal] = useState(false)
  const [addNewAddress, setAddNewAddress] = useState(false)
  const { data, dispatch } = useContext(LayoutContext);
  const globalStoreId = '000000000000100000000000'


  const [state, setState] = useState({
    name: "",
    address: "",
    phone: "",
    pincode: "",
    district: "",
    state: "",
    city: "",
    error: false,
    success: false,
    clientToken: null,
    instance: {},
  });

  const openSavedAddressModal = () => {
    setSavedAddressModal(true);
  }

  const handleCloseSavedAddressModal = () => {
    setSavedAddressModal(false)
  }

  const handleAddNewAddress = () => {
    setSelectedAddressId(null)
    setState((state) => ({
      ...state,
      name: "",
      address: "",
      phone: "",
      pincode: "",
      district: "",
      state: "",
      city: ""
    }));
    setAddNewAddress(true)

  }

  const saveAddressBeforePayment = async () => {
    if (selectedAddressId === null) {
      const res = await saveNewAddress(state)
      //Handle here for success/failure of address saving
    }
  }

  const changeSelectedAddress = () => {
    if (savedAddresses && selectedAddressId) {

      const savedAddress = savedAddresses.filter((savedAddress) => savedAddress._id === selectedAddressId)

      if (savedAddress.length > 0) {
        setState((state) => ({
          ...state,
          name: savedAddress[0].receiverName,
          address: savedAddress[0].receiverAddress,
          phone: savedAddress[0].receiverContactNumber,
          pincode: savedAddress[0].receiverPincode,
          district: savedAddress[0].receiverDistrict,
          state: savedAddress[0].receiverState,
          city: savedAddress[0].receiverCity
        }));
      }
      setAddNewAddress(false)
    }
  }

  useEffect(() => {
    changeSelectedAddress()
  }, [selectedAddressId])


  const deleteSavedAddress = async (deleteAddressId) => {
    const res = await deleteAddress(deleteAddressId);
    if (res && res.savedAddress.length > 0) {
      setSavedAddress(res.savedAddress)
      if (deleteAddressId === selectedAddressId) {
        setSelectedAddressId(res.savedAddress[0]._id)
      }
    }
    else {
      setSavedAddress(null)
      setSelectedAddressId(null)
      handleAddNewAddress()
      setSavedAddressModal(false)
    }
  }

  const getSavedAddress = async () => {
    let savedAddress = await getSavedAddresses();
    dispatch({ type: "loading", payload: true });
    if (savedAddress.length > 0) {
      setSelectedAddressId(savedAddress[0]._id)
      setTimeout(() => {
        setState({
          name: savedAddress[0].receiverName,
          address: savedAddress[0].receiverAddress,
          phone: savedAddress[0].receiverContactNumber,
          pincode: savedAddress[0].receiverPincode,
          district: savedAddress[0].receiverDistrict,
          state: savedAddress[0].receiverState,
          city: savedAddress[0].receiverCity
        });
        setSavedAddressAvailable(true);
        setSavedAddress(savedAddress);
      }, 100)
    }
    else {
      setAddNewAddress(true)
    }
  }

  const handlePincodeChange = async (e) => {
    const pincode = e.target.value;

    // Ensure pincode is at most 6 characters
    if (pincode.length <= 6) {
      setState((prevState) => ({
        ...prevState,
        pincode: pincode, // Update pincode field
      }));

      // Proceed to fetch location data only when pincode is exactly 6 digits
      if (pincode.length === 6) {
        try {
          const locationData = await fetchLocationData(pincode);
          setState((prevState) => ({
            ...prevState,
            district: locationData.district,
            state: locationData.state,
            error: false, // Reset error if fetch is successful
          }));
        } catch (error) {
          setState((prevState) => ({
            ...prevState,
            district: "",
            state: "",
            error: "Invalid pincode", // Handle error in case of invalid pincode
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          district: "",
          state: "",
          error: "Pincode should be 6 digits", // Set error for short pincode
        }));
      }
    }
  };


  const fetchAllData = () => {
    fetchData(cartListProduct, dispatch);
    fetchbrainTree(getBrainTreeToken, setState);
    getSavedAddress()
  }
  useEffect(() => {
    fetchAllData()
  }, [dispatch]);

  if (data.loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
        Please wait until finished
      </div>
    );
  }

  return (
    <Fragment>
      <section className="mx-4 mt-32 md:mx-20 md:mt-64 lg:mt-32">
        <div className="text-2xl mx-2">Order</div>
        <CheckoutProducts products={data.cartItems || []} />
        <ApplyCoupon store={globalStoreId}></ApplyCoupon>
        <div className="flex flex-col md:flex md:space-x-2 md:flex-row">


          <div className="w-full order-first md:order-last md:w-1/2">
            {state.clientToken ? (
              <Fragment>

                <div
                  onBlur={() => setState({ ...state, error: false })}
                  className="p-4 md:p-8"
                >
                  {state.error && (
                    <div className="bg-red-200 py-2 px-4 rounded">
                      {state.error}
                    </div>
                  )}
                  {savedAddresses && !addNewAddress &&

                    <Paper elevation={3}>
                      <div>{state.name}</div>
                      <div>{state.phone}</div>
                      <div>{state.address} , {state.city} , {state.district}</div>
                      <div>{state.pincode}</div>
                      <div>
                        <Button onClick={openSavedAddressModal}>Select different address</Button>
                        <Button onClick={handleAddNewAddress}>Add new address</Button>
                      </div>
                    </Paper>



                  }
                  {addNewAddress &&
                    <Button onClick={() => {
                      openSavedAddressModal()
                    }}>Select From Saved Address</Button>
                  }
                  <Modal
                    open={savedAddressModal}
                    onClose={handleCloseSavedAddressModal}
                  >
                    <ChangeSelectedAddressModal availableAddresses={savedAddresses} selectedAddressId={selectedAddressId} setSelectedAddressId={setSelectedAddressId} onClose={handleCloseSavedAddressModal} deleteSavedAddress={deleteSavedAddress}></ChangeSelectedAddressModal>
                  </Modal>

                  {
                    addNewAddress &&
                    <div>
                      <div className="flex flex-col py-2 mb-2">
                        <label htmlFor="mobile" className="pb-2">
                          Name
                        </label>
                        <input
                          value={state.name}
                          onChange={(e) =>
                            setState({
                              ...state,
                              name: e.target.value,
                              error: false,
                            })
                          }
                          id="name"
                          className="border px-4 py-2"
                          placeholder="Enter your name"
                        />
                      </div>
                      <div className="flex flex-col py-2 mb-2">
                        <label htmlFor="mobile" className="pb-2">
                          Phone
                        </label>
                        <input
                          value={state.phone}
                          onChange={(e) =>
                            setState({
                              ...state,
                              phone: e.target.value,
                              error: false,
                            })
                          }
                          id="phone"
                          className="border px-4 py-2"
                          placeholder="+91 "
                        />
                      </div>
                      <div className="flex flex-col py-2">
                        <label htmlFor="address" className="pb-2">
                          Delivery Address
                        </label>
                        <input
                          value={state.address}
                          onChange={(e) =>
                            setState({
                              ...state,
                              address: e.target.value,
                              error: false,
                            })
                          }
                          type="text"
                          id="address"
                          className="border px-4 py-2"
                          placeholder="Address..."
                        />
                      </div>
                      <div className="flex flex-col py-2">
                        <label htmlFor="city" className="pb-2">
                          City
                        </label>
                        <input
                          value={state.city}
                          onChange={(e) =>
                            setState({
                              ...state,
                              city: e.target.value,
                              error: false,
                            })
                          }
                          type="text"
                          id="city"
                          className="border px-4 py-2"
                          placeholder="City..."
                        />
                      </div>
                      <div className="flex flex-col py-2 mb-2">
                        <label htmlFor="pincode" className="pb-2">
                          Pincode
                        </label>
                        <input
                          value={state.pincode}
                          onChange={handlePincodeChange}
                          type="number"
                          id="pincode"
                          className="border px-4 py-2"
                          placeholder="Pincode..."
                        />
                      </div>
                      <div className="flex flex-col py-2 mb-2">
                        <label htmlFor="district" className="pb-2">
                          District
                        </label>
                        <input
                          value={state.district}
                          onChange={(e) =>
                            setState({
                              ...state,
                              district: e.target.value,
                              error: false,
                            })
                          }
                          type="text"
                          id="district"
                          className="border px-4 py-2"
                          placeholder="District..."
                        />
                      </div>
                      <div className="flex flex-col py-2 mb-2">
                        <label htmlFor="state" className="pb-2">
                          State
                        </label>
                        <input
                          value={state.state}
                          onChange={(e) =>
                            setState({
                              ...state,
                              state: e.target.value,
                              error: false,
                            })
                          }
                          type="text"
                          id="state"
                          className="border px-4 py-2"
                          placeholder="State..."
                        />
                      </div>
                    </div>
                  }
                  <div
                    onClick={() => {
                      saveAddressBeforePayment()
                      pay(
                        data,
                        dispatch,
                        state,
                        data.storeWiseProducts,
                        setState,
                        getPaymentProcess,
                        totalCost,
                        history
                      )
                    }
                    }
                    className="w-full px-4 py-2 text-center text-white font-semibold cursor-pointer"
                    style={{ background: "#303031" }}
                  >
                    {addNewAddress ? "Save and " : ""}
                    Pay now
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="flex items-center justify-center py-12">
                <svg
                  className="w-12 h-12 animate-spin text-gray-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  ></path>
                </svg>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};


const ApplyCoupon = ({ store }) => {
  console.log("store here in applycoupon : ", store)
  const { data, dispatch } = useContext(LayoutContext);
  const [currentStoreId, setCurrentStoreId] = useState(null)
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (storeId) => {
    setCurrentStoreId(storeId);
    setShowModal(true);
  }
  const handleCloseModal = () => setShowModal(false);


  return (
    <Fragment>
      <div className="md:w-1/2">
        {console.log("data.couponName : ", data.storeWiseProducts)}
        {console.log("coupon : ", data.appliedCoupons[store])}

        <div>
          <button onClick={(e) => handleOpenModal(store)}>Apply coupon</button>
          {showModal && (<CouponModal
            show={showModal}
            onClose={handleCloseModal}
            storeId={currentStoreId}
          />)}

        </div>

      </div>
    </Fragment>
  )

};







const CheckoutProducts = ({ products }) => {
  const history = useHistory();
  const { data, dispatch } = useContext(LayoutContext);
  const [coupon, setCoupon] = useState(data.appliedCoupons || {});
  const [currentStoreId, setCurrentStoreId] = useState(null)

  const [showModal, setShowModal] = useState(false);

  // Group products by store
  const productsByStore = products.reduce((acc, product) => {
    const store = product.productStoreId; // Assuming `store` is a property in the product object
    if (!acc[store]) {
      acc[store] = [];
    }
    acc[store].push(product);
    return acc;
  }, {});




  // Calculate total quantity and subtotal for each store
  const storeTotals = Object.keys(productsByStore).map(store => {

    const storeProducts = productsByStore[store];
    const subtotal = storeProducts.reduce((sum, product) => sum + subTotal(product.productId, product.productPrice, data), 0);
    return {
      store,
      subtotal,
      products: storeProducts
    };
  });

  useEffect(() => {
    storeTotals.forEach(({ store, subtotal }) => {
      dispatch({ type: "applyCouponToStoreWiseProducts", storeId: store, price: subtotal });
    });
  }, [data.appliedCoupons]);

  return (
    <Fragment>
      {storeTotals.length > 0 ? (
        storeTotals.map(({ store, subtotal, products }, index) => (
          <div key={index} className="mb-6">
            <div className="text-xl font-semibold mb-2">{store}</div>
            <div className="grid grid-cols-2 md:grid-cols-1">
              {products.map((product, index) => (
                <div
                  key={index}
                  className="col-span-1 m-2 md:py-6 md:border-t md:border-b md:my-2 md:mx-0 md:flex md:items-center md:justify-between"
                >
                  <div className="md:flex md:items-center md:space-x-4">
                    <img
                      onClick={() => history.push(`/products/${product._id}`)}
                      className="cursor-pointer md:h-20 md:w-20 object-cover object-center"
                      src={product.productPhotoUrl}
                      alt="Product"
                    />
                    <div className="text-lg md:ml-6 truncate">{product.pName}</div>
                    <div className="md:ml-6 font-semibold text-gray-600 text-sm">
                      Price: &#8377;{product.productPrice}.00
                    </div>
                    <div className="md:ml-6 font-semibold text-gray-600 text-sm">
                      Size: {product.productSize}
                    </div>
                    <div className="md:ml-6 font-semibold text-gray-600 text-sm">
                      Quantity: {quantity(product.productId, data)}
                    </div>
                    <div className="font-semibold text-gray-600 text-sm">
                      Subtotal: &#8377;{subTotal(product.productId, product.productPrice, data)}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="text-lg font-semibold">
              Total Subtotal for {store}: &#8377;{subtotal}
            </div>
          </div>
        ))
      ) : (
        <div>No products found for checkout</div>
      )}
      <div className="text-2xl mx-2">Total : {totalCost(data)}</div>
    </Fragment>
  );
};

export default CheckoutProducts;
