import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const DashboardData = async () => {
  try {
    let res = await axios.post(`${apiURL}/api/customize/dashboard-data`,{}, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSliderImages = async (storeId) => {
  if(storeId){
    try {
      let res = await axios.post(`${apiURL}/api/customize/get-store-slide-image`,{ storeId },{ withCredentials: true });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
};

export const getSliderImagesMobile = async (storeId) => {
  if(storeId){
    try {
      let res = await axios.post(`${apiURL}/api/customize/get-store-slide-image-mobile`,{ storeId },{ withCredentials: true });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
};

export const getBlogs = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/blog/all-blogs`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const postUploadImage = async (formData) => {
  try {
    let res = await axios.post(
      `${apiURL}/api/customize/upload-slide-image`,
      formData,
      { withCredentials: true }
    );  
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const postDeleteImage = async (id) => {
  try {
    let res = await axios.post(`${apiURL}/api/customize/delete-slide-image`, {
      id,
    },
    { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getStoreAdmin = async () => {
  try {
    let res = await axios.post(`${apiURL}/api/get-store-admin`,{},{ withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
