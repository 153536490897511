import { getCartItems } from "./FetchApi";



const calculateStoreWiseQuantity = (carts) => {
  let storeWiseQuantities = []
  carts.map((cart) => {
    if (storeWiseQuantities[cart.productStoreId] === undefined) {
      storeWiseQuantities[cart.productStoreId] = 1;
    }
    else {
      storeWiseQuantities[cart.productStoreId]++;
    }
  })
  return storeWiseQuantities
}

export const subTotal = (id, price, data) => {
  let subTotalCost = 0;
  let carts = data.cartItems;
  carts = carts || []
  const storeWiseQuantities = calculateStoreWiseQuantity(carts);
  carts.filter((item) => {
    if (item.productId === id) {
      subTotalCost = item.productQuantity * price;
      if (data.appliedCoupons && data.appliedCoupons[item.productStoreId] !== undefined) {
        const coupon = data.appliedCoupons[item.productStoreId];
        subTotalCost -= coupon.applicableDiscountType === "percentage" ? (subTotalCost * coupon.applicableDiscountValue / 100) : coupon.applicableDiscountValue / storeWiseQuantities[item.productStoreId];
      }

    }
  });
  return subTotalCost

};

export const quantity = (id, data) => {
  let product = 0;
  let carts = data.cartItems
  carts.filter((item) => {
    if (item.productId === id) {
      product = item.productQuantity;
    }
  });

  return product;
};

export const size = (id, data) => {
  let product = 0;
  let carts = data.cartItems
  carts.filter((item) => {
    if (item.productId === id) {
      product = item.productSize;
    }
  });

  return product;
};

export const applyCouponsIfAvailable = (data, storeWiseProducts) => {

  for (let key of Object.keys(storeWiseProducts)) {
    if (data.appliedCoupons[key] !== undefined) {
      storeWiseProducts[key] -= (data.appliedCoupons[key].applicableDiscountType === "fixed" ? data.appliedCoupons[key].applicableDiscountValue : storeWiseProducts[key] * data.appliedCoupons[key].applicableDiscountValue / 100);

    }
  }
  return storeWiseProducts;
}

export const totalCost = (data) => {

  let totalCost = 0;
  const storeWiseProducts = data.storeWiseProducts

  if (storeWiseProducts && typeof storeWiseProducts === 'object') {

    const values = Object.values(storeWiseProducts);
    for (let value of Object.values(storeWiseProducts)) {

      totalCost += value;
    }
  }
  let globalCoupon;
  if (data.appliedCoupons['000000000000100000000000'] !== undefined) {
    globalCoupon = data.appliedCoupons['000000000000100000000000']
  }


  if (globalCoupon && globalCoupon !== undefined)
    totalCost = totalCost - (globalCoupon.applicableDiscountType === "fixed" ? globalCoupon.applicableDiscountValue : (totalCost * globalCoupon.applicableDiscountValue / 100))
  return totalCost.toFixed(2)
};
