import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getAllCategory = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/category/all-category`, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getStore = async (storeName) => {
  try {
    let res = await axios.post(`${apiURL}/api/store/single-store`, {
      sName: storeName,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createCategory = async ({
  cName,
  cImage,
  cDescription,
  cStore,
  cSection,
  cStatus,
}) => {
  let formData = new FormData();
  formData.append("cImage", cImage);
  formData.append("cName", cName);
  formData.append("cDescription", cDescription);
  formData.append("cStore", cStore);
  formData.append("cSection", cSection);
  formData.append("cStatus", cStatus);

  try {
    let res = await axios.post(
      `${apiURL}/api/category/add-category`,
      formData,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editCategory = async (cId, des, status,cStore,cName,cSection) => {
  let data = { cId: cId, cDescription: des, cStatus: status,cStore:cStore,cName:cName,cSection: cSection };
  console.log("category data : ",data);
  try {
    let res = await axios.post(
      `${apiURL}/api/category/edit-category`,
      data,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCategory = async (cId) => {
  try {
    let res = await axios.post(
      `${apiURL}/api/category/delete-category`,
      { cId },
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const CatByStore = async (storeId) => {
  console.log("Store Id  : ",storeId);
  try {
    let res = await axios.post(`${apiURL}/api/category/category-by-store`, { storeId });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const CatBySec = async (secId) => {
  console.log("secId:",secId);
  try {
    let res = await axios.post(`${apiURL}/api/category/category-by-section`, { secId });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
