import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import StoreSectionMenu from "./StoreSectionMenu";
import AllStoreSections from "./AllStoreSections";
import { storeSectionState, storeSectionReducer } from "./StoreSectionContext";

/* This context manage all of the caregories component's data */
export const StoreSectionContext = createContext();

const StoreSectionComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <StoreSectionMenu />
      <AllStoreSections />
    </div>
  );
};

const StoreSections = (props) => {
  const [data, dispatch] = useReducer(storeSectionReducer, storeSectionState);
  return (
    <Fragment>
      <StoreSectionContext.Provider value={{ data, dispatch }}>
        <AdminLayout children={<StoreSectionComponent />} />
      </StoreSectionContext.Provider>
    </Fragment>
  );
};

export default StoreSections;
