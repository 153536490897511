import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
      ? JSON.parse(localStorage.getItem("jwt")).token
      : false;
const Headers = () => {
  return {
      headers: {
          token: `Bearer ${BearerToken()}`
      },
  };
};

export const getAllOrder = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/order/get-all-orders`,{ withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editCategory = async (oId, status) => {
  let data = { oId: oId, status: status };
  console.log(data);
  try {
    let res = await axios.post(`${apiURL}/api/order/update-order`, data, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteOrder = async (oId) => {
  let data = { oId: oId };
  try {
    let res = await axios.post(`${apiURL}/api/order/delete-order`, data, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const cancelOrder = async (oId) => {
  let data = { oId: oId, status: "Returned" };
  try {
    let res = await axios.post(`${apiURL}/api/order/return-order`, data, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export const createShipping = async (oId) => {
  let data = { oId: oId, status: "Shipped" };
  try {
    let res = await axios.post(`${apiURL}/api/order/create-shippment`, data, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export const getInvoiceLink = async (invoiceKey) => {
  try {
    let res = await axios.post(`${apiURL}/api/order/get-invoice-link`, {invoiceKey : invoiceKey}, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getShippingLabel = async (oId) => {
  try {
    let res = await axios.post(`${apiURL}/api/order/get-shipping-label`, { oId: oId }, {
      responseType: 'blob' // This ensures the response is handled as binary data
    }, { withCredentials: true });

    const disposition = res.headers['content-disposition'];
    const filenameMatch = disposition && disposition.match(/filename="(.+)"/);
    const filename = filenameMatch ? filenameMatch[1] : `${oId}.pdf`; 
    console.log(filenameMatch);

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // Set the filename

    // Append to the body and click to trigger download
    document.body.appendChild(link);
    link.click();

    // Clean up
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);

  } catch (error) {
    console.log(error);
  }
};