import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getSingleProduct = async (pId) => {
  try {
    console.log("line_18");
    let res = await axios.post(`${apiURL}/api/product/single-product`, {
      pId: pId
    });
    console.log("fetchApi_res:",res);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getSingleProductForLink = async (pId) => {
  try {
    console.log("line_31:",pId);
    let res = await axios.post(`${apiURL}/api/product/single-product-for-link`, {
      pId: pId,
    }, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getSingleProductWithName = async (item) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/single-product-name`, {
      pName: item,
    }, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const postAddReview = async (formData) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/add-review`, formData, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const postDeleteReview = async (formData) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/delete-review`, formData, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const addItemToCart = async(data)=>{
  try{
    let res = await axios.post(`${apiURL}/api/cart/add-product`, data, { withCredentials: true });
    return res.data;
  }catch(err){
    console.log(err)
  }
}

export const getCartItems = async()=>{
  try{
    let res = await axios.get(`${apiURL}/api/cart/get-cart-items`, { withCredentials: true });
    
    return res.data;
  }catch(err){
    console.log(err)
  }
}
