import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { LayoutContext } from '..';
import { getAllStoreSection } from '../../superAdmin/storeSections/FetchApi'; // Keep this import
import LinearProgress from "@material-ui/core/LinearProgress";
import { getStoreByStoreSection } from "../../superAdmin/stores/FetchApi";
import "./style.css";


const StoreSectionList = () => {
  const history = useHistory();
  const { data, dispatch } = useContext(LayoutContext);
  const [storeSection, setStoreSection] = useState(null);
  const [stores, setStores] = useState([]);
  const [isShown, setIsShow] = useState(null);
  const [loadingState, setLoadingState] = useState({}); 

  const fetchData = async () => {
    try {
      let responseData = await getAllStoreSection(); 
      console.log("Data : ", responseData.StoreSections);
      if (responseData && responseData.StoreSections) { 
        setStoreSection(responseData.StoreSections);
      }
    } catch (error) {
      console.log(error); 
    } 
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchStoreData = async (item, index) => {
    // Set loading state for the specific category
    setLoadingState(prevState => ({ ...prevState, [index]: true }));
    setIsShow(index)
    try {
      let responseData = await getStoreByStoreSection(item._id);
      // Update stores for the specific category
      setStores(prevStores => ({...prevStores,[index]: responseData.Stores || []}));
    } catch (error) {
      console.log(error);
    } finally {
      // Reset the loading state for the specific category
      setLoadingState(prevState => ({ ...prevState, [index]: false }));
    }
  };


  return (
    <div className={`catBar_main`}>
      <div className="lg:px-64 sm:px-16 flex flex-row carousel" style={{ justifyContent: "center" }}>
          {storeSection && storeSection.length > 0 ? (
              storeSection.map((item, index) => (
                <Fragment key={index}>
                  <div
                    onMouseEnter={() => fetchStoreData(item, index)}
                    onMouseLeave={() => setIsShow(null)}
                    onClick={() => {
                      history.push(`/stores/storeSection/${item.ssName}/${item._id}`);
                    }}
                    className="col-span-1 flex mx-16 flex-col items-center justify-center space-y-2 cursor-pointer cat_bg"
                    style={{ backgroundImage: `url(${item.url})`, color: "black" }}
                  >
                    <div className="font-medium">{item.ssName}</div>
      
                    {isShown === index && (
                      <div className="stores-dropdown">
                        {loadingState[index] ? (
                          <LinearProgress />
                        ) : (stores[index] && stores[index].length > 0) ? (
                          stores[index].map((store, storeIndex) => (
                            <Fragment key={storeIndex}>
                              <div className="store-item">{store.sName}</div>
                            </Fragment>
                          ))
                        ) : (
                          <div className="no-category">No Stores</div>
                        )}
                      </div>
                    )}
                  </div>
                </Fragment>
              ))
          ) : (
            <div className="no-category">No Stores</div>
          )}
      </div>
    </div>
  );
};
  
const CatBar = (props) => {
  return (
    <Fragment>
      <div>
        <StoreSectionList />
      </div>
    </Fragment>
  );
};

export default CatBar;
