import React, { Fragment, useContext, useEffect, useState } from "react";
import AddCouponModal from "./SelectStoresModal.js"
import { getCouponByStore, deleteCoupon, getQuadrantStores } from "./FetchApi.js";


const CouponMenu = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [storesQ1, setStoreQ1] = useState([]);
  const [storesQ2, setStoreQ2] = useState([]);
  const [storesQ3, setStoreQ3] = useState([]);
  const [storesQ4, setStoreQ4] = useState([]);

  let temp = [ "store1", "store2", "store3", "store4"]
  const storeArrays = [storesQ1, storesQ2, storesQ3, storesQ4];
  const storeSetters = [setStoreQ1, setStoreQ2, setStoreQ3, setStoreQ4];


  const fetchQuadrantData = async () => {
    let responseData = await getQuadrantStores();
    if (responseData && responseData.Stores) {
      console.log("res:",responseData.Stores);
      console.log("res1:",responseData.Stores[0][temp[0]].sName); // Array of target arrays

      for (let quadrant = 0; quadrant < 4; quadrant++) {
        const currentStores = [];
        if (responseData.Stores[quadrant]) {
          for (let i = 0; i < 4; i++) {
            const storeKey = temp[i];
            if (responseData.Stores[quadrant][storeKey]) {
              currentStores.push(responseData.Stores[quadrant][storeKey].sName);
            }
          }
        }
        // Update state for the current quadrant
        storeSetters[quadrant](currentStores);
      }
    }

    //   for (let i = 0; i <= 3; i++) {
    //     if(responseData.Stores[0] && responseData.Stores[0][temp[i]]){
    //       setStoreQ1([...storesQ1, responseData.Stores[0][temp[i]].sName]);
    //     }
    //   }
    //   for (let i = 0; i <= 3; i++) {
    //     if(responseData.Stores[1] && responseData.Stores[1][temp[i]]){
    //       storesQ2.push([...storesQ2, responseData.Stores[1][temp[i]].sName]);
    //     }
    //   }
    //   for (let i = 0; i <= 3; i++) {
    //     if(responseData.Stores[2] && responseData.Stores[2][temp[i]]){
    //       storesQ3.push([...storesQ3,responseData.Stores[2][temp[i]].sName]);
    //     }
    //   }
    //   for (let i = 0; i <= 3; i++) {
    //     if(responseData.Stores[3] && responseData.Stores[3][temp[i]]){
    //       storesQ4.push([...storesQ4, responseData.Stores[3][temp[i]].sName]);
    //     }
    //   } 
      
    // }
    // console.log("storesQ1:",storesQ1);
    
  };


  useEffect(() => {
    fetchQuadrantData();
  }, [showModal]);

  
  console.log("storesQ144:",storesQ1);
  return (
    <Fragment>
      <div className="col-span-1 flex justify-between items-center">
        <div className="flex items-center">
          <span
            style={{ background: "#303031" }}
            onClick={(e) => {
              setShowModal(true)
            }
            }
            className="rounded-full cursor-pointer p-2 bg-gray-800 flex items-center text-gray-100 text-sm font-semibold uppercase"
          >
            <svg
              className="w-6 h-6 text-gray-100 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              />
            </svg>
            Add/Update Quadrants
          </span>
        </div>
        <AddCouponModal showModal={showModal} setShowModal={setShowModal} fetchQuadrantData = {fetchQuadrantData}/>
      </div>
      <div className="col-span-1 overflow-auto bg-white shadow-lg p-4">
        <table className="table-auto border w-full my-2">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Store Group 1</th>
              <th className="px-4 py-2 border">Store Group 2</th>
              <th className="px-4 py-2 border">Store Group 3</th>
              <th className="px-4 py-2 border">Store Group 4</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td className="p-2 text-center">
                <div>
                  {storesQ1 && storesQ1.length > 0 ? (
                    storesQ1.map((item) => {
                      return (
                        <Fragment>
                          <div className="selectstore">
                            {item}
                          </div>
                        </Fragment>
                      )}
                    ))
                    :("No Stores")
                  }
                </div>
              </td>
              <td className="p-2 text-center">
                <div>
                  {storesQ2 && storesQ2.length > 0 ? (
                    storesQ2.map((item, key) => {
                      return (
                        <Fragment>
                          <div className="selectstore">
                            {item}
                          </div>
                        </Fragment>
                      )}
                    ))
                    :("No Stores")
                  }
                </div>
              </td>
              <td className="p-2 text-center">
                <div>
                  {storesQ3 && storesQ3.length > 0 ? (
                    storesQ3.map((item, key) => {
                      return (
                        <Fragment>
                          <div className="selectstore">
                            {item}
                          </div>
                        </Fragment>
                      )}
                    ))
                    :("No Stores")
                  }
                </div>
              </td>
              <td className="p-2 text-center">
                <div>
                  {storesQ4 && storesQ4.length > 0 ? (
                    storesQ4.map((item, key) => {
                      return (
                        <Fragment>
                          <div className="selectstore">
                            {item}
                          </div>
                        </Fragment>
                      )}
                    ))
                    :("No Stores")
                  }
                </div>
              </td>
              {/* <td className="p-2 text-center text-red-500"><button onClick={() => handleDeleteCoupon()}>Delete</button></td> */}
            </tr>
          </tbody>
        </table>
      </div>
      

    </Fragment>
  );
};

export default CouponMenu;
