import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ProductDetailsContext } from "./index";
import { LayoutContext } from "../layout";
import Submenu from "./Submenu";
import ProductDetailsSectionTwo from "./ProductDetailsSectionTwo";

import { getSingleProduct } from "./FetchApi";
import { cartListProduct, getCartItems } from "../partials/FetchApi";
import { Alert } from "./Action";
import { isWishReq, unWishReq, isWish } from "../home/Mixins";
import { updateQuantity, slideImage, addToCart, cartList } from "./Mixins";
import { totalCost } from "../partials/Mixins";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const ProductDetailsSection = (props) => {

  const history = useHistory();
  let { id } = useParams();

  const { data, dispatch } = useContext(ProductDetailsContext);
  const { data: layoutData, dispatch: layoutDispatch } = useContext(
    LayoutContext
  ); // Layout Context

  const sProduct = layoutData.singleProductDetail;
  const [pImages, setPimages] = useState(null);
  const [count, setCount] = useState(0); // Slide change state
  const [size, setSize] = useState(null);
  const [error, setError] = useState(false);
  const [isInStock, setIsInStock] = useState(false);

  const loggedIn = localStorage.getItem("jwt");
  //const [loggedIn, setLoggedIn] = useState(JSON.parse(localStorage.getItem("jwt")));
   const [showLoginModal, setShowLoginModal] = useState(false);
   const [loginError, setLoginError] = useState("");

  const [quantitiy, setQuantitiy] = useState(1); // Increse and decrese quantity state
  const [alertQ, setAlertq] = useState(false); // Alert when quantity greater than stock

  const [wList, setWlist] = useState(null); 

  const [colorChange, setColorchange] = useState(false);

  const changeNavbarColor = () =>{    
    const scrollHeight = document.documentElement.scrollHeight; // Total height of the document
    const clientHeight = document.documentElement.clientHeight; // Visible height of the viewport
    const scrollableHeight = scrollHeight - clientHeight; // Total scrollable height
    const threshold = scrollableHeight * 0.8;

    if(window.scrollY >= threshold ){
        setColorchange(true);
      }
      else{
        setColorchange(false);
      }
   };
  useEffect(() => {
    window.addEventListener('scroll', changeNavbarColor);
    return () => {
      window.addEventListener('scroll', changeNavbarColor);
    };
  }, []);

  const getWishListedProducts = async()=>{
    let wishlistProducts =  await getCartItems();
      wishlistProducts = wishlistProducts.wishlistProducts || [];
      wishlistProducts = wishlistProducts.map((wishlistProduct)=>wishlistProduct.productId);
      setWlist(wishlistProducts)
  }

  const handleIsWishlistReq = async (id)=>{
    await isWishReq(id);
    fetchData();
  }

  const handleUnWishlistReq = async(id)=>{
    await unWishReq(id);
    fetchData();
  }

   const checkSizeAlreadyInCart = (size)=>{
    const result = layoutData.cartItems.some(item => {
      return item.productId === id && item.productSize === size;
    });
  
   // setIsAlreadyPresent(result)
    return result;
   }

   useEffect(() => {
    if (sProduct && sProduct.pQuantity && sProduct.pQuantity.length > 0) {
      const availableSizes = sProduct.pQuantity.filter(item => item.quantity > 0);
  
      if (availableSizes.length > 0) {
        setIsInStock(true);  // Stock available
      } else {
        setIsInStock(false); // Out of stock
      }
    } else {
      setIsInStock(false);   // Out of stock if no pQuantity data or sProduct is null
    }
  }, [sProduct]);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    dispatch({ type: "loading", payload: true });
     getWishListedProducts()
    try {
      let responseData = await getSingleProduct(id);
      console.log("92:", responseData);
      setTimeout(() => {
        if (responseData.Product) {
          if(!responseData.Product.pQuantity[0].size){
            responseData.Product.pQuantity = JSON.parse(responseData.Product.pQuantity) 
          }
          if(responseData.Product.similarProducts && responseData.Product.similarProducts.length > 0){
            responseData.Product.similarProducts = JSON.parse(responseData.Product.similarProducts)
          }
          
          setTimeout(() => {
            layoutDispatch({
              type: "singleProductDetail",
              payload: responseData.Product,
            }); // Dispatch in layout context
            setPimages(responseData.Product.url);
           },500)
        }
        if (responseData.error) {
          console.log("error:", responseData.error);
        }
      }, 500);
    } catch (error) {
      console.log(error);
    }

    console.log("92");
    fetchCartProduct(); // Updating cart total
  };

  const fetchCartProduct = async () => {
    try {
      let cartItems = await getCartItems();

      console.log("92");
      cartItems = cartItems.cartProducts || [];
      let productArray = [];
      if (cartItems) {
        for (const cart of cartItems) {
          productArray.push(cart.productId);
        }
      }
      const responseData = await cartListProduct(productArray);
      const cartListItems = await cartList(cartItems)
      
      console.log("137");
      setTimeout(()=>{
        layoutDispatch({type:"cartItems", payload :cartItems})
        if (responseData && responseData.Products) {
          layoutDispatch({ type: "cartProduct", payload: responseData.Products });          
        }
        layoutDispatch({ type: "inCart", payload: cartListItems }); // This function change cart in cart state
        dispatch({ type: "loading", payload: false });
        
      console.log("146");
      },500)
    } catch (error) {
      console.log(error);
    }
  };
  const [alignment, setAlignment] = React.useState('left');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

   const fetchAffiliateId =  () => {
    const urlParams = new URLSearchParams(window.location.search);
    const affId= urlParams.get('aff_id');
    if(affId)
      return affId;
    else{
      return null;
    }
  };

  if (data.loading) {
    return (
      <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center h-screen">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  } else if (!sProduct) {
    return <div>No product</div>;
  }
  return (

    <Fragment>
      <Submenu
        value={{
          categoryId: sProduct.pCategory._id,
          product: sProduct.pName,
          category: sProduct.pCategory.cName,
        }}
      />
      <section className="m-4 mx-48  pt-20 md:mx-12 md:ml-0 md:my-6 ml-0">
        <div className="grid grid-cols-2 md:grid-cols-10 mr-8">

          <div className="col-span-2 md:col-span-5 z-10">
            <div className="flex">
              <div className="relative ">
                <img
                  className=" m-auto"
                  src={`${sProduct.url[count]}`}
                  alt="Pic"
                />
                <div className="absolute inset-0 flex justify-between items-center mb-4">
                  <svg
                    onClick={(e) =>
                      slideImage("decrease", count-1, count, setCount, pImages)
                    }
                    className="flex justify-center  w-12 h-12 text-gray-700 opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                  <svg
                    onClick={(e) =>
                      slideImage("increase", count+1, count, setCount, pImages)
                    }
                    className="flex justify-center  w-12 h-12 text-gray-700 opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </div>
              
            </div>
            {sProduct && sProduct.url.length > 0
                ? (sProduct.url.slice(1,sProduct.url.length).map((item, key) => {
                  return (
                    <img
                      className="w-400 m-auto"
                      src={`${item}`}
                    />
                  )}
                  )
                ):("")
              }
          </div>
          <div className="  my-32 hidden md:block md:col-span-1 md:flex md:flex-col md:space-y-4 md:mr-2 ml-8 ">
                {sProduct && sProduct.url.length > 0
                  ? (sProduct.url.map((item, key) => {
                    return (
                      <img
                        onClick={(e) =>
                          slideImage("change", key, count, setCount, pImages)
                        }
                        className={`${
                          count === key ? "" : "opacity-25"
                        } cursor-pointer w-20 h-20 object-cover object-center`}
                        src={`${item}`}
                      />
                    )}
                    )
                  ):("")
                }
              </div>
          
          <div className={colorChange ? "col-span-2 mt-8 md:mt-0 md:col-span-4 md:ml-6 lg:ml-12 md:mt-24": "col-span-2 mt-8 md:mt-0 md:col-span-4 md:ml-6 lg:ml-12 md:mt-24 fixed top-10 right-10"}>
          <div className="flex flex-col leading-8">
              <div className="text-2xl tracking-wider mb-2 font-bold">{sProduct.pStore.sName}</div>
              <div className="text-xl tracking-wider">{sProduct.pName}</div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-xl tracking-wider text-yellow-700 flex flex-col">
                  <div>
                  ₹{sProduct.pPrice} <p style={{fontSize: "1rem", color: "rgba(66, 66, 66, 0.426)", display: "inline" }}>({sProduct.pOffer}% OFF)</p>
                  </div>
                  <div style={{fontSize: "1rem", color: "rgba(66, 66, 66, 0.426)" }}>
                    inclusive of all taxes
                  </div>
                </span>
                <span>
                  <svg
                    onClick={(e) => handleIsWishlistReq(sProduct._id)}
                    className={`${
                      isWish(sProduct._id, wList) && "hidden"
                    } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </svg>
                  <svg
                    onClick={(e) => handleUnWishlistReq(sProduct._id)}
                    className={`${
                      !isWish(sProduct._id, wList) && "hidden"
                    } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <hr/>
              {isInStock && (
                  <div className="mt-12">
                    Select Size
                    <div className="flex flex-row">
                      <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                      >
                        {sProduct.pQuantity && sProduct.pQuantity.length > 0 ? (
                          (() => {
                            // Filter sizes to find those with quantity greater than 0
                            const availableSizes = sProduct.pQuantity.filter(item => item.quantity > 0);

                            if (availableSizes.length > 0) {
                              return availableSizes.map((item, key) => {
                                return (
                                  <ToggleButton
                                    key={key}
                                    value={item.size}
                                    style={{
                                      width: "4vw",
                                      margin: "0.5vw",
                                      marginRight: "1vw",
                                      marginLeft: "0vw",
                                      border: "1px solid grey",
                                      borderRadius: "10px"
                                    }}
                                    onClick={() => {
                                      setError(false);
                                      setSize(item.size);
                                    }}
                                    selected={item.size === size}
                                  >
                                    {item.size}
                                  </ToggleButton>
                                );
                              });
                            } else {
                              return <h1>Out of stock</h1>; // Show out of stock if all sizes are zero
                            }
                          })() // Immediately invoke the function
                        ) : (
                          <h1>Out of stock</h1> // Show out of stock if pQuantity is empty
                        )}
                      </ToggleButtonGroup>
                    </div>
                  </div>
                )}

              <div className="mt-2">
                <div className="flex flex-row mt-4">
                  {sProduct.similarProducts && sProduct.similarProducts.length > 0
                    ?(sProduct.similarProducts.map((item, key) => {
                      return (
                        <div 
                          onClick={(e) =>{
                            history.push(`/products/${item.id}`)
                            // document.location.reload()
                            window.location.href= window.location.href;
                          }}
                          className=" cursor-pointer "
                        >
                          <img
                            className="w-20 m-auto rounded-xl mr-4 cursor-pointer"
                            src={`${item.url}`}
                            alt="Pic"
                          />
                          {/* <div className="mr-4 text-center"> {item.name}</div> */}
                        </div>
                      )}
                      )
                    ):( "")
                  }
                </div>
              </div>
            </div>
            <div className="my-4 md:my-6 text-gray-600">
              {sProduct.pDescription}
            </div>
            <div className="my-4 md:my-6">
              {quantitiy == sProduct.pQuantity ? (
                <span className="text-xs text-red-500">Stock limited</span>
              ) : (
                ""
              )}
              {isInStock && (
                <div
                  className={`flex justify-between items-center px-4 py-2 border ${
                    quantitiy === sProduct.pQuantity ? "border-red-500" : ""
                  }`}
                >
                  <div
                    className={`${quantitiy === sProduct.pQuantity ? "text-red-500" : ""}`}
                  >
                    Quantity
                  </div>

                  {/* Quantity Button */}
                  {sProduct.pQuantity !== 0 ? (
                    <Fragment>
                      {layoutData.inCart == null ||
                      (layoutData.inCart !== null &&
                        layoutData.inCart.includes(sProduct._id) === false) ? (
                        <div className="flex items-center space-x-2">
                          {/* Decrease quantity button */}
                          <span
                            onClick={() =>
                              updateQuantity(
                                "decrease",
                                sProduct.pQuantity,
                                quantitiy,
                                setQuantitiy,
                                setAlertq
                              )
                            }
                          >
                            <svg
                              className="w-5 h-5 fill-current cursor-pointer"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>

                          {/* Quantity Display */}
                          <span className="font-semibold">{quantitiy}</span>

                          {/* Increase quantity button */}
                          <span
                            onClick={() =>
                              updateQuantity(
                                "increase",
                                sProduct.pQuantity,
                                quantitiy,
                                setQuantitiy,
                                setAlertq
                              )
                            }
                          >
                            <svg
                              className="w-5 h-5 fill-current cursor-pointer"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </div>
                      ) : (
                        <div className="flex items-center space-x-2">
                          {/* Disabled Decrease quantity button */}
                          <span>
                            <svg
                              className="w-5 h-5 fill-current cursor-not-allowed"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>

                          {/* Quantity Display */}
                          <span className="font-semibold">{quantitiy}</span>

                          {/* Disabled Increase quantity button */}
                          <span>
                            <svg
                              className="w-5 h-5 fill-current cursor-not-allowed"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <div className="flex items-center space-x-2">
                      {/* Disabled Decrease quantity button */}
                      <span>
                        <svg
                          className="w-5 h-5 fill-current cursor-not-allowed"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>

                      {/* Quantity Display */}
                      <span className="font-semibold">{quantitiy}</span>

                      {/* Disabled Increase quantity button */}
                      <span>
                        <svg
                          className="w-5 h-5 fill-current cursor-not-allowed"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  )}
                  {/* Quantity Button End */}
                </div>
              )}

              {/* Incart and out of stock button */}
              {(sProduct.pQuantity !== 0) ? (                
                <Fragment>
                  {layoutData.inCart !== null &&
                  layoutData.inCart.includes(sProduct._id) === true &&
                    checkSizeAlreadyInCart(size)? (
                    <div
                      style={{ background: "#303031" }}
                      className={`px-4 py-2 text-white text-center cursor-not-allowed uppercase opacity-75`}
                    >
                      In cart
                    </div>
                  ) : (                    
                    <div
                      onClick={(e) => {
                        if(loggedIn==null)
                          {
                            setShowLoginModal(true)
                            setLoginError("You must be logged in to add items to the cart.")
                          }
                          else{
                        if (isInStock && size != null) { 
                            const affId = fetchAffiliateId();
                            console.log("AFF ID : ",affId);
                            dispatch({ type: 'affiliateId', payload: affId });
                            console.log("line_414:",size)

                            addToCart(
                              sProduct._id,
                              quantitiy,
                              sProduct.pPrice,
                              sProduct.pStore._id,
                              size,
                              sProduct.url[0],
                              sProduct.pName,
                              layoutData,
                              layoutDispatch,
                              setQuantitiy,
                              setAlertq,
                              fetchData,
                              totalCost,
                              affId,
                            );
                        } else if (size == null) {
                          setError(true); // Show error if size is not selected
                        }
                      }
                      }}
                      style={{
                        background: isInStock ? "#303031" : "grey", // Change background color based on stock status
                        pointerEvents: isInStock ? "auto" : "none", // Disable click if out of stock
                        opacity: isInStock ? 1 : 0.5 // Dim the button if out of stock
                      }}
                      className={`px-4 py-2 text-white text-center uppercase`}
                    >
                      {isInStock ? "Add to Cart" : "Out of Stock"} 
                    </div>

                  )}
                  {error ? Alert("red","Please select a size ") : ""}
                  {loginError && Alert("red", loginError)}
                </Fragment>
              ) : (
                <Fragment>
                  {layoutData.inCart !== null &&
                  layoutData.inCart.includes(sProduct._id) === true ? (
                    <div
                      style={{ background: "#303031" }}
                      className={`px-4 py-2 text-white text-center cursor-not-allowed uppercase opacity-75`}
                    >
                      In cart
                    </div>
                  ) : (
                    <div
                      style={{ background: "#303031" }}
                      disabled={true}
                      className="px-4 py-2 text-white opacity-50 cursor-not-allowed text-center uppercase"
                    >
                      Out of stock
                    </div>
                  )}
                </Fragment>
              )}
              {/* Incart and out of stock button End */}
            </div>
          </div>
        </div>
      </section>
      {/* Product Details Section two */}
      <ProductDetailsSectionTwo />
    </Fragment>
  );
};

export default ProductDetailsSection;
