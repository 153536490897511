import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getAllProduct = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/product/all-product`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createPorductImage = async ({ pImage }) => {
  /* Most important part for uploading multiple image  */
  let formData = new FormData();
  for (const file of pImage) {
    formData.append("pImage", file);
  }
  /* Most important part for uploading multiple image  */
};

export const createProduct = async ({
  pName,
  pDescription,
  pImage,
  pStatus,
  pCategory,
  pSection,
  similarProducts,
  pStore,
  pQuantity,
  pPrice,
  pOffer,
}) => {
  /* Most important part for uploading multiple image  */
  let formData = new FormData();
  for (const file of pImage) {
    formData.append("pImage", file);
  }
  /* Most important part for uploading multiple image  */
  formData.append("pName", pName);
  formData.append("pDescription", pDescription);
  formData.append("pStatus", pStatus);
  formData.append("similarProducts", similarProducts);
  formData.append("pCategory", pCategory);
  formData.append("pSection", pSection);
  formData.append("pStore", pStore);
  formData.append("pQuantity", pQuantity);
  formData.append("pPrice", pPrice);
  formData.append("pOffer", pOffer);

  try {
    let res = await axios.post(`${apiURL}/api/product/add-product`, formData, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editProduct = async (product) => {
  /* Most important part for updating multiple image  */
  let formData = new FormData();
  if (product.pEditImages) {
    for (const file of product.pEditImages) {
      formData.append("pEditImages", file);
    }
  }
  /* Most important part for updating multiple image  */
  if(product.pCategory._id==undefined)
  {
    formData.append("pCategory", product.pCategory);
  }
  else{
    formData.append("pCategory", product.pCategory._id);
  }
  formData.append("pId", product.pId);
  formData.append("pName", product.pName);
  formData.append("pDescription", product.pDescription);
  formData.append("pStatus", product.pStatus);
  // formData.append("pCategory", product.pCategory);
  formData.append("pStore", product.pStore);
  formData.append("pQuantity", product.pQuantity);
  formData.append("similarProducts", product.similarProducts);
  formData.append("pPrice", product.pPrice);
  formData.append("pOffer", product.pOffer);
  formData.append("pImages", JSON.stringify(product.pImages));

  try {
    let res = await axios.post(`${apiURL}/api/product/edit-product`, formData, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteProduct = async (pId) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/delete-product`, { pId }, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const productByCategory = async (catId) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/product-by-category`, { catId });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const productBySection = async (secId) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/product-by-section`, { secId });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const ProductByStore = async (storeId) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/product-by-store`, { storeId }, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const productByPrice = async (price) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/product-by-price`, {
      price,
    }, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};