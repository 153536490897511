import React, { Fragment, useEffect, useState } from "react";
import { getAllTestimonials } from "../../admin/testimonials/FetchApi";
import { prevSlide, nextSlide } from "./Mixins";


const apiURL = process.env.REACT_APP_API_URL;

const Testimonials = (props) => {
    const [slide, setSlide] = useState(0);
    const [tests, setTestimonials] = useState(null);
    const len = (tests && tests.length);
    
    const fetchData = async () => {
        try {
          let responseData = await getAllTestimonials();
          if (responseData && responseData.Testimonials) {
            setTestimonials(responseData.Testimonials);
          }
        } catch (error) {
          console.log(error);
        }
      };
    useEffect(() => {
    fetchData();
    }, []);

    const leftSlide=()=>{
        if(slide === 0){
            return len-1;
        }else if( slide>0){
            return slide -1;
        }
    }
    const rightSlide=()=>{
        if(slide === len - 1){
            return 0;
        }else if( slide< len-1){
            return slide + 1;
        }
    }

    return (
        <Fragment>
            {tests && tests.length>0 ? (
                <div className="t0">
                    <div className="testimonials ">
                        <div className="test1">
                            <div className="testImgC1">
                                <img className="testImg1" src={`${tests[leftSlide()].url}`}
                                />
                            </div>
                            
                            <div className="testName1">
                                <h1>{tests[leftSlide()].tName} </h1>
                            </div>
                            <div className="testContent1">
                                {tests[leftSlide()].tDescription} 
                            </div>
                        </div>
                        <div className="test2">
                            <div className="testImgC">
                                <img className="testImg" src={`${tests[slide].url}`}/>
                            </div>
                            
                            <div className="testName">
                                <h1>{tests[slide].tName} </h1>
                            </div>
                            <div className="testContent">
                                {tests[slide].tDescription} 
                            </div>
                        </div>
                        <div className="test3">

                            <div className="testImgC1">
                                <img className="testImg1" src={`${tests[rightSlide()].url}`}
                                />
                            </div>
                            
                            <div className="testName1">
                                <h1>{tests[rightSlide()].tName} </h1>
                            </div>
                            <div className="testContent1">
                                {tests[rightSlide()].tDescription} 
                            </div>
                        </div>
                        <svg
                            onClick={(e) => prevSlide(len, slide, setSlide)}
                            className={`z-10 absolute top-50 left-0 mt-0 flex justify-end items-center box-border flex justify-center w-12 h-12 text-gray-700  cursor-pointer hover:text-yellow-700`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                        <svg
                            onClick={(e) => nextSlide(len, slide, setSlide)}
                            className={`z-10 absolute top-50 right-0 mt-0 flex justify-start items-center box-border flex justify-center w-12 h-12 text-gray-700 cursor-pointer hover:text-yellow-700`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5l7 7-7 7"
                            />
                        </svg>
                    </div>
                </div>
                
            ): (
                ""
            )}
        </Fragment>        
    );
};
export default Testimonials;