import React, { Fragment, useContext, useEffect, useState } from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TwitterIcon from '@material-ui/icons/Twitter';
import { useParams } from "react-router-dom";
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { LayoutContext } from "../layout";

const Store_Intro = () =>{
    const {storeName} = useParams();

    const { data } = useContext(LayoutContext);
    let [store, setStore] = useState({});
    const fetchData = async () => {
        setStore(data.storeData)
    };
    useEffect(() => {
        fetchData();
      }, [data.storeData._id]);

    return(
        <Fragment>
            <div className="storeIntro mtp-36 bg-gray-100 rounded-2xl overflow-hidden hide" style={{backgroundImage: `url(${store.url})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover"}} >
                <div className="gradient_lyr rounded-b-2xl flex flex-col justify-content-end">
                    <div className=" store_name px-10 py-0 my-0">
                        {store.sName} 
                    </div>
                    <div className="px-10 pt-10 ">  
                       {store.sDescription && store.sDescription.length > 400 ? store.sDescription.substring(1, 400) + "...": store.sDescription}
                    </div>
                        
                </div>
                <div className="social">
                    <div className="social_icon">
                        <InstagramIcon  fontSize="large"/>
                    </div>
                    <div className="social_icon">
                        <MailOutlineIcon fontSize="large"/>
                    </div>
                    <div className="social_icon">
                        <TwitterIcon fontSize="large"/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Store_Intro;