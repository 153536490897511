import React, { Fragment, useContext, useEffect, useState } from "react";
import AddCouponModal from "./AddCouponModal.js"
import { getCouponByStore, deleteCoupon } from "./FetchApi.js";


const CouponMenu = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [coupons, setCoupons] = useState([])
  const [loading, setLoading] = useState(false);
  const [storeId, setStoreId] = useState(null)

  const fetchData = async () => {
    if (!storeId) {
      fetchStoreId();
    }

    if (storeId) {
      setLoading(true);
      const responseData = await getCouponByStore(storeId);
      if (responseData.error) {
        console.error("Error fetching coupons:", responseData.error);
      } else {
        setCoupons(responseData);
      }
      setLoading(false);
    }
  };

  const fetchStoreId = () => {
    //setting globalStoreId
    //If you find any alternative to global storeId then please change wherever there is '000000000000100000000000' this string  
    setStoreId('000000000000100000000000');
  };

  useEffect(() => {
    fetchData();
  }, [storeId]);

  const handleDeleteCoupon = async (couponIndex) => {
    const responseData = await deleteCoupon(coupons[couponIndex]._id)
    if (responseData.error)
      console.log("error from delete : ", responseData.error);
    else {
      console.log("delete success")
      fetchData();
    }
  }

  return (
    <Fragment>
      <div className="col-span-1 flex justify-between items-center">
        <div className="flex items-center">
          <span
            style={{ background: "#303031" }}
            onClick={(e) => {
              setShowModal(true)
            }
            }
            className="rounded-full cursor-pointer p-2 bg-gray-800 flex items-center text-gray-100 text-sm font-semibold uppercase"
          >
            <svg
              className="w-6 h-6 text-gray-100 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              />
            </svg>
            Add Coupon
          </span>
        </div>
        <AddCouponModal showModal={showModal} setShowModal={setShowModal} />

        {/* TODO <EditCouponModal /> */}



      </div>
      <div className="col-span-1 overflow-auto bg-white shadow-lg p-4">
        <table className="table-auto border w-full my-2">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Coupon Name</th>
              <th className="px-4 py-2 border">Coupon Description</th>
              <th className="px-4 py-2 border">Minimum Amount</th>
              <th className="px-4 py-2 border">Discount Type</th>
              <th className="px-4 py-2 border">Dicsount Value</th>
              <th className="px-4 py-2 border"> Operations</th>
            </tr>
          </thead>
          <tbody>
            {coupons ? (
              coupons.map((coupon, key) => {
                return (
                  <tr id={key}>
                    <td className="p-2 text-center">{coupon.couponName}</td>
                    <td className="p-2 text-center">{coupon.couponDescription}</td>
                    <td className="p-2 text-center">{coupon.applicableMinimumAmount}</td>
                    <td className="p-2 text-center">{coupon.applicableDiscountType}</td>
                    <td className="p-2 text-center">{coupon.applicableDiscountValue} {coupon.applicableDiscountType === 'percentage' ? "%" : "rupees"}</td>
                    <td className="p-2 text-center text-red-500"><button onClick={() => handleDeleteCoupon(key)}>Delete</button></td>
                  </tr>)

              })
            ) : <div>No items</div>}
          </tbody>
        </table>
      </div>
      

    </Fragment>
  );
};

export default CouponMenu;
