import React, { Fragment, useContext, useState,useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import logo from "../../../images/thredit-w.png";
import logo from "../../../images/Group 83.png";
// import logo from "../../../images/logo_sqr.png";
import "./style.css";

import { logout } from "./Action";
import { LayoutContext } from "../index";
import { isSuperAdmin } from "../auth/fetchApi";
import { getAllProduct } from "../../admin/products/FetchApi";
import { IconButton } from "@material-ui/core";

const Search = () => {
  const { data, dispatch } = useContext(LayoutContext);
  const [search, setSearch] = useState("");
  const [productArray, setPa] = useState(null);
  const history = useHistory();

  const searchHandle = (e) => {
    if (e.key === "Enter")
      {
    setSearch(e.target.value);
    fetchData();
    dispatch({
      type: "searchHandleInReducer",
      payload: e.target.value,
      productArray: productArray,
    });
    history.push(`/searchItems/${e.target.value}`)
  }
  };

  const fetchData = async () => {
    dispatch({ type: "loading", payload: true });
    try {
      setTimeout(async () => {
        let responseData = await getAllProduct();
        if (responseData && responseData.Products) {
          setPa(responseData.Products);
          dispatch({ type: "loading", payload: false });
        }
      }, 700);
    } catch (error) {
      console.log(error);
    }
  };

  const closeSearchBar = () => {
    dispatch({ type: "searchDropdown1", payload: !data.searchDropdown });
    fetchData();
    dispatch({ type: "setProducts", payload: productArray });
    setSearch("");
  };

  return (
    <div
      className={`${
        data.searchDropdown ? "true" : ""
      } flex my-2 items-center justify-between bg-gray-100 rounded search_shadow search_m`}
    >
      {/* <input
        value={search}
        onChange={(e) => searchHandle(e)}
        className="px-8 text-l py-0 focus:outline-none bg-gray-100 rounded-lg"
        type="text"
        placeholder="Search products...aaaaaaaa"
      /> */}
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}   
        onKeyDown={searchHandle}     
        className="px-2 text-l py-0 mb-0 focus:outline-none bg-gray-100 rounded-lg"
        type="text"
        placeholder="Search products..."
      />
      <div onClick={(e) => closeSearchBar()} className="cursor-pointer ">
        <svg
          className="w-8 h-8 text-gray-700 hover:bg-gray-200 rounded-full p-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 44 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
  );
};

const Navber = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [menu, toggleMenu] = useState(false)

  const [isSuperAdminUser, setIsSuperAdminUser] = useState(false);
  const { data, dispatch } = useContext(LayoutContext);

  // const navberToggleOpen = () =>
  //   data.navberHamburger
  //     ? dispatch({ type: "hamburgerToggle", payload: false })
  //     : dispatch({ type: "hamburgerToggle", payload: true });

  const loginModalOpen = () =>
    data.loginSignupModal
      ? dispatch({ type: "loginSignupModalToggle", payload: false })
      : dispatch({ type: "loginSignupModalToggle", payload: true });

  const cartModalOpen = () =>
    data.cartModal
      ? dispatch({ type: "cartModalToggle", payload: false })
      : dispatch({ type: "cartModalToggle", payload: true });
      
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () =>{    
      if(window.scrollY >= 5){
        setColorchange(true);
      }
      else{
        setColorchange(false);
      }
   };
   window.addEventListener('scroll', changeNavbarColor);

  //  const Headers = () => {
  //   const token = localStorage.getItem('jwt');
  //   if (!token) {
  //     console.error("No JWT token found");
  //     return;
  //   }
  //   return {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };
  // };


  const checkSuperAdminStatus = async () => {
    try {
      console.log("line_147");
      const isSuperAdminValue = await isSuperAdmin();
      console.log("isSuperAdminVAlue:", isSuperAdminValue);
      if (isSuperAdminValue) {
        setIsSuperAdminUser(true);
      } else {
        setIsSuperAdminUser(false);
      }
    } catch (error) {
      setIsSuperAdminUser(false);
      console.error("Error in fetching super admin status: ", error);
    }
  };

  let token = null;
  useEffect(()=>{
    token = localStorage.getItem('jwt');
  })
  useEffect(async ()  => {
    console.log("tokenCheck:",token);
    if (token) {
      console.log("tokenCheck2:",token);
      checkSuperAdminStatus();
    } 
  }, [token]);


  return (
    <Fragment>
      {/* Navber Section */}
      <div style={{ width: "100%", height: "3vh",position: "absolute" , textAlign: "center",color: "#43434380", fontSize: "13px"}} className="pt-2 hide bg-primary">
        {/* Free Shipping within India | Free shipping on all International orders above INR 15000 or approx 200 USD. T&C's apply* | Cash on Delivery available across India  */}
      </div>
      <nav className={colorChange?"fixed top-0 w-full z-10 bg-pink-4t navber-full search_shadow hide" : "fixed top-3 w-full z-10 shadow-lg lg:shadow-none bg-primary navber-full  hide"}>
        <div className="my-1 md:mx-40 grid grid-cols-7-7t lg:grid-cols-6 "> 
          <div className="md:pl-1 flex">
            
            <div className=" items-center navber-mid mr-24" style={{gridColumn: "1 / span 2"}}>
              <div className=" hidden lg:block col-span-1 nav-btns items-center text-black-400 pl-8" style={{color: "#E76F51" }}>
              
                <span
                  className=" nav-btn hover:bg-gray-800 px-8 py-2 ml-8 hover:text-gray-100 cursor-pointer "
                  onClick={(e) => history.push("/home")}
                  style={{fontWeight:600}}
                >
                  Home
                </span>
                <span
                  className=" ml-8 nav-btn hover:bg-gray-800 px-8 py-2 hover:text-gray-100 cursor-pointer"
                  onClick={(e) => history.push("/MultipleStores")}
                  style={{fontWeight:600}}
                >
                  Shops
                </span>
                <span
                  className=" nav-btn hover:bg-gray-800 px-8 py-2 ml-8 hover:text-gray-100 cursor-pointer"
                  onClick={(e) => history.push("/blog")}
                  style={{fontWeight:600}}
                >
                  Blog
                </span>
                {/* <span
                  className="hover:bg-gray-200 px-4 py-3 pt-6 rounded-lg hover:text-gray-800 cursor-pointer pl-12"
                  style={{color: "rgba(255, 255, 255, 0.6)", font: "inter", fontWeight:200, fontSize:15}}
                  onClick={(e) => history.push("/contact-us")}
                >
                  Contact us
                </span> */}
              </div>
            
            </div>
            
          </div>

          <div className="logo_m flex justify-center mr-8" style={{gridColumn: "3 / span 2"}}>
            
            <img onClick={(e) => history.push("/home")} src={logo} className="" style={{maxWidth: "160%"}} alt="FourTurtles-Home" />

            
          </div>
          <Search/>
          
          <div className="flex items-right col-span-2 lg:col-span-1 items-center flex justify-end">
            {/*  WishList Page Button */}
            <div
              onClick={(e) => history.push("/wish-list")}
              className="hover:bg-gray-200 rounded-lg px-2 py-2 cursor-pointer"
              title="Wishlist"
            >
              <svg
                className={`${
                  location.pathname === "/wish-list"
                    ? "fill-current text-gray-800"
                    : ""
                } w-8 h-8 text-gray-600 cursor-pointer`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
            </div>
            {localStorage.getItem("jwt") ? (
              <Fragment>
                <div
                  className="userDropdownBtn hover:bg-gray-800 px-2 py-2 rounded-lg relative"
                  title="Logout"
                >
                  {/* User icon */}
                  <svg
                    className="cursor-pointer w-8 h-8 text-gray-600  hover:text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <div className="userDropdown absolute right-0 mt-1 bg-gray-200 rounded">
                    {/* {(!isSuperAdmin()) ? ( */}
                    { !isSuperAdminUser ? ( 
                      <Fragment>
                        <li className="flex flex-col text-gray-700 w-48 shadow-lg">
                          <span
                            onClick={(e) => history.push("/user/orders")}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </span>
                            <span>My Orders</span>
                          </span>
                          <span
                            onClick={(e) => history.push("/user/profile")}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                              </svg>
                            </span>
                            <span>My Account</span>
                          </span>
                          <span
                            onClick={(e) => history.push("/wish-list")}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                />
                              </svg>
                            </span>
                            <span>My Wishlist</span>
                          </span>
                          <span
                            onClick={() => history.push("/user/setting")}
                            className="flex space-x-1 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </span>
                            <span>Setting</span>
                          </span>
                          <span
                            onClick={(e) => logout()}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                              </svg>
                            </span>
                            <span>Logout</span>
                          </span>
                          {/* <span
                            onClick={(e) => history.push("/affiliateLinkForProduct")}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                              </svg>
                            </span>
                            <span>Affiliate Program</span>
                          </span> */}
                        </li>
                      </Fragment>
                    ) :  
                    <Fragment>
                        <li className="flex flex-col text-gray-700 w-48 shadow-lg">
                            <span
                              onClick={(e) => history.push("/super_admin/dashboard")}
                              className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                            >
                              <span>
                                <svg
                                  className="w-6 h-6"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                              </span>
                              <span>Super Admin Panel</span>
                            </span>
                  
                          <span
                            onClick={(e) => logout()}
                            className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                              </svg>
                            </span>
                            <span>Logout</span>
                          </span>
                        </li>
                      </Fragment>
                    }
                  </div>
                </div>
              </Fragment>
            ) : (
              /* Login Modal Button */
              <div
                onClick={(e) => loginModalOpen()}
                className="cursor-pointer hover:bg-gray-200 px-2 py-2 rounded-lg"
                title="Login"
              >
                <svg
                  className="w-8 h-8 text-gray-600 hover:text-gray-800"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                  />
                </svg>
              </div>
            )}
            {/* Cart Modal Button */}
            <div
              onClick={(e) => cartModalOpen()}
              className="hover:bg-gray-200 px-2 py-2 rounded-lg relative cursor-pointer"
              title="Cart"
            >
              <svg
                className="w-8 h-8 text-gray-600 hover:text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
              <span className="absolute top-0 ml-6 mt-1 bg-yellow-700 rounded px-1 text-white text-xs hover:text-gray-200 font-semibold">
                {data.cartItems !== null ? data.cartItems.length : 0}
              </span>
            </div>
          </div>
        </div>
      </nav>
      <nav className="nav_blank hide_m ">

      </nav>
      <nav className="nav flex flex-col hide_m fixed z-20"> {/* For mobile version */}
          <div className="h-10 flex align-stretch flex-row flex-fill">
            <div className="logo_m" style={{flexGrow:1}}>
              <img onClick={(e) => history.push("/home")} className="logo_m" src={logo} alt="FourTurtles" />
            </div>
            <div className="" style={{flexGrow:4000}}>

            </div>
            <div className="flex" style={{flexGrow:1}}>
              <svg
                className={`${
                  location.pathname === "/wish-list"
                    ? "fill-current text-gray-800"
                    : ""
                } w-5 h-8 m-auto text-gray-600 cursor-pointer`}
                fill="none"
                onClick={(e) => history.push("/wish-list")}
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
              <IconButton color="primary" aria-label="Menu" onClick={(e)=>toggleMenu(!menu)}>
                {/* User icon */}
                <svg
                    className="cursor-pointer w-6 h-6 text-gray-600  hover:text-grey-800"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
              </IconButton>
              <div className={`${menu? "flex" : "hidden" } absolute right-0 mt-10 z-20 bg-gray-200 rounded`}>
                <Fragment>
                  <li className="flex flex-col text-gray-700 w-48 shadow-lg">
                    <span
                      onClick={(e) => history.push("/user/orders")}
                      className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                    >
                      <span>
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                      <span>My Orders</span>
                    </span>
                    <span
                      onClick={(e) => history.push("/user/profile")}
                      className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                    >
                      <span>
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                      </span>
                      <span>My Account</span>
                    </span>
                    <span
                      onClick={(e) => history.push("/wish-list")}
                      className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                    >
                      <span>
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                          />
                        </svg>
                      </span>
                      <span>My Wishlist</span>
                    </span>
                    <span
                      onClick={() => history.push("/user/setting")}
                      className="flex space-x-1 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                    >
                      <span>
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      </span>
                      <span>Setting</span>
                    </span>
                    <span
                      onClick={(e) => logout()}
                      className="flex space-x-2 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                    >
                      <span>
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                          />
                        </svg>
                      </span>
                      <span>Logout</span>
                    </span>
                  </li>
                </Fragment>
              </div>
              <div
                onClick={(e) => cartModalOpen()}
                className="hover:bg-gray-200 pr-2 py-2 rounded-lg relative cursor-pointer"
                title="Cart"
              >
                <svg
                  className="w-6 h-6 text-gray-600 hover:text-gray-800"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                  />
                </svg>
                <span className="absolute top-0 ml-4 mt-1 bg-yellow-700 rounded px-1 text-white text-xxs hover:text-gray-200 font-semibold">
                  {data.cartItems !== null ? data.cartItems.length : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="  bg-pink-4t w-100 h-10 mt-1 mx-2 ">
            <Search/>
          </div>
      </nav>
      {/* End Navber Section */}
    </Fragment>
  );
};

export default Navber;
