import { useContext } from "react";
import { createOrder } from "./FetchApi";
import { checkoutHandler } from "./FetchApi";
import { getkey } from "./FetchApi";
import { getUserName } from "./FetchApi"
import { getCartItems, clearAllCartProducts } from "./FetchApi"
import { quantity, subTotal, size } from "../partials/Mixins";
import axios from 'axios';
import { LayoutContext } from "../layout";
import { addToCart } from "../productDetails/Mixins";
import { setAffiliateData } from "../affiliateService/FetchApi"; 
const apiURL = process.env.REACT_APP_API_URL;

export const fetchData = async (cartListProduct, dispatch) => {
  dispatch({ type: "loading", payload: true });
  try {
    let cartItems = await getCartItems();
    cartItems = cartItems.cartProducts || [];
    let productArray = [];
    if (cartItems) {
      for (const cart of cartItems) {
        productArray.push(cart.productId);
      }
    }
    let responseData = await cartListProduct(productArray);
    if (responseData && responseData.Products) {
      setTimeout(function () {
        dispatch({ type: "cartProduct", payload: responseData.Products });
        dispatch({ type: "loading", payload: false });
      }, 1000);
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchbrainTree = async (getBrainTreeToken, setState) => {
  try {
    let responseData = await getBrainTreeToken();
    if (responseData && responseData) {
      setState((state)=>({
        ...state,
        clientToken: responseData.clientToken,
        success: responseData.success,
      }));
    }
  } catch (error) {
    console.log(error);
  }
};

const sendProductDetailsPostOrder = (data) => {
  let carts = JSON.parse(localStorage.getItem("cart"));
  carts.map((item) => {
    item.amountPaidAfterApplyingCoupon = subTotal(item.id, item.price, data)
  })
  return carts;
}


export const fetchLocationData = async (pincode) => {
  try {
    const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`)
    if (response.data && response.data[0].Status === "Success") {
      
      const data = response.data[0];
      const district = data.PostOffice[0].District;
      const state = data.PostOffice[0].State;
      return { district, state };
    } else {
      throw new Error('No data found for the pincode');
    }
  } catch (error) {
    console.error('Error fetching location data:', error);
    throw error;
  }
};

export const pay = async (
  data,
  dispatch,
  state,
  storeWiseProducts,
  setState,
  getPaymentProcess,
  totalCost,
  history
) => {
  console.log("state:", state);

  console.log("data:", data);
  if (!state.address) {
    setState({ ...state, error: "Please provide your" });
  } else if (!state.phone) {
    setState({ ...state, error: "Please provide your phone number" });
  } else if (!state.name) {
    setState({ ...state, error: "Please provide your name" });
  } else {
    console.log("data:", data);
    try {
      const total = { amount: await totalCost(data) };
      const orderResponse = await checkoutHandler(total);
      const keyResponse = await getkey();

      console.log("line_89:", total);
      if (orderResponse && keyResponse) {
        const order = orderResponse.order;
        const key = keyResponse.key;

        const addressData = JSON.stringify({
          state: state.state,
          address: state.address,
          pincode: state.pincode,
          district: state.district,
          city: state.city,
          name: state.name
        });
        const productsArray = data.cartProduct.map(product => ({
          id: product._id,
          subtotal: subTotal(product._id, product.pPrice, data),
          quantity: quantity(product._id, data),
          size: size(product._id, data)
        }));
        console.log("line_106:", productsArray);

        const userDetail = await getUserName();

        var options = {
          key: key,
          amount: total.amount,
          currency: "INR",
          name: "Peach tech",
          description: "Test Transaction",
          order_id: order.id,
          callback_url: `${apiURL}/api/payment/paymentverification`,
          prefill: {
            name: userDetail.User._id,
            email: "gaurav.kumar@example.com",
            contact: state.phone
          },
          notes: {
            address: "Razorpay Corporate Office",
            products: productsArray
          },
          theme: {
            color: "#3399cc"
          },
          handler: async function (response) {
            if (response.razorpay_payment_id) {
              // Call createOrder function
              const orderData = {
                allProduct: productsArray,
                userDetails: { name: userDetail.User.fullname },
                amount: total.amount,
                transactionDetails: [response.razorpay_payment_id, response.razorpay_signature, response.razorpay_order_id],
                address: addressData,
                phone: state.phone,
                coupons: data.appliedCoupons
              };


              try {
                let resposeData = await createOrder(orderData);
                console.log(resposeData);
                if (resposeData.success) {
                  clearAllCartProducts();
                  localStorage.setItem("cart", JSON.stringify([]));
                  dispatch({ type: "cartProduct", payload: null });
                  dispatch({ type: "cartTotalCost", payload: null });
                  dispatch({ type: "orderSuccess", payload: true });
                  setState({ clientToken: "", instance: {} });
                  dispatch({ type: "resetAppliedCoupons", payload: [] })
                  dispatch({ type: "cartItems", payload: [] })
                  dispatch({ type: "loading", payload: false });
                  return history.push("/home");
                } else if (resposeData.error) {
                  console.log(resposeData.error);
                }
              } catch (error) {
                console.log(error);
              }
            } else {
              console.log("Payment failed");
              // Handle payment failure
              // redirect the payment failure
            }
          }
        };

        const razor = new window.Razorpay(options);
        razor.open();
      }
    } catch (error) {
      console.log(error);
    }
  }
};
