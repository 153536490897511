import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import BlogMenu from "./BlogMenu";
import AllBlog from "./AllBlogs";
import { blogState, blogReducer } from "./BlogContext";

/* This context manage all of the Blogs component's data */
export const BlogContext = createContext();

const BlogComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <BlogMenu />
      <AllBlog />
    </div>
  );
};

const StoreBlogs = (props) => {
  const [data, dispatch] = useReducer(blogReducer, blogState);
  return (
    <Fragment>
      <BlogContext.Provider value={{ data, dispatch }}>
        <AdminLayout children={<BlogComponent />} />
      </BlogContext.Provider>
    </Fragment>
  );
};

export default StoreBlogs;
