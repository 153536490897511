import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`
    },
  };
};

export const getBrainTreeToken = async () => {
  // let uId = JSON.parse(localStorage.getItem("jwt")).user._id;
  try {
    let res = await axios.post(`${apiURL}/api/braintree/get-token`, {}, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPaymentProcess = async (paymentData) => {
  try {
    let res = await axios.post(`${apiURL}/api/braintree/payment`, paymentData, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createOrder = async (orderData) => {
  try {
    let res = await axios.post(`${apiURL}/api/order/create-order`, orderData, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};


export const checkoutHandler = async (orderData) => {
  try {
    const res = await axios.post(`${apiURL}/api/payment/checkout/`, orderData, { withCredentials: true })
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export const getUserName = async () => {
  try {
    const res = await axios.post(`${apiURL}/api/user/signle-user/`,{}, { withCredentials: true })
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export const getkey = async () => {
  try {
    const res = await axios.get(`${apiURL}/api/payment/getkey/`,{ withCredentials: true })
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export const getCouponByStore = async (storeId) => {
  const data = { storeId: storeId }
  try {
    let res = await axios.post(`${apiURL}/api/coupon/get-coupon-by-store`, data, { withCredentials: true });
    return res.data
  }
  catch (err) {
    console.log("Error in fetchApi of coupon: ", err)
  }
}

export const getCartItems = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/cart/get-cart-items`, { withCredentials: true });
    return res.data;
  } catch (err) {
    console.log(err)
  }
}

export const clearAllCartProducts = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/cart/remove-all-items`, { withCredentials: true });
    return res.data;
  } catch (err) {
    console.log(err)
  }
}

export const getSavedAddresses = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/user/get-saved-addresses`, { withCredentials: true });
    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    console.log(err)
  }
}

export const saveNewAddress = async (newAddress) => {
  const data = {
    "receiverName": newAddress.name,
    "receiverContactNumber": newAddress.phone,
    "receiverAddress": newAddress.address,
    "receiverCity": newAddress.city,
    "receiverDistrict": newAddress.district,
    "receiverState": newAddress.state,
    "receiverPincode": newAddress.pincode,
  }
  try{
   let res = await axios.post(`${apiURL}/api/user/save-address`,data, { withCredentials: true });
   return res.data;
  }catch(err){
    console.log(err)
  }
}

export const deleteAddress = async(addressId) =>{
  const data = {
    "savedAddressId": addressId
  }
  try{
    let res = await axios.post(`${apiURL}/api/user/remove-saved-address`,data, { withCredentials: true });
    return res.data;
   }catch(err){
     console.log(err)
   }

}
