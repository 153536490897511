import React, { Fragment, useContext, useState, useEffect } from "react";
import Select from "react-select";
import { createCoupon, createQuadrant } from "./FetchApi";
import { getAllStore } from "../stores/FetchApi";



const AddCouponDetail = ({ showModal, setShowModal, fetchQuadrantData}) => {


    const [stores, setAllStore] = useState([]);

    const [fData, setFdata] = useState({
      quadrant : "",
      store1: "",
      store2: "",
      store3: "",
      store4: "",
      success: false,
      error: false,
    });
  
    const fetchStoreData = async () => {
      let responseData = await getAllStore();
      if (responseData.Stores) {
        setAllStore(responseData.Stores);
      }
    };
  
    useEffect(() => {
      fetchStoreData();
    }, []);

    const submitForm = async (e) => {
        e.preventDefault();
        let responseData = await createQuadrant(fData)
        if (responseData.error) {
            fetchQuadrantData();
            setFdata({ ...fData, success: false, error: responseData.error });
            setShowModal(false);
        }
        else {
            setFdata({
                quadrant : "",
                store1: null,
                store2: null,
                store3: null,
                store4: null,
                success: responseData.success,
                error: false,
            })
            setTimeout(() => {
                setFdata({
                  ...fData,
                  success: false,
                  error: false,
                });
              }, 200);
            setShowModal(false);
        }

       
    };

    return (
        <Fragment>
            <div
                onClick={(e) => {

                    setFdata({
                        quadrant : "",
                        store1: null,
                        store2: null,
                        store3: null,
                        store4: null,
                        success: false,
                        error: false,
                    })
                    fetchQuadrantData()
                    setShowModal(false)
                }}
                className={`${showModal ? "" : "hidden"
                    } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
            />

            {/* Modal Start */}
            <div
                className={`${showModal ? "" : "hidden"
                    } fixed inset-0 flex items-center z-30 justify-center overflow-auto`}
            >
                <div className="mt-32 md:mt-0 relative bg-white w-1/3 md:w-1/3 shadow-lg flex flex-col items-center  px-4 py-4 md:px-8">
                    <div className="flex items-center justify-between w-full pt-4">
                        <span className="text-left font-semibold text-2xl tracking-wider">
                            Select the Stores
                        </span>
                        {/* Close Modal */}
                        <span
                            style={{ background: "#303031" }}
                            onClick={(e) => { 
                                setFdata({
                                    quadrant : "",
                                    store1: "",
                                    store2: "",
                                    store3: "",
                                    store4: "",
                                    success: false,
                                    error: false,
                                })
                                setShowModal(false) }
                            }
                            className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
                        >
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </span>
                    </div>
                    <form className="w-full" onSubmit={(e) => submitForm(e)}>
                        <div className="flex flex-col py-4">
                        <div className="w-1/2 flex flex-col space-y-1 m-auto">
                                <label htmlFor="status">Quadrant</label>
                                <select
                                    value={fData.quadrant}
                                    onChange={(e) =>
                                    setFdata({
                                      ...fData,
                                      quadrant : e.target.value,
                                      error: false,
                                      success: false,
                                    })
                                    }
                                    name="status"
                                    className="px-4 py-2 border focus:outline-none"
                                    id="status"
                                >
                                    <option disabled value="">
                                    Select the Quadrant
                                    </option>
                                    <option name="quadrant" value="1" >
                                        Quadrant 1
                                    </option>
                                    <option name="quadrant" value="2" >
                                        Quadrant 2
                                    </option>
                                    <option name="quadrant" value="3" >
                                        Quadrant 3
                                    </option>
                                    <option name="quadrant" value="4" >
                                        Quadrant 4
                                    </option>
                                </select>

                            </div>
                            <div className="w-1/2 flex flex-col space-y-1 m-auto">
                                <label htmlFor="status">Store 1</label>
                                <select
                                    value={fData.store1}
                                    onChange={(e) =>
                                    setFdata({
                                      ...fData,
                                      store1 : e.target.value,
                                      error: false,
                                      success: false,
                                    })
                                    }
                                    name="store"
                                    className="px-4 py-2 border focus:outline-none"
                                    id="store"
                                >
                                    <option value="">
                                        Select a Store
                                    </option>
                                    {stores && stores.length > 0 
                                    ? stores.map((elem, index) => {
                                        return (
                                            <option name="store" value={JSON.stringify({ sName: elem.sName, sId: elem._id })}  key={elem.sName}>
                                                {elem.sName}
                                            </option>
                                        );
                                    })
                                    : "No Stores Available"}
                                </select>

                            </div>
                            <div className="w-1/2 flex flex-col space-y-1 m-auto">
                                <label htmlFor="status">Store 2</label>
                                <select
                                    value={fData.store2}
                                    onChange={(e) =>
                                    setFdata({
                                      ...fData,
                                      store2 : e.target.value,
                                      error: false,
                                      success: false,
                                    })
                                    }
                                    name="status"
                                    className="px-4 py-2 border focus:outline-none"
                                    id="status"
                                >
                                    <option  value="">
                                    Select a Store
                                    </option>
                                    {stores && stores.length > 0 
                                    ? stores.map((elem, index) => {
                                    return (
                                        <option name="store" value={JSON.stringify({ sName: elem.sName, sId: elem._id })} key={elem.sName}>
                                        {elem.sName}
                                        </option>
                                    );
                                    })
                                    : "No Stores Available"}
                                </select>

                            </div>
                            <div className="w-1/2 flex flex-col space-y-1 m-auto">
                                <label htmlFor="status">Store 3</label>
                                <select
                                    value={fData.store3}
                                    onChange={(e) =>
                                    setFdata({
                                      ...fData,
                                      store3 : e.target.value,
                                      error: false,
                                      success: false,
                                    })
                                    }
                                    name="status"
                                    className="px-4 py-2 border focus:outline-none"
                                    id="status"
                                >
                                    <option  value="">
                                    Select a Store
                                    </option>
                                    {stores && stores.length > 0 
                                    ? stores.map((elem, index) => {
                                    return (
                                        <option name="store" value={JSON.stringify({ sName: elem.sName, sId: elem._id })} key={elem.sName}>
                                        {elem.sName}
                                        </option>
                                    );
                                    })
                                    : "No Stores Available"}
                                </select>

                            </div>
                            <div className="w-1/2 flex flex-col space-y-1 m-auto">
                                <label htmlFor="status">Store 4</label>
                                <select
                                    value={fData.store4}
                                    onChange={(e) =>
                                    setFdata({
                                      ...fData,
                                      store4 : e.target.value,
                                      error: false,
                                      success: false,
                                    })
                                    }
                                    name="status"
                                    className="px-4 py-2 border focus:outline-none"
                                    id="status"
                                >
                                    <option  value="">
                                    Select a Store
                                    </option>
                                    {stores && stores.length > 0 
                                    ? stores.map((elem, index) => {
                                    return (
                                        <option name="store" value={JSON.stringify({ sName: elem.sName, sId: elem._id })} key={elem.sName}>
                                        {elem.sName}
                                        </option>
                                    );
                                    })
                                    : "No Stores Available"}
                                </select>

                            </div>
                        </div>
                    


                        <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
                            <button
                                onClick={submitForm}
                                style={{ background: "#303031" }}
                                type="submit"
                                className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
                            >
                                Set/Update the Quadrant
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

const AddProductModal = ({ showModal, setShowModal }) => {

    return (
        <Fragment>
            <AddCouponDetail showModal={showModal} setShowModal={setShowModal} />
        </Fragment>
    );
};

export default AddProductModal;
