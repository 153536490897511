import React, { Fragment, useContext, useEffect, useState } from "react";
import Select from 'react-select';
import { StoreContext } from "./index";
import { createStore, getAllStore } from "./FetchApi";
import { getAllUser } from "../Users/FetchApi";
import { getAllStoreSection } from "../storeSections/FetchApi";

const AddStoreModal = (props) => {
  const { data, dispatch } = useContext(StoreContext);

  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  const [fData, setFdata] = useState({
    sName: "",
    GST: "",
    sAddress: "",
    sPincode: null,
    sSignature: "",
    sLogo: "",
    sDescription: "",
    sImage: "",
    Admin: "",
    sSection: "",
    sInvoceCount: 0,
    commisionRate: 10,
    sStatus: "Active",
    success: false,
    error: false,
  });

  const [users, setUsers] = useState([]);
  const [storeSections, setStSec] = useState([]);

  const fetchUserData = async () => {
    let responseData = await getAllUser();
    let responseData1 = await getAllStoreSection();
    if(responseData1.StoreSections) {
      setStSec(responseData1.StoreSections.map(sec => ({ value: sec._id, label: sec.ssName })));
    }
    if (responseData.Users) {
      setUsers(responseData.Users.map(user => ({ value: user._id, label: user.fullname })));
    }
  };
  
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchData = async () => {
    let responseData = await getAllStore();
    if (responseData.Stores) {
      dispatch({
        type: "fetchStoreAndChangeState",
        payload: responseData.Stores,
      });
    }
  };

  if (fData.error || fData.success) {
    setTimeout(() => {
      setFdata({ ...fData, success: false, error: false });
    }, 2000);
  }

  const submitForm = async (e) => {
    dispatch({ type: "loading", payload: true });
    e.preventDefault();
    e.target.reset();

    if (!fData.sImage || !fData.sSignature) {
      dispatch({ type: "loading", payload: false });
      return setFdata({ ...fData, error: "Please upload both a Store image and a Admin signature" });
    }

    try {
      let responseData = await createStore(fData);
      if (responseData.success) {
        fetchData();
        setFdata({
          ...fData,
          sName: "",
          sDescription: "",
          sImage: "",
          GST: "",
          sAddress: "",
          sPincode: null,
          sSignature: "",
          sLogo: "",
          Admin: "",
          sStatus: "Active",
          success: responseData.success,
          error: false,
        });
        dispatch({ type: "loading", payload: false });
        setTimeout(() => {
          setFdata({
            ...fData,
            sName: "",
            sDescription: "",
            sImage: "",
            GST: "",
            sAddress: "",
            sPincode: null,
            sSignature: "",
            sLogo: "",
            Admin: "",
            sStatus: "Active",
            success: false,
            error: false,
          });
        }, 2000);
      } else if (responseData.error) {
        setFdata({ ...fData, success: false, error: responseData.error });
        dispatch({ type: "loading", payload: false });
        setTimeout(() => {
          return setFdata({ ...fData, error: false, success: false });
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={() => dispatch({ type: "addStoreModal", payload: false })}
        className={`${
          data.addStoreModal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${
          data.addStoreModal ? "" : "hidden"
        } fixed inset-0 m-4 flex items-center z-30 justify-center`}
      >
        <div className="relative bg-white w-12/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4 overflow-y-auto px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Add Store
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={() => dispatch({ type: "addStoreModal", payload: false })}
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          {fData.error ? alert(fData.error, "red") : ""}
          {fData.success ? alert(fData.success, "green") : ""}
          <form className="w-full" onSubmit={(e) => submitForm(e)}>
            <div className="flex space-x-1">
              <div className="flex flex-col space-y-1 w-full py-4">
                <label htmlFor="name">Store Name</label>
                <input
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      success: false,
                      error: false,
                      sName: e.target.value,
                    })
                  }
                  value={fData.sName}
                  className="px-4 py-2 border focus:outline-none"
                  type="text"
                />
              </div>
              <div className="flex flex-col space-y-1 w-full py-4">
                <label htmlFor="name">GST Number</label>
                <input
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      success: false,
                      error: false,
                      GST: e.target.value,
                    })
                  }
                  value={fData.GST}
                  className="px-4 py-2 border focus:outline-none"
                  type="text"
                />
              </div>
            </div>
            <div className="flex space-x-1 py-2">
              <div className="flex flex-col space-y-1 w-1/2">
                <label htmlFor="description">Store Description</label>
                <textarea
                  onChange={(e) =>
                    setFdata({
                      ...fData,
                      success: false,
                      error: false,
                      sDescription: e.target.value,
                    })
                  }
                  value={fData.sDescription}
                  className="px-4 py-2 border focus:outline-none"
                  name="description"
                  id="description"
                  cols={5}
                  rows={5}
                />
              </div>
              <div className="flex flex-col space-x-1 w-1/2">
                <div className="flex flex-col space-y-1 w-full">
                  <label htmlFor="description">Store/Billing Address</label>
                  <textarea
                    onChange={(e) =>
                      setFdata({
                        ...fData,
                        success: false,
                        error: false,
                        sAddress: e.target.value,
                      })
                    }
                    value={fData.sAddress}
                    className="px-4 py-2 border focus:outline-none"
                    name="description"
                    id="description"
                    cols={5}
                    rows={2}
                  />
                </div>
                <div className="flex flex-col space-y-1">
                  <label htmlFor="name">Pincode</label>
                  <input
                    onChange={(e) =>
                      setFdata({
                        ...fData,
                        success: false,
                        error: false,
                        sPincode: e.target.value,
                      })
                    }
                    value={fData.sPincode}
                    className="px-4 py-2 border focus:outline-none"
                    type="number"
                    min="100000"
                  />
                </div>
              </div>
            </div>
            {/* Image Field & function */}
            <div className="flex space-x-1 py-4">
              <div className="flex flex-col space-x-1 w-1/2">
                <div className="flex flex-col space-y-1">
                  <label htmlFor="name">Store Logo</label>
                  <input
                    accept=".jpg, .jpeg, .png, .avif"
                    onChange={(e) => {
                      setFdata({
                        ...fData,
                        success: false,
                        error: false,
                        sLogo: e.target.files[0],
                      });
                    }}
                    className="px-4 py-2 border focus:outline-none"
                    type="file"
                  />
                </div>
                <div className="flex flex-col space-y-1">
                  <label htmlFor="name">Admin Signature</label>
                  <input
                    accept=".jpg, .jpeg, .png, .avif"
                    onChange={(e) => {
                      setFdata({
                        ...fData,
                        success: false,
                        error: false,
                        sSignature: e.target.files[0],
                      });
                    }}
                    className="px-4 py-2 border focus:outline-none"
                    type="file"
                  />
                </div>
              </div>
              <div className="flex w-1/2 flex-col space-x-1">
                <div className="flex flex-col space-y-1 w-full">
                  <label htmlFor="name">Display Image</label>
                  <input
                    accept=".jpg, .jpeg, .png, .avif"
                    onChange={(e) => {
                      setFdata({
                        ...fData,
                        success: false,
                        error: false,
                        sImage: e.target.files[0],
                      });
                    }}
                    className="px-4 py-2 border focus:outline-none"
                    type="file"
                  />
                </div>
                <div className="w-full flex flex-col space-y-1">
                  <label htmlFor="storeSection">Store Section *</label>
                  <Select
                    value={storeSections.find(option => option.value === fData.sSection)}
                    onChange={selectedOption => 
                      setFdata({
                        ...fData,
                        success: false,
                        error: false,
                        sSection: selectedOption ? selectedOption.value : "",
                      })
                    }
                    options={storeSections}
                    className="basic-single"
                    classNamePrefix="select"
                  />
                </div>
              </div>
            </div>

            <div className="flex space-x-1 py-4">
              <div className="flex flex-col space-y-1 w-1/2">
                <label htmlFor="status">Store Status</label>
                <Select
                  value={{ value: fData.sStatus, label: fData.sStatus }}
                  onChange={selectedOption => 
                    setFdata({
                      ...fData,
                      success: false,
                      error: false,
                      sStatus: selectedOption ? selectedOption.value : "Active",
                    })
                  }
                  options={[
                    { value: "Active", label: "Active" },
                    { value: "Disabled", label: "Disabled" }
                  ]}
                  className="basic-single"
                  classNamePrefix="select"
                />
              </div>
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="admin">Store Admin *</label>
                <Select
                  value={users.find(option => option.value === fData.Admin)}
                  onChange={selectedOption => 
                    setFdata({
                      ...fData,
                      success: false,
                      error: false,
                      Admin: selectedOption ? selectedOption.value : "",
                    })
                  }
                  options={users}
                  className="basic-single"
                  classNamePrefix="select"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
              <button
                style={{ background: "#303031" }}
                type="submit"
                className="bg-gray-800 text-gray-100 rounded-full text-lg font-medium py-2"
              >
                Create Store
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddStoreModal;
