import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

const Submenu = (props) => {
  const { categoryId, category, product } = props.value;
  const history = useHistory();
  return (
    <Fragment>
      {/* Submenu Section */}
      <section className="mx-4 mt-16 md:mx-20 md:mt-48 lg:mt-32 absolute z-10">
        <div className="flex justify-between items-center">
          <div className="md:text-xxs flex align-items-center space-x-2">
            <span
              className="hover:text-yellow-700 cursor-pointer text-sm lg:text-base font-bold"
              onClick={(e) => history.push("/home")}
            >
              Home
            </span>
            <span className="mx-2 text-sm lg:text-base font-bold">&#187;</span>
            
            <span
              className="hover:text-yellow-700 cursor-pointer text-sm lg:text-base font-bold"
              onClick={(e) => history.push(`/products/category/${categoryId}`)}
            >
              {category}
            </span>
            <span className="mx-2 text-sm lg:text-base font-bold">&#187;</span>
            <span className="text-yellow-700 cursor-default text-sm lg:text-base font-bold">{product}</span>
          </div>
        </div>
      </section>
      {/* Submenu Section */}
    </Fragment>
  );
};

export default Submenu;
