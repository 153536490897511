import React, { Fragment, useContext, useState, useEffect } from "react";
import { ProductContext } from "./index";
import { editProduct, getAllProduct } from "./FetchApi";
import { getAllCategory } from "../categories/FetchApi";
import { getAllStore } from "../stores/FetchApi";
import Select from 'react-select';
import { Button } from "@material-ui/core";

const EditProductModal = (props) => {
  const { data, dispatch } = useContext(ProductContext);
  const [categories, setCategories] = useState([]);
  const [stores, setStores] = useState([]);
  const [sizes, setSizes] = useState({ size: '', quantity: '' });

  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  const [editformData, setEditformdata] = useState({
    pId: "",
    pName: "",
    pDescription: "",
    pImages: null,
    pEditImages: null,
    pStatus: "",
    url: "",
    pCategory: null,
    pStore: null,
    pQuantity: "",
    similarProducts: [],
    pPrice: "",
    pOffer: "",
    error: false,
    success: false,
  });

  const fetchCategoryData = async () => {
    let responseData = await getAllCategory();
    if (responseData.Categories) {
      setCategories(responseData.Categories.map(cat => ({
        value: cat._id,
        label: cat.cName,
      })));
    }
    let responseData1 = await getAllStore();
    if (responseData1.Stores) {
      setStores(responseData1.Stores.map(store => ({
        value: store._id,
        label: store.sName,
      })));
    }
  };

  useEffect(() => {
    fetchCategoryData();
  }, []);

  useEffect(() => {
    setEditformdata({
      pId: data.editProductModal.pId,
      pName: data.editProductModal.pName,
      pDescription: data.editProductModal.pDescription,
      pImages: data.editProductModal.pImages,
      url: data.editProductModal.url,
      pStatus: data.editProductModal.pStatus,
      pCategory: categories.find(cat => cat.value === data.editProductModal.pCategory) || null,
      pStore: stores.find(store => store.value === data.editProductModal.pStore) || null,
      pQuantity: data.editProductModal.pQuantity,
      similarProducts: data.editProductModal.similarProducts,
      pPrice: data.editProductModal.pPrice,
      pOffer: data.editProductModal.pOffer,
    });
  }, [data.editProductModal, categories, stores]);

  const fetchData = async () => {
    let responseData = await getAllProduct();
    if (responseData && responseData.Products) {
      dispatch({
        type: "fetchProductsAndChangeState",
        payload: responseData.Products,
      });
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const updatedData = { ...editformData, pQuantity: JSON.stringify(editformData.pQuantity) };
    try {
      let responseData = await editProduct(updatedData);
      updatedData.pQuantity = JSON.parse(updatedData.pQuantity);
      if (responseData.success) {
        fetchData();
        setEditformdata({ ...updatedData, success: responseData.success });
        dispatch({ type: "editProductModalClose", payload: false });
        setTimeout(() => {
          return setEditformdata({
            ...updatedData,
            success: responseData.success,
          });
        }, 2000);
      } else if (responseData.error) {
        setEditformdata({ ...updatedData, error: responseData.error });
        setTimeout(() => {
          return setEditformdata({
            ...updatedData,
            error: responseData.error,
          });
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={(e) =>
          dispatch({ type: "editProductModalClose", payload: false })
        }
        className={`${
          data.editProductModal.modal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${
          data.editProductModal.modal ? "" : "hidden"
        } fixed inset-0 flex items-center z-30 justify-center overflow-auto`}
      >
        <div className="mt-32 md:mt-0 relative bg-white w-11/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4 px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Edit Product
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={(e) =>
                dispatch({ type: "editProductModalClose", payload: false })
              }
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          {editformData.error ? alert(editformData.error, "red") : ""}
          {editformData.success ? alert(editformData.success, "green") : ""}
          <form className="w-full" onSubmit={(e) => submitForm(e)}>
            <div className="flex space-x-1 py-4">
              <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                <label htmlFor="name">Product Name *</label>
                <input
                  value={editformData.pName}
                  onChange={(e) =>
                    setEditformdata({
                      ...editformData,
                      error: false,
                      success: false,
                      pName: e.target.value,
                    })
                  }
                  className="px-4 py-2 border focus:outline-none"
                  type="text"
                />
              </div>
              <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                <label htmlFor="price">Product Price *</label>
                <input
                  value={editformData.pPrice}
                  onChange={(e) =>
                    setEditformdata({
                      ...editformData,
                      error: false,
                      success: false,
                      pPrice: e.target.value,
                    })
                  }
                  type="number"
                  className="px-4 py-2 border focus:outline-none"
                  id="price"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="description">Product Description *</label>
              <textarea
                value={editformData.pDescription}
                onChange={(e) =>
                  setEditformdata({
                    ...editformData,
                    error: false,
                    success: false,
                    pDescription: e.target.value,
                  })
                }
                className="px-4 py-2 border focus:outline-none"
                name="description"
                id="description"
                cols={5}
                rows={2}
              />
            </div>
            {/* Most Important part for uploading multiple image */}
            <div className="flex space-x-1 py-4">
              <div className="flex flex-col mt-4 w-1/2">
                <label htmlFor="image">Product Images *</label>
                {editformData.pImages ? (
                  <div className="flex space-x-1">
                    <img
                      className="h-16 w-16 object-cover"
                      src={`${editformData.url[0]}`}
                      alt="productImage"
                    />
                    <img
                      className="h-16 w-16 object-cover"
                      src={`${editformData.url[1]}`}
                      alt="productImage"
                    />
                  </div>
                ) : (
                  ""
                )}
                <span className="text-gray-600 text-xs">Must need 2 images</span>
                <input
                  onChange={(e) =>
                    setEditformdata({
                      ...editformData,
                      error: false,
                      success: false,
                      pEditImages: [...e.target.files],
                    })
                  }
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className="px-4 py-2 border focus:outline-none"
                  id="image"
                  multiple
                />
              </div>
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="quantity">Product in Stock *</label>
                <div className="flex flex-row">
                  {(editformData.pQuantity && editformData.pQuantity.length > 0)
                    ? editformData.pQuantity.map((elem) => {
                      return (
                        <div className="bg-gray-400 mr-2 rounded flex flex-row text-center">
                          {elem.size} : {elem.quantity}
                          <div
                            className="rounded-full px-1 m-1 bg-white"
                            style={{ fontSize: "1vh" }}
                            onClick={() => {
                              const newQuantity = editformData.pQuantity.filter((obj) => obj !== elem);
                              setEditformdata({
                                ...editformData,
                                pQuantity: newQuantity,
                              });
                              setSizes({
                                size: "",
                                quantity: "",
                              });
                              console.log("removed:", elem);
                            }}
                          >
                            <svg
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                        </div>
                      );
                    })
                    : <p style={{ color: "lightgray" }}>Please select a size</p>}
                </div>
                <div className="w-full flex flex-row space-x-1">
                  <select
                    value={sizes.size}
                    onChange={(e) =>
                      setSizes({
                        ...sizes,
                        size: e.target.value,
                      })
                    }
                    name="status"
                    className="px-4 py-2 border focus:outline-none"
                    id="status"
                  >
                    <option disabled value="">
                      sizes
                    </option>
                    <option name="status" value="XS">XS</option>
                    <option name="status" value="S">S</option>
                    <option name="status" value="M">M</option>
                    <option name="status" value="L">L</option>
                    <option name="status" value="XL">XL</option>
                    <option name="status" value="XXL">XXL</option>
                  </select>
                  <input
                    value={sizes.quantity}
                    onChange={(e) =>
                      setSizes({
                        ...sizes,
                        quantity: e.target.value,
                      })
                    }
                    type="number"
                    className="px-4 py-2 border focus:outline-none"
                    id="quantity"
                  />
                  <Button variant="outlined" onClick={() => {
                    if (sizes.size && sizes.quantity) {
                      setEditformdata({
                        ...editformData,
                        pQuantity: [...editformData.pQuantity, sizes],
                      });
                      setSizes({
                        size: "",
                        quantity: "",
                      });
                    }
                  }}>
                    Add
                  </Button>
                </div>
              </div>
            </div>
            {/* Most Important part for uploading multiple image */}
            <div className="flex space-x-1 py-4">
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="store">Store Name *</label>
                <Select
                  value={editformData.pStore}
                  onChange={(selectedOption) =>
                    setEditformdata({
                      ...editformData,
                      error: false,
                      success: false,
                      pStore: selectedOption,
                    })
                  }
                  options={stores}
                  placeholder="Select a Store"
                />
              </div>
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="status">Product Category *</label>
                <Select
                  value={editformData.pCategory}
                  onChange={(selectedOption) =>
                    setEditformdata({
                      ...editformData,
                      error: false,
                      success: false,
                      pCategory: selectedOption,
                    })
                  }
                  options={categories}
                  placeholder="Select a Category"
                />
              </div>
            </div>
            <div className="flex space-x-1 py-4">
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="status">Product Status *</label>
                <select
                  value={editformData.pStatus}
                  onChange={(e) =>
                    setEditformdata({
                      ...editformData,
                      error: false,
                      success: false,
                      pStatus: e.target.value,
                    })
                  }
                  name="status"
                  className="px-4 py-2 border focus:outline-none"
                  id="status"
                >
                  <option name="status" value="Active">Active</option>
                  <option name="status" value="Disabled">Disabled</option>
                </select>
              </div>
              <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="offer">Product Offer (%) *</label>
                <input
                  value={editformData.pOffer}
                  onChange={(e) =>
                    setEditformdata({
                      ...editformData,
                      error: false,
                      success: false,
                      pOffer: e.target.value,
                    })
                  }
                  type="number"
                  className="px-4 py-2 border focus:outline-none"
                  id="offer"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
              <button
                style={{ background: "#303031" }}
                type="submit"
                className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
              >
                Update product
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default EditProductModal;
