import React, { Fragment, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ProductByStore } from "../../admin/products/FetchApi";
import { LayoutContext } from "../layout";
import { isWishReq, unWishReq, isWish } from "./Mixins";
import { getCartItems } from "../wishlist/FetchApi";
import { Button } from "@material-ui/core";


const StoreWiseProduct = ( storeId ) => {

  const { data, dispatch } = useContext(LayoutContext);
  const productLine =`productsLine${storeId.position}`;
  const Products = data[productLine];
  const history = useHistory();

  /* WhisList State */
  const [wList, setWlist] = useState(null);

  const getWishListedProducts = async () => {
    let wishlistProducts = await getCartItems();
    wishlistProducts = wishlistProducts&& wishlistProducts.wishlistProducts ||[];
    wishlistProducts = wishlistProducts.map((wishlistProduct) => wishlistProduct.productId);
    setWlist(wishlistProducts)
  }

  const handleIsWishlistReq = async (id) => {
    await isWishReq(id);
    fetchData();
  }

  const handleUnWishlistReq = async (id) => {
    await unWishReq(id);
    fetchData();
  }

  const fetchData = async () => {
    getWishListedProducts();
    try {
      console.log("38:",storeId);
      let responseData = await ProductByStore(storeId.storeId);
      console.log("39:",responseData);
      setTimeout(() => {
        if (responseData && responseData.Products) {
          dispatch({ type: `setProductsLine${storeId.position}`, payload: responseData.Products });
        }
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  if (data.loading) {
    return (
      <div className="col-span-2 md:col-span-3 lg:col-span-5 flex items-center justify-center py-24">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  }
  return (
    <Fragment>
      
      <div className=" bg-gray-100" >
        <div className="flex justify-center p-2 pt-4">
          {Products && Products.length > 0 ? (
            <div className=" text-sm md:text-2xl tracking-widest uppercase" >
             The {Products[0].pStore.sName} store
            </div>
          ):(null)
          }
        </div>
        <div className="flex justify-center">
          <Button
            variant="outlined"
            onClick={(e) => history.push(`/stores/${Products[0].pStore.sName}`)}
          >
           <p className=" text-xs md:text-2xl text-bg-light tracking-widest">
            Veiw All
           </p>
          </Button>
        </div>
        <div className=" justify-center m-2 md:mx-4 md:my-2 grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5">
          {Products && Products.length > 0 ? (
            Products.slice(0, 5).map((item, index) => {
              return (
                <Fragment key={index}>
                  <div className="relative col-span-1 m-1  overflow-hidden" style={{borderRadius: 0}}>
                    <div className="flex flex-col justify-center items-center overflow-hidden">
                      <img
                        onClick={(e) => history.push(`/products/${item._id}`)}
                        className="w-full object-cover object-center cursor-pointer img_magnify"
                        src={`${item.url[0]}`}
                        alt=""
                      />
                    </div>
                    <div className="flex items-center justify-between mt-2 pr-4 pl-1 bg-transparent">
                      <div className="text-gray-600 text-xs font-smaller font-hairline truncate">
                        {item.pName}
                      </div>
                      <div className="flex items-center space-x-1">
                        <span>
                          <svg
                            className="w-2 h-2 md:w-4 md:w-4 fill-current text-yellow-700"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                            />
                          </svg>
                        </span>
                        <span className="text-gray-700 text-xs md:text-lg ">
                          {item.pRatingsReviews.length}
                        </span>
                      </div>
                    </div>
                    <div className="p-4 pl-1 pt-0 text-xs md:text-lg font-light">₹{item.pPrice}.00</div>
                    {/* WhisList Logic  */}
                    <div className="absolute top-0 right-0 mx-2 my-2 md:mx-4">
                      <svg
                        onClick={(e) => handleIsWishlistReq(item._id)}
                        className={`${isWish(item._id, wList) && "hidden"
                          } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                      </svg>
                      <svg
                        onClick={(e) => handleUnWishlistReq(item._id)}
                        className={`${!isWish(item._id, wList) && "hidden"
                          } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    {/* WhisList Logic End */}
                  </div>
                </Fragment>
              );
            })
          ) : (
            <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
              No product found
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default StoreWiseProduct;

// lazyProducts copied the contents of products for lazyLoading
// Changed the props so to get the pressed button request to load more cloths