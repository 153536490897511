import React, { Fragment, useContext, useState, useEffect } from "react";
import Select from 'react-select';
import { StoreContext } from "./index";
import { editStore, getAllStore } from "./FetchApi";
import { getAllUser } from "../Users/FetchApi";
import { getAllStoreSection } from "../storeSections/FetchApi";

const EditStoreModal = (props) => {
  const { data, dispatch } = useContext(StoreContext);

  const [editformData, setEditformdata] = useState({
    name: "",
    admin: null,
    des: "",
    image: null,
    editImage: null,
    url: "",
    status: "",
    section: null,
    sId: "",
  });

  const [users, setUsers] = useState([]);
  const [storeSections, setStoreSections] = useState([]);

  const fetchUserData = async () => {
    let responseData = await getAllUser();
    let responseData1 = await getAllStoreSection();
    if (responseData1.StoreSections) {
      setStoreSections(responseData1.StoreSections.map(sec => ({ value: sec._id, label: sec.ssName })));
    }
    if (responseData.Users) {
      setUsers(responseData.Users.map(user => ({ value: user._id, label: user.fullname })));
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (data.editStoreModal.sSection) {
      setEditformdata({
        name: data.editStoreModal.sName,
        image: data.editStoreModal.sImage,
        url: data.editStoreModal.url,
        des: data.editStoreModal.des,
        admin: data.editStoreModal.admin._id,
        status: data.editStoreModal.status,
        section: data.editStoreModal.sSection._id,
        sId: data.editStoreModal.sId,
      });
    } else {
      setEditformdata({
        name: data.editStoreModal.sName,
        image: data.editStoreModal.sImage,
        url: data.editStoreModal.url,
        des: data.editStoreModal.des,
        admin: data.editStoreModal.admin._id,
        status: data.editStoreModal.status,
        section: null,
        sId: data.editStoreModal.sId,
      });
    }
  }, [data.editStoreModal]);

  const fetchData = async () => {
    let responseData = await getAllStore();
    if (responseData.Stores) {
      dispatch({
        type: "fetchStoreAndChangeState",
        payload: responseData.Stores,
      });
    }
  };

  const submitForm = async () => {
    dispatch({ type: "loading", payload: true });
    if (!editformData.editImage) {
      console.log("Image Not uploaded=============", editformData);
    } else {
      console.log("Image uploading");
    }
    console.log("admin_check:", editformData);
    let edit = await editStore(editformData);
    if (edit.error) {
      console.log(edit.error);
      dispatch({ type: "loading", payload: false });
    } else if (edit.success) {
      console.log(edit.success);
      dispatch({ type: "editStoreModalClose" });
      setTimeout(() => {
        fetchData();
        dispatch({ type: "loading", payload: false });
      }, 1000);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={() => dispatch({ type: "editStoreModalClose" })}
        className={`${
          data.editStoreModal.modal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${
          data.editStoreModal.modal ? "" : "hidden"
        } fixed inset-0 m-4  flex items-center z-30 justify-center`}
      >
        <div className="relative bg-white w-11/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4  overflow-y-auto px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Edit Store
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={() => dispatch({ type: "editStoreModalClose" })}
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          <div className="flex flex-col space-y-1 w-full">
            <label htmlFor="name">Store Name</label>
            <textarea
              value={editformData.name}
              onChange={(e) => 
                setEditformdata({
                  ...editformData,
                  name: e.target.value,
                })
              }
              className="px-4 py-2 border focus:outline-none"
              name="name"
              id="name"
              cols={5}
              rows={1}
            />
          </div>
          <div className="flex flex-col space-y-1 w-full">
            <label htmlFor="description">Store Description</label>
            <textarea
              value={editformData.des}
              onChange={(e) => 
                setEditformdata({
                  ...editformData,
                  des: e.target.value,
                })
              }
              className="px-4 py-2 border focus:outline-none"
              name="description"
              id="description"
              cols={5}
              rows={5}
            />
          </div>
          <div className="w-full flex space-x-1 py-4">
            <div className="flex flex-col w-1/2">
              <label htmlFor="image">Store Image *</label>
              {editformData.image ? (
                <div className="flex space-x-1">
                  <img
                    className="h-16 w-16 object-cover"
                    src={`${editformData.url}`}
                    alt="productImage"
                  />
                </div>
              ) : (
                ""
              )}
              <input
                onChange={(e) => {
                  setEditformdata({
                    ...editformData,
                    editImage: e.target.files[0],
                  })
                }}
                type="file"
                accept=".jpg, .jpeg, .png, .avif"
                className="px-4 py-2 border focus:outline-none"
                id="image"
              />
            </div>
            <div className="w-1/2 flex flex-col space-y-1">
              <label htmlFor="admin">Store Admin *</label>
              <Select
                value={users.find(user => user.value === editformData.admin)}
                onChange={(selectedOption) => 
                  setEditformdata({
                    ...editformData,
                    admin: selectedOption ? selectedOption.value : null,
                  })
                }
                options={users}
                className="basic-single"
                classNamePrefix="select"
              />
            </div>
          </div>
          <div className="w-full flex space-x-1 py-4">
            <div className="w-1/2 flex flex-col space-y-1">
              <label htmlFor="section">Store Section *</label>
              <Select
                value={storeSections.find(section => section.value === editformData.section)}
                onChange={(selectedOption) => 
                  setEditformdata({
                    ...editformData,
                    section: selectedOption ? selectedOption.value : null,
                  })
                }
                options={storeSections}
                className="basic-single"
                classNamePrefix="select"
              />
            </div>
            <div className="flex flex-col space-y-1 w-full">
              <label htmlFor="status">Store Status</label>
              <select
                value={editformData.status}
                name="status"
                onChange={(e) => 
                  setEditformdata({
                    ...editformData,
                    status: e.target.value,
                  })
                }
                className="px-4 py-2 border focus:outline-none"
                id="status"
              >
                <option name="status" value="Active">
                  Active
                </option>
                <option name="status" value="Disabled">
                  Disabled
                </option>
              </select>
            </div>
          </div>
          <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6">
            <button
              style={{ background: "#303031" }}
              onClick={() => submitForm()}
              className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
            >
              Update Store
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditStoreModal;
