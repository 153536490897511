import React, { Fragment, useContext, useEffect, useState } from "react";
import AddCouponModal from "./AddCouponModal.js"
import { getCouponByStore, deleteCoupon } from "./FetchApi.js";
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { useParams } from "react-router-dom";

const CouponMenu = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [coupons, setCoupons] = useState([])
  const [loading, setLoading] = useState(false);
  const [storeId, setStoreId] = useState(null);
  const { storeName } = useParams();

  const fetchData = async () => {
    if (!storeId) {
      await fetchStoreId();
    }

    if (storeId) {
      setLoading(true);
      const responseData = await getCouponByStore(storeId);      
      if (responseData.error) {
        console.error("Error fetching coupons:", responseData.error);
      } else {
        setCoupons(responseData);
      }
      setLoading(false);
    }
  };

  const fetchStoreId = async () => {
    const storedData = JSON.parse(await AsyncLocalStorage.getItem(storeName));
    setStoreId(storedData?.Store?._id || "");
  };

  useEffect(() => {
    fetchData();
  }, [storeId,showModal]);

  const handleDeleteCoupon = async (couponIndex) => {
    const responseData = await deleteCoupon(coupons[couponIndex]._id)
    if (responseData.error)
      console.log("error from delete : ", responseData.error);
    else {

      fetchData();
    }
  }

  return (
    <Fragment>
      <div className="col-span-1 flex justify-between items-center">
        <div className="flex items-center">
          {/* It's open the add product modal */}
          <span
            style={{ background: "#303031" }}
            onClick={(e) => {
              setShowModal(true)
            }
            }
            className="rounded-full cursor-pointer p-2 bg-gray-800 flex items-center text-gray-100 text-sm font-semibold uppercase"
          >
            <svg
              className="w-6 h-6 text-gray-100 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              />
            </svg>
            Add Coupon
          </span>
        </div>
        <AddCouponModal showModal={showModal} setShowModal={setShowModal} />
        {/* <EditCouponModal /> */}



      </div>
      <table>
        <thead>
          <tr>
            <th>Coupon Name</th>
            <th>Coupon Description</th>
            <th>Minimum Amount</th>
            <th>Discount Type</th>
            <th>Dicsount Value</th>
            <th>Operations</th>
          </tr>
        </thead>
        <tbody>
          {coupons ? (
            coupons.map((coupon, key) => {
              return (
                <tr id={key}>
                  <td>{coupon.couponName}</td>
                  <td>{coupon.couponDescription}</td>
                  <td>{coupon.applicableMinimumAmount}</td>
                  <td>{coupon.applicableDiscountType}</td>
                  <td>{coupon.applicableDiscountValue} {coupon.applicableDiscountType === 'percentage' ? "%" : "rupees"}</td>
                  <td><button onClick={() => handleDeleteCoupon(key)}>Delete</button></td>
                </tr>)

            })
          ) : <div>No items</div>}
        </tbody>
        {coupons ? (coupons.map((coupon) => {
          return(
            <tr>
              <td>coupon.couponName</td>
            </tr>
          )
        })) : <div>No coupons found</div>}
      </table>

    </Fragment>
  );
};

export default CouponMenu;
