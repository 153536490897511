import React, { Fragment, useEffect, useContext, useState } from "react";
import moment from "moment";
import Layout from "./Layout";
import { DashboardUserContext } from "./Layout";
import { fetchOrderByUser } from "./Action";
import { updateOrder, getTrackingData, getInvoiceLink } from "./FetchApi";
import Button from "@material-ui/core/Button";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import SaveAlt from "@material-ui/icons/SaveAlt";
import "./style.css"
const ConfirmDialog = ({ open, handleClose, handleAgree, title, description }) => {
  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={handleClose}
        className={`${open ? "" : "hidden"} fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}


      {/* Modal Start */}
      <div
        className={`${open ? "" : "hidden"} fixed inset-0 m-4 flex items-center z-30 justify-center`}
      >
        <div className="relative bg-white w-full md:w-3/4 shadow-lg flex flex-col items-center space-y-4 overflow-y-auto px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">{title}</span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={handleClose}
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          <div className="flex flex-col space-y-4 w-full">
            <p>{description}</p>
            <div className="flex justify-end w-full space-x-4">
              <button
                style={{ background: "#303031" }}
                onClick={handleAgree}
                className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2 px-6"
              >
                Agree
              </button>
              <button
                onClick={handleClose}
                className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2 px-6"
              >
                Disagree
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const TrackingDialog = ({ open, handleClose, trackingData, orderId, orderCreatedAt }) => {
  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={handleClose}
        className={`${open ? "" : "hidden"} fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${open ? "" : "hidden"} fixed inset-0 m-4 flex items-center z-30 justify-center`}
      >
        <div className="relative bg-white w-full md:w-1/2 shadow-lg flex flex-col items-center space-y-4 overflow-y-auto px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">Tracking Information</span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={handleClose}
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          <div className="flex flex-col space-y-4 w-full">
            <div>
              <figure>
                <figcaption>
                  <h6>Order Number</h6>
                  <h2>{orderId}</h2>
                </figcaption>
              </figure>
              <div className="order-track">
                <div className="order-track-step">
                  <div className="order-track-status">
                    <span className="order-track-status-dot"></span>
                    { trackingData && trackingData.ShipmentData[0].Shipment.Scans.length > 0 ? (
                      <span className="order-track-status-line"></span>
                    ): (
                      <span></span>
                    )}
                  </div>
                  <div className="order-track-text">
                    <p className="order-track-text-stat">Order Created</p>
                    <span className="order-track-text-sub">{moment(orderCreatedAt).format("LL")}</span>
                  </div>
                </div>
                {trackingData && trackingData.ShipmentData[0].Shipment.Scans.length > 0 ? (
                  trackingData.ShipmentData[0].Shipment.Scans.map((scan, index) => (
                    <div key={index} className="order-track-step">
                      <div className="order-track-status">
                        <span className="order-track-status-dot"></span>
                        <span className="order-track-status-line"></span>
                      </div>
                      <div className="order-track-text">
                        <p className="order-track-text-stat">Scanned In - {scan.ScanDetail.ScannedLocation}</p>
                        <span className="order-track-text-sub">{moment(scan.ScanDetail.ScanDateTime).format("LLL")}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div className="flex justify-end w-full space-x-4">
              <button
                onClick={handleClose}
                className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2 px-6"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};


const alert = (msg, type) => (
  <div className={`text-sm text-${type}-500`}>{msg}</div>
);

const TableBody = ({ order }) => {
  const { dispatch } = useContext(DashboardUserContext);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [trackingOpen, setTrackingOpen] = useState(false);
  const [trackingData, setTrackingData] = useState(null);

  const handleAgree = () => {
    updateOrder(order._id, order.status, dispatch);
    setUpdateOpen(false);
  };

  const handleTrack = async () => {
    const data = await getTrackingData(order.shippingDetails.waybill);
    setTrackingData(data);
    setTrackingOpen(true);
  };

  const handleInvoiceDownload = async () => {
    try {
      const invoiceLink = await getInvoiceLink(order.invoiceKey); // Await the promise to get the URL
      if (invoiceLink) {
        window.open(invoiceLink, '_blank'); // Open the URL in a new tab
      } else {
        console.error('Invoice link is not available.');
      }
    } catch (error) {
      console.error('Failed to fetch invoice link:', error);
    }
  };
  
  return (
    <Fragment>
      <div className="rounded-lg p-4 flex flex-row justify-around" style={{ backgroundColor: "rgba(174, 174, 174, 0.403)" }}>
        <div className="w-48 flex flex-col space-y-1 w-1/3">
          {order.allProduct.map((product, i) => {
            return (
              <span className="block flex items-center space-x-2" key={i}>
                <img
                  className="w-32 h-32 object-cover object-center"
                  src={`${product.id.url}`}
                  alt="productImage"
                />
                <div className="p-4 mb-4">
                  <span className="text-xs" style={{ fontSize: "12px" }}>{order.store.sName}</span>
                  <br />
                  <span style={{ fontSize: "20px" }}>{product.id.pName} </span><br />
                  <span className="" style={{ fontSize: "12px" }}>Quantity: {product.quantitiy}</span>
                  <span className="hover:bg-gray-200 pt-8" >
                    {order.status === "Not processed" && (
                      <span className="block text-red-600 rounded-full text-xs pt-4 font-semibold">
                        {order.status}
                      </span>
                    )}
                    {order.status === "Processing" && (
                      <span className="block text-yellow-600 rounded-full text-xs pt-4 font-semibold">
                        {order.status}
                      </span>
                    )}
                    {order.status === "Shipped" && (
                      <span className="block text-blue-600 rounded-full text-xs pt-4 font-semibold">
                        {order.status}
                      </span>
                    )}
                    {order.status === "Delivered" && (
                      <span className="block text-green-600 rounded-full text-xs pt-4 font-semibold">
                        {order.status}
                      </span>
                    )}
                    {order.status === "Cancelled" && (
                      <span className="block text-red-600 rounded-full text-xs pt-4 font-semibold">
                        Order Cancelled
                      </span>
                    )}
                    {order.status === "Return Requested" && (
                      <span className="block text-red-600 rounded-full text-xs font-semibold">
                        Return Requested
                      </span>
                    )}
                    {order.status === "Returned" && (
                      <span className="block text-red-600 rounded-full text-xs font-semibold">
                        Returned
                      </span>
                    )}
                  </span>
                </div>
              </span>
            );
          })}
        </div>
        <div className="p-2 w-128">
          <div className="text-white inline">Order Id:</div> {order.transactionDetails[2]}
          <br /><br />
          <div className="text-white inline">Order Placed:</div> {moment(order.createdAt).format("lll")}
          <br /><br />
          <span className=" ">
            {order.status === "Not processed" && (
              <div>
                <Button variant="outlined" color="error" onClick={() => setUpdateOpen(true)}>Cancel order</Button>
                  <ConfirmDialog
                    open={updateOpen}
                    handleClose={() => setUpdateOpen(false)}
                    handleAgree={handleAgree}
                    title="Confirm Order Cancellation"
                    description="Are you sure you want to cancel this order?"
                  />
                </div>
            )}
            {order.status === "Processing" && (
              <div>
                <Button variant="outlined" color="error" onClick={() => setUpdateOpen(true)}>Cancel order</Button>
                <ConfirmDialog
                  open={updateOpen}
                  handleClose={() => setUpdateOpen(false)}
                  handleAgree={handleAgree}
                  title="Confirm Order Cancellation"
                  description="Are you sure you want to cancel this order?"
                />
              </div>
            )}
            {order.status === "Shipped" && (
              <div>
                <Button variant="outlined" color="success" onClick={handleTrack}>Track</Button>
                <TrackingDialog
                  open={trackingOpen}
                  handleClose={() => setTrackingOpen(false)}
                  trackingData={trackingData}
                  orderId={order.transactionDetails[2]}
                  orderCreatedAt={order.createdAt}
                />
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleInvoiceDownload}
                  startIcon={<SaveAlt />}
                >
                  Invoice
                </Button>
              </div>
            )}
            {order.status === "Delivered" && (
              <div>
                <Button variant="outlined" color="error" onClick={() => setUpdateOpen(true)}>Return/Exchange</Button>
                <ConfirmDialog
                  open={updateOpen}
                  handleClose={() => setUpdateOpen(false)}
                  handleAgree={handleAgree}
                  title="Confirm Return/Exchange"
                  description="Are you sure you want to return or exchange this order?"
                />
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleInvoiceDownload}
                  startIcon={<SaveAlt />}
                >
                  Invoice
                </Button>

              </div>
            )}
          </span>
        </div>
      </div>
    </Fragment>
  );
};

const OrdersComponent = () => {
  const { data, dispatch } = useContext(DashboardUserContext);
  const { OrderByUser: orders } = data;

  useEffect(() => {
    fetchOrderByUser(dispatch);
  }, [dispatch]);

  if (data.loading) {
    return (
      <div className="w-full md:w-9/12 flex items-center justify-center py-24">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  }

  // Ensure orders is an array
  const sortedOrders = Array.isArray(orders)
    ? [...orders].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    : [];

  return (
    <Fragment>
      <div className="flex flex-col w-full my-4 md:my-0 md:w-9/12 md:px-8">
        <div className="border">
          <div className="py-4 px-4 text-lg font-semibold border-t-2 border-yellow-700">
            Orders
          </div>
          <hr />
          <div className="overflow-auto bg-white shadow-lg p-4">
            {sortedOrders && sortedOrders.length > 0 ? (
              sortedOrders.map((item, i) => {
                return <TableBody key={i} order={item} />;
              })
            ) : (
              <tr>
                <td colSpan="8" className="text-xl text-center font-semibold py-8">
                  No order found
                </td>
              </tr>
            )}

            <div className="text-sm text-gray-600 mt-2">
              Total {sortedOrders && sortedOrders.length} order found
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const UserOrders = (props) => {
  return (
    <Fragment>
      <Layout children={<OrdersComponent />} />
    </Fragment>
  );
};

export default UserOrders;
