import React, { Fragment } from "react";
import { Layout2 } from "../layout";
import { CheckoutComponent } from "./CheckoutProducts";

const CheckoutPage = (props) => {
  return (
    <Fragment>
      <Layout2 children={<CheckoutComponent />} />
    </Fragment>
  );
};

export default CheckoutPage;
