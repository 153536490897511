
import React, { Fragment, useState,useEffect } from 'react';
import { changePassword, validateForgotPasswordToken, generateLink } from './fetchApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function ForgotPassword() {
  

  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  let history = useHistory();

  useEffect(() => {
    // Get the query string from the URL
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token'); // Access the 'token' parameter

    if (token) {
      setToken(token.replace(/['"]+/g, '')); // Remove any quotes if present
    }
  }, []);

  const validateToken = async()=>{
    const res = await validateForgotPasswordToken(token)
    if(res && res.error){
        alert(res.error)
        setToken('')
    history.push("/resetPassword")
    }else if(res && res.success){
        setEmail(res.userEmail)
    }
    
  }

  useEffect(()=>{
    validateToken()
  },[token])

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic (e.g., send email to the backend)
    if(!email || !password || !newPassword){
        alert("Please fill all the fields")
        return;
    }
    if(password!==newPassword){
        alert("Passwords doesnt match")
        return;
    }

    const data = {
             "uniqueId" :token,
             "userEmail" :   email,
             "newPassword" : newPassword
        }
    const res = await changePassword(data)
    if(res && res.error){
        alert(res.err);
    }
    else{
        alert(res.success + " Please login once again");
        history.push("/home")
    }

  };

  const handleGenerateLink = async(e)=>{
    e.preventDefault()
    const data = {
        userEmail : email,
    }
    const res = await generateLink(data);
    alert("Reset link sent to your mail");
    history.push("/home")
  }

  return (
    <Fragment>

    {token==''?
    <div className="forgot-password-container" style={containerStyle}>
    <h1>Forgot Password</h1>
    <form onSubmit={handleGenerateLink}  style={formStyle}>
      <div style={inputGroupStyle}>
        <label htmlFor="email" style={labelStyle}>Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          style={inputStyle}
        />
      </div>
      <button type="submit" style={buttonStyle} >Send Link</button>
    </form>
  </div>:
  <div className="reset-password-container" style={containerStyle}>
  <h1>Reset Password</h1>
  <form onSubmit={handleSubmit} style={formStyle}>
    {/* Email Field */}
    <div style={inputGroupStyle}>
      <label htmlFor="email" style={labelStyle}>Email</label>
      <input
        type="email"
        id="email"
        name="email"
        value={email}
        style={emailInputStyle}
        readOnly={true}
      />
    </div>

    {/* Password Field */}
    <div style={inputGroupStyle}>
      <label htmlFor="password" style={labelStyle}>New Password</label>
      <input
        type="password"
        id="password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        style={inputStyle}
      />
    </div>

    {/* New Password Field */}
    <div style={inputGroupStyle}>
      <label htmlFor="newPassword" style={labelStyle}>Retype Password</label>
      <input
        type="password"
        id="newPassword"
        name="newPassword"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        required
        style={inputStyle}
      />
    </div>

    {/* Submit Button */}
    <button type="submit" style={buttonStyle} onSubmit={(e)=>handleSubmit(e)}>Submit</button>
  </form>
</div>}
    
    </Fragment>
  );
}

// Inline CSS styles
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: '#f7f7f7',
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '300px',
  backgroundColor: '#fff',
  padding: '20px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '10px',
};

const inputGroupStyle = {
  marginBottom: '15px',
};

const labelStyle = {
  marginBottom: '5px',
  fontSize: '16px',
  fontWeight: 'bold',
};

const inputStyle = {
  padding: '10px',
  fontSize: '14px',
  borderRadius: '5px',
  border: '1px solid #ccc',
  width: '100%',
};

const emailInputStyle = {
    padding: '10px',
  fontSize: '14px',
  borderRadius: '5px',
  width: '100%',
  backgroundColor:'#eee',
  cursor: 'not-allowed'
}

const buttonStyle = {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  export default ForgotPassword;