import React, { Fragment, createContext, useReducer } from "react";

import AdminLayout from "../layout";
import CouponMenu from "./CouponMenu";
/* This context manage all of the products component's data */


const CouponComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <CouponMenu />
    </div>
  );
};

const StoreCoupons = (props) => {
  return (
    <Fragment>
      <AdminLayout children={<CouponComponent />} />
    </Fragment>
  );
};

export default StoreCoupons;
