import React, { Fragment, useContext, useState,useEffect } from 'react';
import axios from 'axios';
import { affiliateLinkForProduct, getAffiliateDetails } from './FetchApi';
import './style.css';
import { LayoutContext } from '..';

const AffiliateLinkGenerator = () => {
  const [productId, setProductId] = useState('');
  const [affiliateid, setAffiliateId] = useState('');
  const [affiliateLink, setAffiliateLink] = useState('');
  const [showDashboard, setShowDashboard] = useState(false);
  const [totalCommission, setTotalCommission] = useState('')
  const { data, dispatch } = useContext(LayoutContext);
  const { affiliateId } = data;


  const generateAffiliateLink = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem("jwt")).user._id
      const extractedProductId = productId.split('/').pop();
      const responseData = await affiliateLinkForProduct(extractedProductId, userId);
      if (responseData && responseData.affiliateLink) {
        setAffiliateLink(responseData.affiliateLink);
        setAffiliateId(responseData.userId);
      } else {
        alert(`Product ID not found`);
      }
    } catch (error) {
      console.error('Error generating affiliate link:', error);
    }
  };


  const AffiliateDetails = async () => {
    try {
      const responseData = await getAffiliateDetails(affiliateid)
      if (responseData) {
        setShowDashboard(true);
        //setTotalCommission(responseData.totalCommission)
        console.log("Details : ",responseData);
        dispatch({ type: "affiliateId", payload: responseData })
      } else {
        //alert('Failed to fetch affiliate details');
      }

    } catch (error) {
      console.error('Error fetching affiliate details:', error);
    }
  };
  useEffect(() => {
    if (Array.isArray(affiliateId)) {
      const total = affiliateId.reduce((acc, affiliate) => acc + affiliate.monthlyCommission, 0);
      setTotalCommission(total);
    }
  }, [affiliateId]);

  return (
    <div className="container">
      <h1>Affiliate Link Generator</h1>
      <input
        type="text"
        placeholder="Enter Product Link"
        //onChange={handleInputChange}
        value={productId}
        onChange={(e) =>
          setProductId(e.target.value)}

      />
      <button onClick={generateAffiliateLink}>Generate Link</button>
      {affiliateLink && (
        <div className="affiliate-link">
          <p>Affiliate Link:</p>
          <a href={affiliateLink} target="_blank" rel="noopener noreferrer">
            {affiliateLink}
          </a>
        </div>
      )}
      <button className="track-button" onClick={AffiliateDetails}>Track Affiliate Link</button>
      {showDashboard && (
        <div className="dashboard-container">
          <h1>Affiliate Dashboard</h1>
          <table className="affiliate-table">
            <thead>
              <tr>
                <th>Product ID</th>
                <th>Total orders</th>
                <th>Monthly Orders</th>
                <th>Monthly Commission</th>
              </tr>
            </thead>
            <tbody>
              {affiliateId && affiliateId.map((affiliate) => {
                return (<Fragment>
                  <tr>
                    <td>{affiliate.pId._id}</td>
                    <td>{affiliate.count}</td>
                    <td>{affiliate.monthlyOrders}</td>
                    <td>{affiliate.monthlyCommission}</td>
                  </tr>

                </Fragment>)})
              }
            </tbody>
          </table>
          <p className="total-commission">Total commission earned this month: {totalCommission}</p>
        </div>
      )}

    </div>
  );
};

export default AffiliateLinkGenerator;