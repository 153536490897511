import React, { Fragment, createContext} from "react";
import { Navber, Footer, CartModal } from "../partials";
import LoginSignup from "../auth/LoginSignup";
import "./style.css";
import Navber2 from "../partials/Navbar2";


export const LayoutContext = createContext();

const Layout = ({ children }) => {
  return (
    <Fragment>
      <div className="wrapper">
        <Navber />
        <LoginSignup />
        <CartModal />
        {/* <CatBar/> */}
        {/* All Children pass from here */}
        
        {children}
      </div>
      <Footer />
    </Fragment>
  );
};

export default Layout;

export const Layout1 = ({ children }) => {
  return (
    <Fragment>
      <div> 
        <Navber2 />
        <LoginSignup />
        <CartModal />
        {/* All Children pass from here */}
        {children}
        
      </div>
      
      <Footer/>
    </Fragment>
  );
};
export const Layout2 = ({ children }) => {
  return (
    <Fragment>
      <div className="wrapper">
        <Navber />
        <LoginSignup />
        <CartModal />

        {/* All Children pass from here */}
        
        {children}
        <div className="push"></div>
      </div>
      <Footer />
    </Fragment>
  );
};

