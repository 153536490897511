import React, { useContext, useState, useEffect } from "react";
import { Menu, MenuItem, Button, CircularProgress } from '@material-ui/core';
import { LayoutContext } from "../layout";
import { getCouponByStore } from "./FetchApi";
import { totalCost } from "../partials/Mixins";
// import Dropdown from "./Dropdown"; // Import the Dropdown component
import "./couponModal.css";

const CouponModal = ({ show, onClose, storeId }) => {
  const { data, dispatch } = useContext(LayoutContext);

  const totalCostPayable = totalCost(data);
  let storeIds = Object.keys(data.storeWiseProducts);
  storeIds.push('000000000000100000000000');

  const handleRemoveCoupon = (storeId) => {
    dispatch({
      type: "removeCoupon",
      storeId
    });
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" >
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>Close</button>
        <div className="modal-content">
          {
            storeIds.map((storeId) => {
              return (data.appliedCoupons[storeId] === undefined ?
                (<DropdownComponent storeId={storeId} data={data} dispatch={dispatch} />) :
                (<div>
                  {storeId}:{data.appliedCoupons[storeId].couponName}
                  <button onClick={() => handleRemoveCoupon(storeId)} className="text-red-500">
                    X
                  </button>
                </div>)
              )

            })
          }

        </div>
      </div>
    </div>
  );
};

export default CouponModal;



const DropdownComponent = ({ storeId, data, dispatch }) => {
  const [storeCoupons, setStoreCoupons] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCoupons = async (storeId) => {
    setLoading(true);
    try {
      const responseData = await getCouponByStore(storeId);
      setStoreCoupons(responseData);
    } catch (error) {
      console.error('Error fetching coupons:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCouponClick = (coupon) => {
    dispatch({ type: "applyCoupon", storeId, storeCoupon: coupon });

    if (storeId !== '000000000000100000000000') {
      dispatch({
        type: "applyCouponToStoreWiseProducts",
        storeId,
        price: data.storeWiseProducts[storeId] - (coupon.applicableDiscountType === "percentage"
          ? (data.storeWiseProducts[storeId] * coupon.applicableDiscountValue / 100)
          : coupon.applicableDiscountValue),
      });
    } else {
      let count = 0;
      const auxData = data.storeWiseProducts;

      if (coupon.applicableDiscountType === "percentage") {
        for (const key in auxData) {
          if (auxData[key] !== undefined) count++;
          auxData[key] = auxData[key] - (auxData[key] * coupon.applicableDiscountValue / 100);
        }
      } else {
        for (const key in auxData) {
          if (auxData[key] !== undefined) auxData[key] = auxData[key] - (coupon.applicableDiscountValue / count);
        }
      }

      dispatch({ type: "storeWiseProducts", payload: auxData });
    }
  }

  useEffect(() => {
    getCoupons(storeId);
  }, [storeId]);

  return (
    <div>
      <Button
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
      >
        {storeId}
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={24} />
          </MenuItem>
        ) : storeCoupons.length > 0 ? (
          storeCoupons.map(coupon => (

            <MenuItem
              key={coupon._id}

            >
              <div className="coupon-item">
                {coupon.applicableMinimumAmount < (storeId != '000000000000100000000000' ? data.storeWiseProducts[storeId] : totalCost(data)) ?
                  <Button className="coupon-name" onClick={() => {
                    handleCouponClick(coupon);
                    handleClose();
                  }}>{coupon.couponName}</Button> :
                  <div>{coupon.couponName}</div>
                }

                <div className="coupon-description">{coupon.couponDescription}</div>
                <div>Minimum order: {coupon.applicableMinimumAmount}</div>
                <div>
                  {coupon.applicableDiscountType === "percentage"
                    ? `${coupon.applicableDiscountValue}% off`
                    : `${coupon.applicableDiscountValue} rs off`}
                </div>
              </div>
            </MenuItem>)
          )
        ) : (
          <MenuItem>No coupons available</MenuItem>
        )}
      </Menu>
    </div>
  );
};