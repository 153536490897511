import React, { Fragment, useContext, useEffect } from "react";
import { deleteStore, getAllStore } from "./FetchApi";
import { StoreContext } from "./index";
import moment from "moment";


const AllStore = (props) => {
  const { data, dispatch } = useContext(StoreContext);
  const { stores, loading } = data;

  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async () => {
    dispatch({ type: "loading", payload: true });
    let responseData = await getAllStore();
    setTimeout(() => {
      if (responseData && responseData.Stores) {
        dispatch({
          type: "fetchStoreAndChangeState",
          payload: responseData.Stores,
        });
        dispatch({ type: "loading", payload: false });
      }
    }, 1000);
  };

  const deleteStoreReq = async (sId) => {
    let deleteS = await deleteStore(sId);
    if (deleteS.error) {
      console.log(deleteS.error);
    } else if (deleteS.success) {
      console.log(deleteS.success);
      fetchData();
    }
  };

  /* This method call the editmodal & dispatch Store context */
  const editStore = ( sId, store, type) => {
    if (type) {
      dispatch({
        type: "editStoreModalOpen",
        store: {...store, sId:sId}
      }); 
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="col-span-1 overflow-auto bg-white shadow-lg p-4">
        <table className="table-auto border w-full my-2">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Store</th>
              <th className="px-4 py-2 border">Store Section</th>
              <th className="px-4 py-2 border">Description</th>
              <th className="px-4 py-2 border">Image</th>
              <th className="px-4 py-2 border">Status</th>
              <th className="px-4 py-2 border">Admin</th>
              <th className="px-4 py-2 border">Section</th>
              <th className="px-4 py-2 border">Created at</th>
              <th className="px-4 py-2 border">Updated at</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {stores && stores.length > 0 ? (
              stores.map((item, key) => {
                return (
                  <StoreTable
                    store={item}
                    editStore={(sName, sId, sImage, url, type, des, admin, sSection, status) =>
                      editStore(sName, sId, sImage, url, type, des, admin, sSection, status)
                    }
                    deleteStore={(sId) => deleteStoreReq(sId)}
                    key={key}
                  />
                );
              })
            ) : (
              <tr>
                <td
                  colSpan="7"
                  className="text-xl text-center font-semibold py-8"
                >
                  No Store found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="text-sm text-gray-600 mt-2">
          Total {stores && stores.length} Store found
        </div>
      </div>
    </Fragment>
  );
};

/* Single Store Component */
const StoreTable = ({ store, deleteStore, editStore }) => {
  return (
    <Fragment>
      <tr>
        <td className="p-2 text-left">
          {store.sName.length > 20
            ? store.sName.slice(0, 20) + "..."
            : store.sName}
        </td>
        <td className="p-2 text-left">
          {store.sSection.ssName.length > 20
            ? store.sSection.ssName.slice(0, 20) + "..."
            : store.sSection.ssName}
        </td>
        <td className="p-2 text-left">
          {store.sDescription.length > 30
            ? store.sDescription.slice(0, 30) + "..."
            : store.sDescription}
        </td>
        <td className="p-2 text-center">
          <img
            className="w-12 h-12 object-cover object-center"
            src={`${store.url}`}
            alt=""
          />
        </td>
        <td className="p-2 text-center">
          {store.sStatus === "Active" ? (
            <span className="bg-green-200 rounded-full text-center text-xs px-2 font-semibold">
              {store.sStatus}
            </span>
          ) : (
            <span className="bg-red-200 rounded-full text-center text-xs px-2 font-semibold">
              {store.sStatus}
            </span>
          )}
        </td>
        <td className="p-2 text-left">
          {store.Admin.length > 30
            ? store.Admin.slice(0, 30) + "..."
            : store.Admin.fullname}
        </td>
        <td className="p-2 text-left">

          {store.sSection ?store.sSection.ssName.length > 15
            ? store.sSection.ssName.slice(0, 15) + "..."
            : store.sSection.ssName : ""}
        </td>
        <td className="p-2 text-center">
          {moment(store.createdAt).format("lll")}
        </td>
        <td className="p-2 text-center">
          {moment(store.updatedAt).format("lll")}
        </td>
        <td className="p-2 flex items-center justify-center">
          <span
            onClick={(e) =>
              editStore(
                store._id,
                store,
                true
              )
            }
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1"
          >
            <svg
              className="w-6 h-6 fill-current text-green-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
              <path
                fillRule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span
            onClick={(e) => deleteStore(store._id)}
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1"
          >
            <svg
              className="w-6 h-6 fill-current text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </td>
      </tr>
    </Fragment>
  );
};

export default AllStore;
