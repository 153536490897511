export const testimonialState = {
  testimonial: [],
  addTestimonialModal: false,
  editTestimonialModal: {
    modal: false,
    tId: null,
    des: "",
  },
  loading: false,
};

export const testimonialReducer = (state, action) => {
  switch (action.type) {
    /* Get all testimonial */
    case "fetchTestimonialAndChangeState":
      return {
        ...state,
        testimonials: action.payload,
      };
    /* Create a testimonial */
    case "addTestimonialModal":
      return {
        ...state,
        addTestimonialModal: action.payload,
      };
    /* Edit a Testimonial */
    case "editTestimonialModalOpen":
      return {
        ...state,
        editTestimonialModal: {
          modal: true,
          tId: action.tId,
          des: action.des,
        },
      };
    case "editTestimonialModalClose":
      return {
        ...state,
        editTestimonialModal: {
          modal: false,
          tId: null,
          des: "",
        },
      };
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
