import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

export const affiliateLinkForProduct = async (productId,userId) => {
    let data = {productId: productId, userId: userId};
    try {
      let res = await axios.post(`${apiURL}/api/affiliate/generate-affiliate-link`, data);  
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const getAffiliateDetails = async (affiliateId) => {
    try {
      let res = await axios.post(`${apiURL}/api/affiliate/get-affiliate-details`, {affiliateId},
      );
      console.log("fetch api aff fetch : ",res);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  export const setAffiliateData = async(pId,affiliateId,pPrice) => {
    let data = {pId: pId, affiliateId:affiliateId, pPrice:pPrice};
    try {
      const res = await axios.post(`${apiURL}/api/affiliate/set-affiliate-data`, data)
      console.log("Commission : ", res);
      return res.data;
    } catch (error) {
        console.log(error);
    }
  };