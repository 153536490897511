import axios from "axios";
import { getSingleProduct } from "../../market/productDetails/FetchApi";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getAllProduct = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/product/all-product`, { withCredentials: true });
    // console.log("all products" , res.data);
    return res.data;
  } catch (error) {
    // console.log(error);
  }
};

export const createPorductImage = async ({ pImage }) => {
  /* Most important part for uploading multiple image  */
  let formData = new FormData();
  for (const file of pImage) {
    formData.append("pImage", file);
  }
  /* Most important part for uploading multiple image  */
};

export const createProduct = async ({
  pName,
  pDescription,
  pImage,
  pStatus,
  pCategory,
  similarProducts,
  pStore,
  pQuantity,
  pPrice,
  pOffer,
}) => {
  /* Most important part for uploading multiple image  */ 
  let formData = new FormData();
  for (const file of pImage) {
    formData.append("pImage", file);
  }
  /* Most important part for uploading multiple image  */
  formData.append("pName", pName);
  formData.append("pDescription", pDescription);
  formData.append("pStatus", pStatus);
  formData.append("similarProducts", similarProducts);
  formData.append("pCategory", pCategory);
  formData.append("pStore", pStore);
  formData.append("pQuantity", pQuantity);
  formData.append("pPrice", pPrice);
  formData.append("pOffer", pOffer);

  try {
    let res = await axios.post(`${apiURL}/api/product/add-product`, formData, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editProduct = async (product) => {
  /* Most important part for updating multiple image  */
  let formData = new FormData();
  if (product.pEditImages) {
    for (const file of product.pEditImages) {
      formData.append("editImages", file);
    }
  }
  // else{
  //   for (const file of product.pImages) {
  //     formData.append("pImages", file);
  //   }
  // }
  /* Most important part for updating multiple image  */
  formData.append("pId", product.pId);
  formData.append("pName", product.pName);
  formData.append("pDescription", product.pDescription);
  formData.append("pStatus", product.pStatus);
  formData.append("pStore", product.pStore._id);
  formData.append("pQuantity", product.pQuantity);
  formData.append("similarProducts", product.similarProducts);
  formData.append("pPrice", product.pPrice);
  formData.append("pOffer", product.pOffer);
  formData.append("pImages", JSON.stringify(product.pImages));
  formData.append("pCategory", product.pCategory._id);
  try {
    let res = await axios.post(`${apiURL}/api/product/edit-product`, formData, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const linkProduct = async (products, origin) => {
  try {
    let productsArray = JSON.parse(products);
    console.log("line_111:",origin);
    console.log("line_102:", productsArray);

    for (let product of productsArray) {
      console.log("here_111");
      let parsedProduct = product;
      console.log("line_111:",product);
      let responseData = await getSingleProduct(parsedProduct.id);

      if (responseData && responseData.Product) {
        responseData.Product.similarProducts = JSON.parse(responseData.Product.similarProducts || "[]");
        if (responseData.Product.similarProducts.length > 0) {
          console.log("line_161:", responseData.Product.similarProducts);
          
          for (let product1 of productsArray) {
            let flag = 1;
            let parsedProduct1 = product1;

            if (parsedProduct1.id === parsedProduct.id) {
              console.log("line_116");
            } else {
              for (let product2 of responseData.Product.similarProducts) {
                let parsedProduct2 = product2;
                if (parsedProduct2.id === parsedProduct1.id) {
                  flag = 0;
                  break;
                }
              }
              if (flag) {
                responseData.Product.similarProducts.push(parsedProduct1);
              }
            }
          }
        }
        console.log("line_140:",typeof(responseData.Product.similarProducts));

        responseData.Product.similarProducts.push(origin);
        responseData.Product.similarProducts = JSON.stringify(responseData.Product.similarProducts);

        responseData.Product = {
          ...responseData.Product,
          pId: responseData.Product._id,
        };
        
        let responseData1 = await editProduct(responseData.Product);
        console.log("edit_response:", responseData1);
      }
    }
  } catch (error) {
    console.error("Error in linkProduct:", error);
  }
};


export const deleteProduct = async (pId) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/delete-product`, { pId }, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const productByCategory = async (catId) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/product-by-category`, { catId}, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const productByPrice = async (price) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/product-by-price`, {
      price,
    }, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};


