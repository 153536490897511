export const storeSectionState = {
  storeSections: [],
  addStoreSectionModal: false,
  editStoreSectionModal: {
    modal: false,
    name: "",
    ssId: null,
    des: "",
    status: "",
  },
  loading: false,
};

export const storeSectionReducer = (state, action) => {
  switch (action.type) {
    /* Get all section */
    case "fetchStoreSectionAndChangeState":
      return {
        ...state,
        storeSections: action.payload,
      };
    /* Create a Section */
    case "addStoreSectionModal":
      return {
        ...state,
        addStoreSectionModal: action.payload,
      };
    /* Edit a Section */
    case "editStoreSectionModalOpen":
      return {
        ...state,
        editStoreSectionModal: {
          modal: true,
          name: action.name,
          ssId: action.ssId,
          des: action.des,
          status: action.status,
        },
      };
    case "editStoreSectionModalClose":
      return {
        ...state,
        editStoreSectionModal: {
          modal: false,
          name: "",
          ssId: null,
          des: "",
          status: "",
        },
      };
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
