import React, { Fragment, useContext, useState, useEffect } from "react";
import Select from "react-select";
import { createCoupon } from "./FetchApi";



const AddCouponDetail = ({ showModal, setShowModal }) => {


    const storeId = '000000000000100000000000'
    const [checkedIndividualLimit, setCheckedIndividualLimit] = useState(true)
    const [checkedCouponLimit, setCheckedCouponLimit] = useState(true)
    const [checkedMinimumAmount, setCheckedMinimumAmount] = useState(true);

    const discountTypeOptions = [
        { value: 'percentage', label: 'Percentage' },
        { value: 'fixed', label: 'Fixed' }
    ]
    const handleDiscountTypeChange = (selectedOption) => {

        setCdata({
            ...cData,
            error: false,
            success: false,
            cpDiscountType: selectedOption.value,
        });

    };

    const handleMaximumRedemptionByUserCheckbox = () => {
        setCheckedIndividualLimit(!checkedIndividualLimit)
        if (checkedIndividualLimit) {
            setCdata({
                ...cData,
                error: false,
                success: false,
                cpMaximumRedemptionByIndividualUser: 1000000,

            })
        }
        else {
            setCdata({
                ...cData,
                error: false,
                success: false,
                cpMaximumRedemptionByIndividualUser: 0,

            })
        }

    }

    const handleMaximumCouponRedemption = () => {
        setCheckedCouponLimit(!checkedCouponLimit);
        if (checkedCouponLimit) {
            setCdata({
                ...cData,
                error: false,
                success: false,
                cpMaximumRedemptionsPossible: 10000000,

            })
        }

        else {
            setCdata({
                ...cData,
                error: false,
                success: false,
                cpMaximumRedemptionsPossible: 0,

            })
        }
    }

    const handleMinimumAmount = () => {
        setCheckedMinimumAmount(!checkedMinimumAmount);
        setCdata({
            ...cData,
            error: false,
            success: false,
            cpMinimumAmount: 0,

        })
    }

    const alert = (msg, type) => (
        <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
    );

    const [cData, setCdata] = useState({
        cpName: null,
        cpDescription: null,
        cpMinimumAmount: 0,
        cpDiscountType: "percentage",
        cpDiscountValue: 0,
        //cpExpiryDate: null,
        cpMaximumRedemptionsPossible: 0,
        cpMaximumRedemptionByIndividualUser: 0,
        //cpEligibleProducts: null,
        cpStoreId: null,
        success: false,
        error: false,
    });

    const submitForm = async (e) => {
        e.preventDefault();
        cData.cpStoreId = storeId
        let responseData = await createCoupon(cData)
        if (responseData.error) {
            cData.error = true;
            console.log(responseData.error)
            alert(responseData.error, "red")
        }
        else {
            cData.error = false;
            setCdata({
                ...cData,
                cpName: '',
                cpDescription: '',
                cpMinimumAmount: 0,
                cpDiscountType: "percentage",
                cpDiscountValue: 0,
                //cpExpiryDate: null,
                cpMaximumRedemptionsPossible: 0,
                cpMaximumRedemptionByIndividualUser: 0,
                //cpEligibleProducts: null,
                cpStoreId: null,
                success: false,
                error: false,
            })
            setShowModal(false);
        }

       
    };

    return (
        <Fragment>
            <div
                onClick={(e) => setShowModal(false)}
                className={`${showModal ? "" : "hidden"
                    } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
            />

            {/* Modal Start */}
            <div
                className={`${showModal ? "" : "hidden"
                    } fixed inset-0 flex items-center z-30 justify-center overflow-auto`}
            >
                <div className="mt-32 md:mt-0 relative bg-white w-11/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4 px-4 py-4 md:px-8">
                    <div className="flex items-center justify-between w-full pt-4">
                        <span className="text-left font-semibold text-2xl tracking-wider">
                            Add Coupon
                        </span>
                        {/* Close Modal */}
                        <span
                            style={{ background: "#303031" }}
                            onClick={(e) => { setShowModal(false) }
                            }
                            className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
                        >
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </span>
                    </div>
                    <form className="w-full" onSubmit={(e) => submitForm(e)}>
                        <div className="flex space-x-1 py-4">

                            <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                                <label htmlFor="name">Coupon Name *</label>
                                <input
                                    value={cData.cpName}
                                    onChange={(e) =>
                                        setCdata({
                                            ...cData,
                                            error: false,
                                            success: false,
                                            cpName: e.target.value,
                                        })
                                    }
                                    className="px-4 py-2 border focus:outline-none"
                                    type="text"
                                />
                            </div>
                            <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                                <label htmlFor="price">Minimum Amount *</label>
                                <input
                                    disabled={!checkedMinimumAmount}
                                    value={cData.cpMinimumAmount}
                                    onChange={(e) =>
                                        setCdata({
                                            ...cData,
                                            error: false,
                                            success: false, 
                                            cpMinimumAmount: Math.round(parseFloat(e.target.value))
                                          
                                        })
                                    }
                                    type="number"
                                    className="px-4 py-2 border focus:outline-none"
                                    id="price"
                                />
                                <input type="checkbox" id="minimumAmoutCheckbox" onChange={handleMinimumAmount}></input>
                                <label for="minimumAmoutCheckbox">No maximum amount</label>
                            </div>
                        </div>
                        <div className="flex flex-col space-y-2">
                            <label htmlFor="description">Coupon Description *</label>
                            <textarea
                                value={cData.cpDescription}
                                onChange={(e) =>
                                    setCdata({
                                        ...cData,
                                        error: false,
                                        success: false,
                                        cpDescription: e.target.value,
                                    })
                                }
                                className="px-4 py-2 border focus:outline-none"
                                name="description"
                                id="description"
                                cols={5}
                                rows={2}
                            />
                        </div>
                        <div className="flex space-x-1 py-4">
                            <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                                <label htmlFor="name">Discount Type *</label>
                                <Select
                                    value={discountTypeOptions.find(
                                        (option) => option.value === cData.cpDiscountType
                                    )}
                                    onChange={handleDiscountTypeChange}
                                    options={discountTypeOptions}
                                />
                            </div>
                            <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                                <label htmlFor="price">Discount Value *</label>
                                <input
                                    value={cData.cpDiscountValue}
                                    onChange={(e) =>
                                        setCdata({
                                            ...cData,
                                            error: false,
                                            success: false, 
                                            cpDiscountValue: Math.round(parseFloat(e.target.value))
                                        })
                                    }
                                    type="number"
                                    className="px-4 py-2 border focus:outline-none"
                                    id="price"
                                />
                            </div>
                        </div>

                        <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                            <label htmlFor="price">Maximum number of redemptions of the coupon *</label>
                            <input
                                disabled={!checkedCouponLimit}
                                value={cData.cpMaximumRedemptionsPossible}
                                onChange={(e) =>
                                    setCdata({
                                        ...cData,
                                        error: false,
                                        success: false,
                                        cpMaximumRedemptionsPossible: Math.round(parseFloat(e.target.value))
                                       
                                    })
                                }
                                type="number"
                                className="px-4 py-2 border focus:outline-none"
                                id="price"
                            />
                            <input type="checkbox" id="maximumRedemptionCouponCheckbox" onChange={handleMaximumCouponRedemption}></input>
                            <label for="maximumRedemptionCouponCheckbox">No maximum limit on redemption of this coupon</label>
                        </div>
                        <div className="w-1/2 flex flex-col space-y-1 space-x-1">
                            <label htmlFor="price">Maximum number of redemptions by the user *</label>
                            <input
                                disabled={!checkedIndividualLimit}
                                value={cData.cpMaximumRedemptionByIndividualUser}
                                onChange={(e) =>
                                    setCdata({
                                        ...cData,
                                        error: false,
                                        success: false,
                                        cpMaximumRedemptionByIndividualUser: Math.round(parseFloat(e.target.value))
                                     
                                    })
                                }
                                type="number"
                                className="px-4 py-2 border focus:outline-none"
                                id="price"
                            />
                            <input type="checkbox" id="maximumRedemptionByUserCheckbox" onChange={handleMaximumRedemptionByUserCheckbox}></input>
                            <label for="maximumRedemptionByUserCheckbox">No maximum limit for user</label>
                        </div>
                        {/* TODO : Expiry date of coupons */}



                        <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
                            <button
                                onClick={submitForm}
                                style={{ background: "#303031" }}
                                type="submit"
                                className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
                            >
                                Create Coupon
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

const AddProductModal = ({ showModal, setShowModal }) => {

    return (
        <Fragment>
            <AddCouponDetail showModal={showModal} setShowModal={setShowModal} />
        </Fragment>
    );
};

export default AddProductModal;
