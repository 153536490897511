import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
    localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt")).token
        : false;
const Headers = () => {
    return {
        headers: {
            token: `Bearer ${BearerToken()}`
        },
    };
};

//add expiry date and all extra fields here
export const createCoupon = async (couponData) => {
    const data = {
        couponName: couponData.cpName,
        couponDescription: couponData.cpDescription,
        applicableStore: couponData.cpStoreId,
        applicableMinimumAmount: couponData.cpMinimumAmount,
        applicableDiscountValue: couponData.cpDiscountValue,
        applicableDiscountType: couponData.cpDiscountType,
        couponMaximumRedemptions: couponData.cpMaximumRedemptionsPossible,
        couponMaximumRedemptionByIndividualUser: couponData.cpMaximumRedemptionByIndividualUser,
        error: false

    }

    try {
        let res = await axios.post(`${apiURL}/api/coupon/add-coupon`, data, { withCredentials: true });
        return res.data
    }
    catch (err) {
        console.log("Error in fetchApi of coupon: ", err)
    }
}

export const getCouponByStore = async (storeId) => {
    const data = { storeId: storeId }
    try {
        let res = await axios.post(`${apiURL}/api/coupon/get-coupon-by-store`, data, { withCredentials: true });
        return res.data
    }
    catch (err) {
        console.log("Error in fetchApi of coupon: ", err)
    }
}

export const deleteCoupon = async (couponId) => {
    const data = { couponId: couponId }
    try {
        let res = await axios.post(`${apiURL}/api/coupon/delete-coupon`, data, { withCredentials: true });
        return res.data
    }
    catch (err) {
        console.log("Error in fetchApi of coupon: ", err)
    }
}

