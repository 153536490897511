import React, { Fragment, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { wishListProducts, getCartItems, removeProductFromWishlist } from "./FetchApi";
import { LayoutContext } from "..";
import { cartListProduct } from "../partials/FetchApi";
import { totalCost } from "../partials/Mixins";
import { addToCart } from "../productDetails/Mixins";
const apiURL = process.env.REACT_APP_API_URL;

const Product = () => {
  const history = useHistory();
  const { data: layoutData, dispatch: layoutDispatch } =
    useContext(LayoutContext);
  const [quantitiy, setQuantitiy] = useState(1);
  const [alertQ, setAlertq] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSizeIndex, setSelectedSizeIndex] = useState([]);
  const [selectedSize, setSelectedSize] = useState([])
  let sizes = null;


  //TODO :  Add size to cart which is currently not present
  //TODO :  Instead of "IN CART", make it a button or something to change the quantity there itself
  //TODO : Change the UI.

  const handleSizeChange = (event, index) => {
    const newSelectedSize = [...selectedSize];
    newSelectedSize[index] = event.target.value; // Update the size for the product at the given index
    setSelectedSize(newSelectedSize);
  };

  const checkSizeAlreadyInCart = (productId, size) => {
    return layoutData.cartItems?.some((item) => {
      return item.productId === productId && item.productSize === size;
    });
  };

  useEffect(() => {
    if (sizes && sizes.length > 0) {
      setSelectedSize(sizes[0].size); // Automatically select the first size by default
    }
  }, [sizes]);

  const fetchCartProduct = async () => {
    try {
      let cartItems = await getCartItems();
      cartItems = cartItems.cartProducts || [];
      const carts = cartItems.map((cart) => cart.productId);
      layoutDispatch({ type: "inCart", payload: carts })
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    let responseData = await wishListProducts();
    setTimeout(() => {
      if (responseData && responseData.Products) {
        setProducts(responseData.Products);
        // selectedSizeIndex =Array(products.length).fill(0) ;
        const defaultSizes = responseData.Products.map((product) => {
          const sizes = JSON.parse(product.pQuantity);
          return sizes.length > 0 ? sizes[0].size : null;
        });
        setSelectedSize(defaultSizes);
        setLoading(false);
      }
    }, 50);
    fetchCartProduct();
  };
  useEffect(() => {
    fetchData();
  }, []);

  const removeFromWishList = async (id) => {
    const responseData = await removeProductFromWishlist(id)
    fetchData();
  };
  if (loading) {
    return (
      <div className="my-32 text-2xl text-center">
        No product found in wishList
      </div>
    );
  }
  return (
    <Fragment>
      <div className="grid grid-cols-2 md:grid-cols-5">
        {products.length > 0 ? (
          products.map((product, index) => {
            sizes = JSON.parse(product.pQuantity);
            console.log("Product Quantity : ", product);
            return (
              <div
                key={product._id}
                className="test relative m-2 md:py-6 md:border-t md:border-b  md:mx-4  md:mx-0 col-span-1 md:flex "
              >
                <div className="md:items-center">
                  <img
                    onClick={(e) => history.push(`/products/${product._id}`)}
                    //onClick={(e) => history.push(`/products/${product.pName}`)}
                    className="cursor-pointer md:h-20 md:w-20 object-cover object-center"
                    src={`${product.url[0]}`}
                    alt="wishListproduct">
                  </img>
                  <div className="text-lg md:ml-6 truncate" >
                    {product.pName}
                  </div>
                </div>
                <div className="md:w-1/2 md:flex md:items-center md:justify-around">

                  <div className="font-semibold text-gray-600">
                    &#8377;{product.pPrice}
                  </div>
                </div>
                <div>
                  <select value={selectedSize[index]} onChange={(e) => handleSizeChange(e, index)}>
                    {sizes.map((size, idx) => (
                      
                      <option value={size.size} key={idx}>
                        {size.size}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="md:flex  md:justify-around">
                  <div></div>

                  {layoutData.inCart != null &&
                    layoutData.inCart.includes(product._id) === true &&
                    checkSizeAlreadyInCart(product._id,selectedSize[index]) ? (
                    <div
                      style={{ background: "#303031" }}
                      className={`px-4 py-2 text-white text-center cursor-not-allowed uppercase opacity-75`}
                    >
                      In cart
                    </div>
                  ) : (
                    <div
                      style={{ background: "#303031" }}
                      onClick={(e) => {
                        addToCart(
                          product._id,
                          quantitiy,
                          product.pPrice,
                          product.pStore,
                          selectedSize[index],
                          product.url[0],
                          product.pName,
                          layoutData,
                          layoutDispatch,
                          setQuantitiy,
                          setAlertq,
                          fetchData,
                          totalCost
                        );
                      }}
                      className="inline-block px-4 py-2 text-white text-xs md:text-base text-center cursor-pointer hover:opacity-75"
                    >
                      Add to cart
                    </div>
                  )}

                  <div
                    style={{ background: "#303031" }}
                    onClick={async (e) => await removeFromWishList(product._id)}
                    className="inline-block px-4 py-2 text-white text-xs md:text-base text-center cursor-pointer hover:opacity-75"
                  >
                    Remove
                  </div>
                </div>
                <div className="absolute top-0 right-0 mx-2 my-2 md:relative"></div>
              </div>
            );
          })
        ) : (
          <div>No product found in wishList</div>
        )}
      </div>
    </Fragment>
  );
};

const SingleWishProduct = (props) => {
  return (
    <Fragment>
      <section className="mx-4 mt-20 md:mx-12 md:mt-32 lg:mt-24">
        <div className="text-2xl mx-2 mb-6">WISHLIST</div>
        {/* Product List */}
        <Product />
      </section>
    </Fragment>
  );
};

export default SingleWishProduct;
