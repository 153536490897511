import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { LayoutContext } from "../layout";
import { CatBySec } from "../../admin/categories/FetchApi";
import LinearProgress from "@material-ui/core/LinearProgress";
import "./style.css";
import { secByStore } from '../../admin/sections/FetchApi';


const apiURL = process.env.REACT_APP_API_URL;

const CategoryList = ( props, store ) => {
  const history = useHistory();
  const { data,dispatch } = useContext(LayoutContext);
  const [sections, setSections] = useState(null);
  const [categories, setCategories] = useState([]);  
  const [isShown, setIsShow] = useState(false);
  const [loadingState, setLoadingState] = useState({});

  useEffect(() => {
    fetchData();
  }, [data.storeData._id]);
  const fetchData = async () => {
    try {
      setTimeout(async () => {
        let responseData = await secByStore(data.storeData._id);
        setTimeout(() => {
          if (responseData && responseData.Sections) {
            setSections(responseData.Sections);
          }
        }, 500);  
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategoryData = async (item, index) => {
    // Set loading state for the specific category section
    setLoadingState(prevState => ({ ...prevState, [index]: true }));
    setIsShow(index);

    try {
      let responseData = await CatBySec(item._id);
      // Map categories to their respective section index
      setCategories(prevCategories => ({...prevCategories,[index]: responseData.Categories || []}));
      setLoadingState(prevState => ({ ...prevState, [index]: false }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`${data.categoryListDropdown ? "" : "hidden"} my-4`}>
      <div className={`catBar_main`}>
        <div className="px-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3" style={{ display: "flex", justifyContent: "center" }}>
          {sections && sections.length > 0 ? (
            sections.map((item, index) => (
              <Fragment key={index}>
                <div 
                  onMouseEnter={() => fetchCategoryData(item, index)}
                  onMouseLeave={() => setIsShow(null)}
                  className='col-span-1 pl-1 items-center flex space-y-2 cursor-pointer m-auto'
                  >
                <div
                  
                  onClick={() => history.push(`${data.storeData.sName}/products/section/${item._id}`)}
                  style={{
                    backgroundImage: `url(${item.url})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                  }}
                  className="blob col-span-1 pl-1 items-center flex space-y-2 cursor-pointer m-auto"
                >
                  <div
                    className="font-medium position-absolute p-2"
                    style={{ backgroundColor: "#ffffff99", borderTopRightRadius: "15px", borderBottomRightRadius: "15px" }}
                  >
                    {item.secName}
                  </div>

                  
                </div>
                
                  {isShown === index && (
                      <div className="stores-dropdown">
                        {loadingState[index] ? (
                          <LinearProgress />
                        ) : categories[index] && categories[index].length > 0 ? (
                          categories[index].map((category, catIndex) => (
                            <Fragment key={catIndex}>
                              <div onClick={() => history.push(`${data.storeData.sName}/products/category/${category._id}`)} className="store-item">
                                {category.cName}
                              </div>
                            </Fragment>
                          ))
                        ) : (
                          <div className="no-category">No categories</div>
                        )}
                      </div>
                    )}
                </div>
              </Fragment>
            ))
          ) : (
            <div className="text-xl text-center my-4">No categories</div>
          )}
        </div>
      </div>
    </div>
  );
};

const CatBar = (props) => {
  const { data, dispatch } = useContext(LayoutContext);
  const [store, setStore] = useState();
  useEffect(() => {
    setStore(data.storeData);
  }, [data.storeData._id]);

  return (
    <Fragment>
      <div className="catBar">
        <CategoryList store={store}/>
      </div>
    </Fragment>
    
  );
};



export default CatBar;