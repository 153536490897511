import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import SectionMenu from "./SectionMenu";
import AllSections from "./AllSections";
import { sectionState, sectionReducer } from "./SectionContext";

/* This context manage all of the caregories component's data */
export const SectionContext = createContext();

const SectionComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <SectionMenu />
      <AllSections />
    </div>
  );
};

const Sections = (props) => {
  const [data, dispatch] = useReducer(sectionReducer, sectionState);
  return (
    <Fragment>
      <SectionContext.Provider value={{ data, dispatch }}>
        <AdminLayout children={<SectionComponent />} />
      </SectionContext.Provider>
    </Fragment>
  );
};

export default Sections;
