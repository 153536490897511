import React, { Fragment } from "react";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import "./changeSelectedAddressModal.css"

const ChangeSelectedAddressModal = ({ availableAddresses, selectedAddressId, setSelectedAddressId, onClose, deleteSavedAddress }) => {
    const changeSelectedAddress = (id)=> {
        setSelectedAddressId(id);
    }
    
    return (
        <div className="modal-container">
            <div className="modal-content">
            <IconButton 
                    aria-label="close"
                    onClick={()=>onClose()} 
                    style={{ position: 'absolute', right: 10, top: 10 }}
                >
                    <CloseIcon />
                </IconButton>
                <Fragment>
                    {availableAddresses ?
                        availableAddresses.map((availableAdress) => {
                            return (
                                <Card sx={{ maxWidth: 345 }} key={availableAdress._id}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {availableAdress.receiverName}
                                        </Typography>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {availableAdress.receiverContactNumber}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            {availableAdress.receiverAddress}, {availableAdress.receiverCity}, {availableAdress.receiverDistrict}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            {availableAdress.receiverPincode}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        {availableAdress._id !== selectedAddressId ? 
                                            <Button size="small" onClick={() => {
                                                changeSelectedAddress(availableAdress._id)
                                                onClose()
                                            }}>Select</Button> 
                                            : <div>Selected</div>}
                                        <Button size="small" color="error" onClick={()=>deleteSavedAddress(availableAdress._id)}>Delete</Button>
                                    </CardActions>
                                </Card>
                            )
                        })
                        : <div>No address available, please add</div>}
                </Fragment>
            </div>
        </div>
    );
}

export default ChangeSelectedAddressModal;