import React, { Fragment, createContext, useReducer,useState, useEffect,useContext } from "react";
import Navber from "../partials/Navbar";
import { useHistory,useParams } from "react-router-dom";
import { getAllProduct, productBySearch } from "../../admin/products/FetchApi";
import { LayoutContext } from "../layout";
import { isWishReq, unWishReq, isWish } from "./Mixins";
import SingleProduct from "./SingleProduct";



const SearchList = () => {
  const { data, dispatch } = useContext(LayoutContext);
  // const [products, setProducts] = useState(null);
  const{products}=data;
  const { searchProduct } = useParams();
  const history = useHistory();
  console.log("Name : ",searchProduct);

  const [wList, setWlist] = useState(
    JSON.parse(localStorage.getItem("wishList"))
  );


  // const fetchData = async () => {
  //   dispatch({ type: "loading", payload: true });
  //   try {
  //     let responseData = await productBySearch(itemName);
  //     console.log("Inside : ",itemName);
  //     setTimeout(() => {
  //     if (responseData && responseData.Products) {
  //       console.log("Data : ",responseData);
  //       dispatch({ type: "setProducts", payload: responseData.Products });
  //       dispatch({ type: "loading", payload: false });
  //     }
  //   },500);
  // }
  //   catch (error) {
  //     console.log(error);
  //   }
  // };


  const fetchData = async () => {
    dispatch({ type: "loading", payload: true });
    try {
      setTimeout(async () => {
        let responseData = await getAllProduct();
        if (responseData && responseData.Products) {
          dispatch({
            type: "searchHandleInReducer",
            payload: searchProduct,
            productArray: responseData.Products,
          });
          dispatch({ type: "loading", payload: false });
        }
      }, 700);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);



  // console.log("Data : ",data)
  console.log("Products : ",products)
  if (data.loading) {
    return (
      <div className="col-span-2 md:col-span-3 lg:col-span-5 flex items-center justify-center py-24">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  }
console.log("Products : ",data.products);

  return (
    <Fragment>
      {data.products && data.products.length > 0 ? (
        data.products.map((item, index) => {
          // if (item.pName === itemName){
            console.log("Store Name : ",item.pStore.sName);

          return (
            <Fragment key={index}>
              <div className="relative col-span-1 m-2 pr_card p-4 mt-10 ">
                <img
                  onClick={(e) => history.push(`/products/${item._id}`)}
                  className="w-full object-cover object-center cursor-pointer"
                  src={`${item.url[0]}`}
                  alt=""
                />
                <div className="flex items-center justify-between mt-2">
                  <div className="text-gray-600 font-light truncate">
                    {item.pName} by {item.pStore.sName}
                  </div>
                  <div className="flex items-center space-x-1">
                    <span>
                      <svg
                        className="w-4 h-4 fill-current text-yellow-700"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                        />
                      </svg>
                    </span>
                    <span className="text-gray-700">
                      {item.pRatingsReviews.length}
                    </span>
                  </div>
                </div>
                <div>₹{item.pPrice}.00</div>
                {/* WhisList Logic  */}
                <div className="absolute top-0 right-0 mx-2 my-2 md:mx-4">
                  <svg
                    onClick={(e) => isWishReq(e, item._id, setWlist)}
                    className={`${
                      isWish(item._id, wList) && "hidden"
                    } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </svg>
                  <svg
                    onClick={(e) => unWishReq(e, item._id, setWlist)}
                    className={`${
                      !isWish(item._id, wList) && "hidden"
                    } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                {/* WhisList Logic End */}
              </div>
            </Fragment>
          )
        // } 
          // else {
          //   <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
          //     No product found
          //   </div>
          // }
      ;
        })
      ) : (
        <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
          No product found
        </div>
      )}
    </Fragment>
  );

};



const SearchItemDisplay = () => {
  const { searchProduct } = useParams();
  return (
    <Fragment>
      <div style={{ marginBottom: "60px" }}>
        <Navber />
      </div>
      {/* Product Section */}
      {/* <section className="m-2 md:mt-12 md:my-1 p-2 pd:px-8 pd:py-2 bstslr"> */}
      <section className="m-30 md:mt-20 md:my-1 p-2 pd:py-5 bstslr">
        Step into our marketplace, where wishes meet reality amidst a myriad of
        stores!
      </section>
      <div className="text-sm flex space-x-3 m-2 md:mx-20 md:mt-8">
          <span
              className="hover:text-yellow-700 cursor-pointer text-xl font-bold"
            >
              Search results of : {searchProduct}
            </span>
    </div>
      <div>
      <section className="m-2 md:mx-4 md:my-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
          <SearchList />
      </section>
      </div>
    </Fragment>
    );
};





export default SearchItemDisplay;