export const storeState = {
  stores: [],
  addStoreModal: false,
  editStoreModal: {
    modal: false,
    sName: "",
    sId: "",
    sImage: null,
    url :"",
    des: "",
    admin: "",
    sSection: "",
    status: "",
  },
  loading: false,
};

export const storeReducer = (state, action) => {
  switch (action.type) {
    /* Get all store */
    case "fetchStoreAndChangeState":
      return {
        ...state,
        stores: action.payload,
      };
    /* Create a Store */
    case "addStoreModal":
      return {
        ...state,
        addStoreModal: action.payload,
      };
    /* Edit a Store */
    case "editStoreModalOpen":
      return {
        ...state,
        editStoreModal: {
          modal: true,
          sName: action.store.sName,
          sId: action.store.sId,
          sImage: action.store.sImage,
          url: action.store.url,
          des: action.store.sDescription,
          admin: action.store.Admin,
          sSection: action.store.sSection,
          status: action.store.sStatus,
        },
      };
    case "editStoreModalClose":
      return {
        ...state,
        editStoreModal: {
          modal: false,
          sId: null,
          sImage: null,
          url: "",
          des: "",
          status: "",
          admin: "",
          sSection: "",
          sName: "",
        },
      };
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

