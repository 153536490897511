import React, { Fragment, useContext, useEffect, useState } from "react";
import { TestimonialContext } from "./index";
import { createTestimonial, getAllTestimonials } from "./FetchApi";
import { getAllStore } from "../stores/FetchApi";

const AddTestimonialModal = (props) => {
  const { data, dispatch } = useContext(TestimonialContext);
  const [storeID, setStoreID] = useState();
  const [stores, setStores] = useState(null);

  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  const [fData, setFdata] = useState({
    tName: "",
    tDescription: "",
    tImage: "",
    storeName: "",
    success: false,
    error: false,
  });

  const fetchData = async () => {
    let responseData = await getAllTestimonials();
    if (responseData.Testimonials) {
      dispatch({
        type: "fetchTestimonialAndChangeState",
        payload: responseData.Testimonials,
      });
    }
  };


  const fetchStoreData = async () => {
    let responseData1 = await getAllStore();
    if (responseData1.Stores) {
      console.log("storesd:",responseData1.Stores)
      setStores(responseData1.Stores);
    }
  };
  useEffect(() => {
    fetchStoreData();
  }, []);

  if (fData.error || fData.success) {
    setTimeout(() => {
      setFdata({ ...fData, success: false, error: false });
    }, 2000);
  }

  const submitForm = async (e) => {
    dispatch({ type: "loading", payload: true });
    // Reset and prevent the form
    e.preventDefault();
    e.target.reset();

    if (!fData.tImage) {
      dispatch({ type: "loading", payload: false });
      return setFdata({ ...fData, error: "Please upload a User image" });
    }

    try {
      console.log("fdata:",fData);
      let responseData = await createTestimonial(fData);
      console.log("resp:", responseData);
      if (responseData.success) {
        fetchData();
        setFdata({
          ...fData,
          tName: "",
          tDescription: "",
          tImage: "",
          storeName: "",
          success: responseData.success,
          error: false,
        });
        dispatch({ type: "loading", payload: false });
        setTimeout(() => {
          setFdata({
            ...fData,
            tName: "",
            tDescription: "",
            tImage: "",
            storeName: "",
            success: false,
            error: false,
          });
        }, 2000);
      } else if (responseData.error) {
        setFdata({ ...fData, success: false, error: responseData.error });
        dispatch({ type: "loading", payload: false });
        setTimeout(() => {
          return setFdata({ ...fData, error: false, success: false });
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={(e) => dispatch({ type: "addTestimonialModal", payload: false })}
        className={`${
          data.addTestimonialModal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${
          data.addTestimonialModal ? "" : "hidden"
        } fixed inset-0 m-4  flex items-center z-30 justify-center`}
      >
        <div className="relative bg-white w-12/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4  overflow-y-auto px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Add Testimonial
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={(e) =>
                dispatch({ type: "addTestimonialModal", payload: false })
              }
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          {fData.error ? alert(fData.error, "red") : ""}
          {fData.success ? alert(fData.success, "green") : ""}
          <form className="w-full" onSubmit={(e) => submitForm(e)}>
            <div className="flex flex-col space-y-1 w-full py-4">
              <label htmlFor="name">Username of Testimonial</label>
              <input
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    tName: e.target.value,
                  })
                }
                value={fData.tName}
                className="px-4 py-2 border focus:outline-none"
                type="text"
              />
            </div>
            <div className="flex flex-col space-y-1 w-full">
              <label htmlFor="description">Testimonial Content</label>
              <textarea
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    tDescription: e.target.value,
                  })
                }
                value={fData.tDescription}
                className="px-4 py-2 border focus:outline-none"
                name="description"
                id="description"
                cols={5}
                rows={5}
              />
            </div>
            {/* Image Field & function */}
            <div className="flex flex-col space-y-1 w-full">
              <label htmlFor="name">User Image</label>
              <input
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    tImage: e.target.files[0],
                  });
                }}
                className="px-4 py-2 border focus:outline-none"
                type="file"
              />
            </div>
            <div className="w-1/2 flex flex-col space-y-1">
                <label htmlFor="store">Store Name *</label>
                <select
                  value={fData.storeName}
                  onChange={(e) =>
                    {
                      setFdata({
                        ...fData,
                        error: false,
                        success: false,
                        storeName: e.target.value,
                      });
                    }
                  }
                  name="store"
                  className="px-4 py-2 border focus:outline-none"
                  id="store"
                >
                  <option disabled value="">
                    Select a Store
                  </option>
                  {stores && stores.length > 0 
                    ? stores.map((elem) => {
                      return (
                        <option name="store" value={elem.sName} key={elem.sName}>
                          {elem.sName}
                        </option>
                      );
                    })
                    : ""}
                </select>
              </div>
            <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
              <button
                style={{ background: "#303031" }}
                type="submit"
                className="bg-gray-800 text-gray-100 rounded-full text-lg font-medium py-2"
              >
                Publish Testimonial
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddTestimonialModal;
