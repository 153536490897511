import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`
    },
  };
};


export const wishListProducts = async () => {
  let wishlistProducts = await getCartItems();
  wishlistProducts = wishlistProducts.wishlistProducts ||[];
  let productArray = wishlistProducts.map((wishlistProduct)=>wishlistProduct.productId)
  try {
    let res = await axios.post(`${apiURL}/api/product/wish-product`, {
      productArray,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCartItems = async()=>{
  try{
    let res = await axios.get(`${apiURL}/api/cart/get-cart-items`, { withCredentials: true });
    
    return res.data;
  }catch(err){
    console.log(err)
  }
}

export const removeProductFromWishlist = async (productId) =>{
  try{
    let res = await axios.post(`${apiURL}/api/cart/remove-from-wishlist`,{productId}, { withCredentials: true });
    
    return res.data;
  }catch(err){
    console.log(err)
  }
}

export const addItemToWishlist = async (productId) =>{
  try{
    let res = await axios.post(`${apiURL}/api/cart/add-wishlist`,{productId}, { withCredentials: true });
    
    return res.data;
  }catch(err){
    console.log(err)
  }
}
