import React, { Fragment } from "react";
import logo from "../images/Group 83.png"
import insta from "../images/Insta.png"
import linkedin from "../images/LinkedIn.png"


const ComingSoon = (props) => {
  return (
    <Fragment >
        <div className="bgimg">
            <div className="topleft">
            </div>
            <div className="middle1" style={{backgroundColor: 'rgba(240, 248, 255, 0.93)', borderRadius: '30px', paddingLeft: '8vw', paddingRight: '8vw', color: 'black'}}>
                <img src={logo} alt="" style={{width: '500px', paddingTop: '60px'}}/>
                <h1 style={{fontWeight: 900, fontSize: '50px',marginBottom: "20px"}}>Loading...</h1>
                <h1 style={{fontWeight: 500, fontSize: '30px', marginBottom: "20px"}}>Stay tuned!</h1>
                <hr style={{ borderColor: 'black',marginBottom: "20px"}}/>
                <h5 style={{marginBottom: "40px"}}>35 days left</h5>
            </div>
            <div className="bottom1 mt-10">
                <h4>Follow us for more update:</h4>
                <div className="flex justify-evenly w-full">
                    <a  href="https://www.instagram.com/thredit.in/" >
                        <img src={insta} alt="" style={{width: '100px', paddingTop: '10px', paddingLeft: '20px'}}/>
                    </a>
                    <a  href="https://www.linkedin.com/company/viberance-technologies/">
                        <img src={linkedin} alt="" style={{width: '100px', paddingTop: '10px', paddingLeft: '20px'}}/>
                    </a>
                </div>
            </div>
        </div>
      
    </Fragment>
  );
};

export default ComingSoon;