import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import TestimonialMenu from "./TestimonialMenu";
import AllTestimonial from "./AllTestimonials";
import { testimonialState, testimonialReducer } from "./TestimonialContext";

/* This context manage all of the Testimonials component's data */
export const TestimonialContext = createContext();

const TestimonialComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <TestimonialMenu />
      <AllTestimonial />
    </div>
  );
};

const StoreTestimonials = (props) => {
  const [data, dispatch] = useReducer(testimonialReducer, testimonialState);
  return (
    <Fragment>
      <TestimonialContext.Provider value={{ data, dispatch }}>
        <AdminLayout children={<TestimonialComponent />} />
      </TestimonialContext.Provider>
    </Fragment>
  );
};

export default StoreTestimonials;
