export const sectionState = {
  sections: [],
  addSectionModal: false,
  editSectionModal: {
    modal: false,
    name: "",
    secId: null,
    des: "",
    store: "",
    status: "",
  },
  loading: false,
};

export const sectionReducer = (state, action) => {
  switch (action.type) {
    /* Get all section */
    case "fetchSectionAndChangeState":
      return {
        ...state,
        sections: action.payload,
      };
    /* Create a Section */
    case "addSectionModal":
      return {
        ...state,
        addSectionModal: action.payload,
      };
    /* Edit a Section */
    case "editSectionModalOpen":
      return {
        ...state,
        editSectionModal: {
          modal: true,
          name: action.name,
          secId: action.secId,
          des: action.des,
          store: action.store,
          status: action.status,
        },
      };
    case "editSectionModalClose":
      return {
        ...state,
        editSectionModal: {
          modal: false,
          name: "",
          secId: null,
          des: "",
          store: "",
          status: "",
        },
      };
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
