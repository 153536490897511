import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`
    },
  };
};

//add expiry date and all extra fields here
export const createQuadrant = async (fData)=>{
    let formData = new FormData();
    formData.append("sectionName", fData.quadrant);
    formData.append("store1", fData.store1 ? fData.store1 : null);
    formData.append("store2", fData.store2 ? fData.store2 : null);
    formData.append("store3", fData.store3 ? fData.store3 : null);
    formData.append("store4", fData.store4 ? fData.store4 : null);
    try{
        let res = await axios.post(`${apiURL}/api/selectStore/add-quadrant`,formData, { withCredentials: true });
        console.log(res.data);
        return res.data
    }
    catch(err){
        console.log(err)
    }
}

export const getQuadrantStores = async ()=>{
    try{
        let res = await axios.get(`${apiURL}/api/selectStore/all-store`,{ withCredentials: true });
        return res.data
    }
    catch(err){
        console.log(err)
    }
}

export const deleteCoupon = async (couponId)=>{
    const data = {couponId : couponId}
    try{
        let res = await axios.post(`${apiURL}/api/coupon/delete-coupon`,data,{ withCredentials: true });
        return res.data
    }
    catch(err){
        console.log("Error in fetchApi of coupon: ",err)
    }
}

