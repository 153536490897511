import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getUserById = async () => {
  try {
    let res = await axios.post(`${apiURL}/api/user/signle-user`,{}, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePersonalInformationFetch = async (userData) => {
  try {
    let res = await axios.post(`${apiURL}/api/user/edit-user`, userData, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOrderByUser = async () => {
  try {
    let res = await axios.post(`${apiURL}/api/order/order-by-user`,{}, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePassword = async (formData) => {
  try {
    let res = await axios.post(`${apiURL}/api/user/change-password`, formData, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateOrder = async (oId, status) => {
  if (status == "Delivered"){
    status = "Return Requested"
  }
  else{
    status = "Cancelled"
  }
  let data = { oId: oId, status: status };
  try {
    let res = await axios.post(`${apiURL}/api/order/update-order`, data, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
}


export const getTrackingData = async (waybill) => {
  try {
    console.log(waybill);
    let res = await axios.post(`${apiURL}/api/order/track-order`, {waybill : waybill}, { withCredentials: true });
    // console.log("data",res.data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};


export const getInvoiceLink = async (invoiceKey) => {
  try {
    let res = await axios.post(`${apiURL}/api/order/get-invoice-link`, {invoiceKey : invoiceKey}, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};