import React, { Fragment, useContext, useEffect, useState } from "react";
import { SectionContext } from "./index";
import { createSection, secByStore } from "./FetchApi";
import { useParams } from "react-router-dom";
import AsyncLocalStorage from '@createnextapp/async-local-storage'

const AddSectionModal = (props) => {
  const { data, dispatch } = useContext(SectionContext);
  const {storeName} = useParams();
  const [storeId, setStoreId] = useState({});

  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  const [fData, setFdata] = useState({
    secName: "",
    secDescription: "",
    secImage: "",
    secStore: "",
    secStatus: "Active",
    success: false,
    error: false,
  });


  const fetchstoreId = async ()=>{
    setStoreId(JSON.parse(await AsyncLocalStorage.getItem(storeName))
    ? JSON.parse(await AsyncLocalStorage.getItem(storeName)).Store._id
    : "")
  }

  useEffect(() => {
   fetchstoreId();
  }, []);


  const fetchData = async () => {

    let responseData = await secByStore(storeId);
    if (responseData.Sections) {
      dispatch({
        type: "fetchSectionAndChangeState",
        payload: responseData.Sections,
      });
    }
  };

  if (fData.error || fData.success) {
    setTimeout(() => {
      setFdata({ ...fData, success: false, error: false });
    }, 2000);
  }

  const submitForm = async (e) => {
    dispatch({ type: "loading", payload: true });
    // Reset and prevent the form
    e.preventDefault();
    e.target.reset();
    fData.secStore = storeId;

    if (!fData.secImage) {
      dispatch({ type: "loading", payload: false });
      return setFdata({ ...fData, error: "Please upload a Section image" });
    }

    try {
      let responseData = await createSection(fData);
      if (responseData.success) {
        setFdata({
          ...fData,
          secName: "",
          secDescription: "",
          secImage: "",
          secStatus: "Active",
          success: responseData.success,
          error: false,
        });
        fetchData();
        dispatch({ type: "loading", payload: false });
        setTimeout(() => {
          setFdata({
            ...fData,
            secName: "",
            secDescription: "",
            secImage: "",
            secStatus: "Active",
            success: false,
            error: false,
          });
        }, 2000);
      } else if (responseData.error) {
        setFdata({ ...fData, success: false, error: responseData.error });
        dispatch({ type: "loading", payload: false });
        setTimeout(() => {
          return setFdata({ ...fData, error: false, success: false });
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={(e) => dispatch({ type: "addSectionModal", payload: false })}
        className={`${
          data.addSectionModal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${
          data.addSectionModal ? "" : "hidden"
        } fixed inset-0 m-4  flex items-center z-30 justify-center`}
      >
        <div className="relative bg-white w-12/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4  overflow-y-auto px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Add Section
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={(e) =>
                dispatch({ type: "addSectionModal", payload: false })
              }
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          {fData.error ? alert(fData.error, "red") : ""}
          {fData.success ? alert(fData.success, "green") : ""}
          <form className="w-full" onSubmit={(e) => submitForm(e)}>
            <div className="flex flex-col space-y-1 w-full py-4">
              <label htmlFor="name">Section Name</label>
              <input
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    secName: e.target.value,
                  })
                }
                value={fData.secName}
                className="px-4 py-2 border focus:outline-none"
                type="text"
              />
            </div>
            <div className="flex flex-col space-y-1 w-full
            ">
              <label htmlFor="description">Section Description</label>
              <textarea
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    secDescription: e.target.value,
                  })
                }
                value={fData.secDescription}
                className="px-4 py-2 border focus:outline-none"
                name="description"
                id="description"
                cols={5}
                rows={5}
              />
            </div>
            {/* Image Field & function */}
            <div className="flex space-x-1 py-4">
              <div className="flex flex-col space-y-1 w-full">
                <label htmlFor="name">Section Image</label>
                <input
                  accept=".jpg, .jpeg, .png, .avif"
                  onChange={(e) => {
                    setFdata({
                      ...fData,
                      success: false,
                      error: false,
                      secImage: e.target.files[0],
                    });
                  }}
                  className="px-4 py-2 border focus:outline-none"
                  type="file"
                />
              </div>
              
            </div>
            
            <div className="flex flex-col space-y-1 w-full">
              <label htmlFor="status">Section Status</label>
              <select
                name="status"
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    secStatus: e.target.value,
                  })
                }
                className="px-4 py-2 border focus:outline-none"
                id="status"
              >
                <option name="status" value="Active">
                  Active
                </option>
                <option name="status" value="Disabled">
                  Disabled
                </option>
              </select>
            </div>
            <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6 mt-4">
              <button
                style={{ background: "#303031" }}
                type="submit"
                className="bg-gray-800 text-gray-100 rounded-full text-lg font-medium py-2"
              >
                Create Section
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddSectionModal;
