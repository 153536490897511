import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const getAllSection = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/section/all-section`, { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const SecByStore = async (storeId) => {
  try {
    let res = await axios.post(`${apiURL}/api/section/section-by-store`, { storeId });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createSection = async ({
  secName,
  secImage,
  secDescription,
  secStore,
  secStatus,
}) => {
  let formData = new FormData();
  formData.append("secImage", secImage);
  formData.append("secName", secName);
  formData.append("secDescription", secDescription);
  formData.append("secStore", secStore);
  formData.append("secStatus", secStatus);

  try {
    let res = await axios.post(
      `${apiURL}/api/section/add-section`,
      formData,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editSection = async (name, secId, des, store, status) => {
  let data = {secName: name, secId: secId, secDescription: des, secStore: store, secStatus: status };
  console.log("edit_data:", data);
  try {
    let res = await axios.post(
      `${apiURL}/api/section/edit-section`,
      data,
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSection = async (secId) => {
  try {
    let res = await axios.post(
      `${apiURL}/api/section/delete-section`,
      { secId },
      { withCredentials: true }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

