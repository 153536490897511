import React, { Fragment, useContext, useState, useEffect } from "react";
import { SectionContext } from "./index";
import { editSection, getAllSection, secByStore } from "./FetchApi";
import { getAllStore } from "../../superAdmin/stores/FetchApi";

const EditSectionModal = (props) => {
  const { data, dispatch } = useContext(SectionContext);

  const [des, setDes] = useState("");
  const [status, setStatus] = useState("");
  const [secId, setSecid] = useState("");
  const [name, setName] = useState("");
  const [store, setStore] = useState("");
  
  useEffect(() => {
    setDes(data.editSectionModal.des);
    setStatus(data.editSectionModal.status);
    setSecid(data.editSectionModal.secId);
    setName(data.editSectionModal.name);
    setStore(data.editSectionModal.store);
  }, [data.editSectionModal.modal]);

  const fetchData = async () => {
    let responseData = await secByStore(data.editSectionModal.store);
    if (responseData.Sections) {
      dispatch({
        type: "fetchSectionAndChangeState",
        payload: responseData.Sections,
      });
    }
  };

  const submitForm = async () => {
    dispatch({ type: "loading", payload: true });
    let edit = await editSection(name, secId, des, store, status);
    if (edit.error) {
      console.log(edit.error);
      dispatch({ type: "loading", payload: false });
    } else if (edit.success) {
      console.log(edit.success);
      dispatch({ type: "editSectionModalClose" });
      setTimeout(() => {
        fetchData();
        dispatch({ type: "loading", payload: false });
      }, 1000);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={(e) => dispatch({ type: "editSectionModalClose" })}
        className={`${
          data.editSectionModal.modal ? "" : "hidden"
        } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${
          data.editSectionModal.modal ? "" : "hidden"
        } fixed inset-0 m-4  flex items-center z-30 justify-center`}
      >
        <div className="relative bg-white w-11/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4  overflow-y-auto px-4 py-4 md:px-8">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-2xl tracking-wider">
              Edit Section
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={(e) => dispatch({ type: "editSectionModalClose" })}
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          <div className="flex flex-col space-y-1 w-full">
            <label htmlFor="description">Section Name</label>
            <textarea
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="px-4 py-2 border focus:outline-none"
              name="name"
              id="name"
              cols={5}
              rows={1}
            />
          </div>
          <div className="flex flex-col space-y-1 w-full">
            <label htmlFor="description">Section Description</label>
            <textarea
              value={des}
              onChange={(e) => setDes(e.target.value)}
              className="px-4 py-2 border focus:outline-none"
              name="description"
              id="description"
              cols={5}
              rows={5}
            />
          </div>
          <div className="flex space-x-1 py-4 w-full">
            <div className="w-full flex flex-col space-y-1 ">
              <label htmlFor="status">Section Status</label>
              <select
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value)}
                className="px-4 py-2 border focus:outline-none"
                id="status"
              >
                <option name="status" value="Active">
                  Active
                </option>
                <option name="status" value="Disabled">
                  Disabled
                </option>
              </select>
            </div>
          </div>
          <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6">
            <button
              style={{ background: "#303031" }}
              onClick={(e) => submitForm()}
              className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
            >
              Edit Section
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditSectionModal;
