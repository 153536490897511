import React, { Fragment, useState, useContext } from "react";

const GoogleAuth = (props) => {

  const googleAuth = () =>{
    window.open(
			`${process.env.REACT_APP_API_URL}/api/google/callback`,
			"_self"
		);
    
  }

  return (
    <Fragment>
        {/* <button className="google_btn" onClick={googleAuth}>
            <span>Signin with Google</span>
          </button> */}
          <div className="google_btn">
            <a href="https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=http%3A%2F%2Flocalhost%3A8000%2Fauth%2Fgoogle&client_id=702426021026-9f4kiu9shd6l51uracr0kuea407m89b8.apps.googleusercontent.com&access_type=offline&response_type=code&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email">
              LOGIN WITH GOOGLE
            </a>
          </div>
    </Fragment>
  );
};

export default GoogleAuth;


