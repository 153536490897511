export const layoutState = {
  categoryListDropdown: true,
  searchDropdown: false,
  navberHamburger: false,
  loginSignupModal: false,
  loginSignupError: false,
  cartModal: false,
  cartProduct: [],
  cartItems: [],
  singleProductDetail: null,
  inCart: null,
  cartTotalCost: null,
  storeWiseProducts: [],
  appliedCoupons: [],
  orderSuccess: false,
  loading: false,
  filterListDropdown: false,
  products: null,
  productsLine1: null,
  productsLine2: null,
  selectStores: null,
  stores: null,
  loading: false,
  sliderImages: [],
  sliderImagesmobile: [],
  blogsData: null,
  storeData: null,
  affiliateId: null,
};

export const layoutReducer = (state, action) => {
  switch (action.type) {
    case "categoryListDropdown":
      return {
        ...state,
        categoryListDropdown: action.payload,
        filterListDropdown: false,
        searchDropdown: false,
      };
    case "searchDropdown":
      return {
        ...state,
        categoryListDropdown: false,
        filterListDropdown: false,
        searchDropdown: action.payload,
      };
    case "filterListDropdown":
      return {
        ...state,
        categoryListDropdown: false,
        filterListDropdown: action.payload,
        searchDropdown: false,
      };
    case "searchDropdown-4t":
      return {
        ...state,
        searchDropdown: action.payload,
      };
    case "setProducts":
      return {
        ...state,
        products: action.payload,
      };
    case "setProductsLine1":
      return {
        ...state,
        productsLine1: action.payload,
      };
    case "setProductsLine2":
    return {
      ...state,
      productsLine2: action.payload,
    };
    case "setStores":
      return {
        ...state,
        stores: action.payload,
      };
    case "setSelectStores":
      return {
        ...state,
        selectStores: action.payload,
      };
    case "blogsData":
      return {
        ...state,
        blogsData: action.payload,
      }
    case "searchHandleInReducer":
      return {
        ...state,
        products:
          action.productArray &&
          action.productArray.filter((item) => {
            if (
              item.pName.toUpperCase().indexOf(action.payload.toUpperCase()) !==
              -1
            ) {
              return item;
            }
            return null;
          }),
      };
    case "categoryListDropdown":
      return {
        ...state,
        categoryListDropdown: action.payload,
        filterListDropdown: false,
        searchDropdown: false,
      };
    case "hamburgerToggle":
      return {
        ...state,
        navberHamburger: action.payload,
      };
    case "loginSignupModalToggle":
      return {
        ...state,
        loginSignupModal: action.payload,
      };
    case "cartModalToggle":
      return {
        ...state,
        cartModal: action.payload,
      };
    case "cartProduct":
      return {
        ...state,
        cartProduct: action.payload,
      };
    case "cartItems":
      return {
        ...state,
        cartItems: action.payload,
      };
    case "singleProductDetail":
      return {
        ...state,
        singleProductDetail: action.payload,
      };
    case "inCart":
      return {
        ...state,
        inCart: action.payload,
      };
    case "cartTotalCost":
      return {
        ...state,
        cartTotalCost: action.payload,
      };
    case "storeWiseProducts":
      return {
        ...state,
        storeWiseProducts: action.payload,
      };

    case "applyCouponToStoreWiseProducts":
      return {
        ...state,
        storeWiseProducts: {
          ...state.storeWiseProducts,
          [action.storeId]: action.price, // Update the price for the given storeId
        },
      };

    case "applyCoupon":
      return {
        ...state,
        appliedCoupons: {
          ...state.appliedCoupons,
          [action.storeId]: action.storeCoupon

        },
      };
    case "removeCoupon":
      // Destructure the coupons, and get the coupons for the specific store
      const { [action.storeId]: removedCoupon, ...remainingCoupons } = state.appliedCoupons;

      // Remove the coupon for the specific store
      // The remainingCoupons will contain all stores except the one we are updating
      return {
        ...state,
        appliedCoupons: {
          ...remainingCoupons,
          // If there was a coupon for the store, it will be removed, otherwise nothing changes
          [action.storeId]: undefined,
        },
      };
    case "resetAppliedCoupons":
      return {
        ...state,
        appliedCoupons: action.payload,
      }

    case "loginSignupError":
      return {
        ...state,
        loginSignupError: action.payload,
      };
    case "orderSuccess":
      return {
        ...state,
        orderSuccess: action.payload,
      };
    case "sliderImages":
      return {
        ...state,
        sliderImages: action.payload,
      };
    case "affiliateId":
      return {
        ...state,
        affiliateId: action.payload,
      };
  
    case "sliderImagesMobile":
      return {
        ...state,
        sliderImagesMobile: action.payload,
      };

    case "sliderImagesMobile":
      return {
        ...state,
        sliderImagesMobile: action.payload,
      };

    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
