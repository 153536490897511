import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import StoreMenu from "./StoreMenu";
import AllStores from "./AllStores";
import { storeState, storeReducer } from "./StoreContext";

/* This context manage all of the caregories component's data */
export const StoreContext = createContext();

const StoreComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <StoreMenu />
      <AllStores />
    </div>
  );
};

const Stores = (props) => {
  const [data, dispatch] = useReducer(storeReducer, storeState);
  return (
    <Fragment>
      <StoreContext.Provider value={{ data, dispatch }}>
        <AdminLayout children={<StoreComponent />} />
      </StoreContext.Provider>
    </Fragment>
  );
};

export default Stores;
