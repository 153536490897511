import React, { Fragment, createContext, useReducer } from "react";
import SingleStore from "./Store";
import Navber from "../partials/Navbar";
import Footer from "../partials/Footer";
import { useHistory, useParams} from "react-router-dom";
import SingleStoreSection from "./SingleStoreSection";
const StoreByStoreSection = () => {
    const history = useHistory();
    const {ssName} = useParams();
  return (
    <Fragment>
      <div style={{ marginBottom: "60px" }}>
        <Navber />
      </div>
      {/* Product Section */}
      {/* <section className="m-2 md:mt-12 md:my-1 p-2 pd:px-8 pd:py-2 bstslr"> */}
      <section className="m-30 md:mt-20 md:my-1 p-2 pd:py-5 bstslr">
        Step into our marketplace, where wishes meet reality amidst a myriad of
        stores!
      </section>
      <div className="text-sm flex space-x-3 m-2 md:mx-20 md:mt-8">
          <span
              className="hover:text-yellow-700 cursor-pointer text-xl font-bold"
              onClick={(e) => history.push("/home")}
            >
              Home
              <span className="mx-2 text-xl font-bold">&#187;</span>
            </span>
            <span className="text-yellow-700 cursor-default text-xl font-bold" >
            <div>{ssName}</div>
            </span>
    </div>
    {/* <section className="md:mt-10 md:ml-20 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4"></section> */}
      <section className="md:mt:5 md:mx-10 flex justify-center items-center">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <SingleStoreSection />
        </div>
      </section>
      <div style={{ marginTop: "60px" }}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default StoreByStoreSection;
