import React from "react";

import {
  MarketHome,
  ProtectedRoute,
  AdminProtectedRoute,
  SuperAdminProtectedRoute,
  CartProtectedRoute,
  PageNotFound,
  // ProductDetails,
  ProductByCategory,
  CheckoutPage,
  WishList,
  ProductDetails,
  ComingSoon,
} from "./market";
import {  Categories, Products, Orders, Blogs, Testimonials, Stores, Users, StoreSections, GlobalCoupons, SelectStores } from "./superAdmin";
import { StoreDashboardAdmin, StoreCategories, StoreTestimonials , StoreProducts, StoreOrders, StoreBlogs, Store_Sections } from "./admin";
import { UserProfile, UserOrders, SettingUser } from "./market/dashboardUser";
import MultipleStores from "./market/home/MultipleStores";
import { BrowserRouter as Router , Switch, Route } from "react-router-dom";
import { DashboardSuperAdmin } from "./superAdmin";
import { Home2, ProductDetails_shop } from "./shop";
import Sections from "./superAdmin/sections";
import ProductByCategory_store from "./shop/home/ProductByCategory";
import StoreByStoreSection from "./market/home/StoreByStoreSection";
import StoreCoupons from "./admin/coupons";
import ProductBySection from "./shop/home/ProductBySection";
import SearchItemDisplay from "./market/home/SearchItemDisplay";
import ForgotPassword from "./market/auth/ForgotPassword";
import AffiliateLinkGenerator from "./market/affiliateService/AffiliateLinks";


/* Routing All page will be here */
const Routes = (props) => {
  return (
    <Router history={window.history} >
      <Switch>
        {/* Market & Public Routes */}
        <Route exact path="/" component={ComingSoon} />
        <Route exact path="/home" component={MarketHome} />
        <Route
          exact
          path="/stores/:storeName"
          component={Home2}
        />
        <Route exact path="/MultipleStores" component={MultipleStores} />        
        {/* Market & Public Routes end*/}

        {/* Shop & Public Routes */}

        <Route 
          exact 
          path="/stores/:storeName/products/:id" 
          component={ProductDetails_shop} 
        />

        <Route
          exact
          path="/stores/:storeName/products/category/:catId"
          component={ProductByCategory_store}
        />   
        <Route
          exact
          path="/stores/:storeName/products/section/:secId"
          component={ProductBySection}
        />         
        <Route
          exact
          path="/stores/storeSection/:ssName/:ssId"
          component={StoreByStoreSection}
        />
        {/* Shop & Public Routes */}
        {/* <Route exact path="/" component={Home} /> */}
        <Route exact path="/wish-list" component={WishList} />
        <Route exact path="/products/:id" component={ProductDetails} />
        <Route exact path="/searchItems/:searchProduct" component={SearchItemDisplay} /> 
        <Route
          exact
          path="/products/category/:catId"
          component={ProductByCategory}
        />
        <Route
        path="/resetPassword/"
        component={ForgotPassword}
        />
        <CartProtectedRoute
          exact={true}
          path="/checkout"
          component={CheckoutPage}
        />
        {/* Shop & Public Routes End */}

        {/* Super Admin Routes */}
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard"
          component={DashboardSuperAdmin}
        />
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard/coupon"
          component={GlobalCoupons}
        />
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard/selectstores"
          component={SelectStores}
        />
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard/store_sections"
          component={StoreSections}
        />
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard/stores"
          component={Stores}
        />
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard/categories"
          component={Categories}
        />
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard/sections"
          component={Sections}
        />
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard/testimonials"
          component={Testimonials}
        />
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard/blogs"
          component={Blogs}
        />
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard/users"
          component={Users}
        />
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard/products"
          component={Products}
        />
        <SuperAdminProtectedRoute
          exact={true}
          path="/super_admin/dashboard/orders"
          component={Orders}
        />
        {/* Super Admin Routes end*/}

        

        {/* Admin Routes */}
        <AdminProtectedRoute
          exact={true}
          path="/stores/:storeName/admin/dashboard"
          component={StoreDashboardAdmin}
        />
        <AdminProtectedRoute
          exact={true}
          path="/stores/:storeName/admin/dashboard/categories"
          component={StoreCategories}
        />
         <AdminProtectedRoute
          exact={true}
          path="/stores/:storeName/admin/dashboard/sections"
          component={Store_Sections}
        />
        <AdminProtectedRoute
          exact={true}
          path="/stores/:storeName/admin/dashboard/testimonials"
          component={StoreTestimonials}
        />
        <AdminProtectedRoute
          exact={true}
          path="/stores/:storeName/admin/dashboard/blogs"
          component={StoreBlogs}
        />
        <AdminProtectedRoute
          exact={true}
          path="/stores/:storeName/admin/dashboard/products"
          component={StoreProducts}
        />
        <AdminProtectedRoute
          exact={true}
          path="/stores/:storeName/admin/dashboard/orders"
          component={StoreOrders}
        />

        {/* CouponRote */}
        <AdminProtectedRoute
          exact={true}
          path="/stores/:storeName/admin/dashboard/coupon"
          component={StoreCoupons}
        />
        {/* Admin Routes End */}
     

        {/* User Dashboard */}
        <ProtectedRoute
          exact={true}
          path="/user/profile"
          component={UserProfile}
        />
        <ProtectedRoute
          exact={true}
          path="/user/orders"
          component={UserOrders}
        />
        <ProtectedRoute
          exact={true}
          path="/user/setting"
          component={SettingUser}
        />
        <ProtectedRoute
          exact={true}
          path="/affiliateLinkForProduct"
          component={AffiliateLinkGenerator}
        />
        {/* User Dashboard End */}

        {/* 404 Page */}
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
