import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};

export const isAuthenticate = () =>
  localStorage.getItem("jwt") ? true : false;

export const isAdmin = async() =>{
  try {
      if(isAuthenticate()){
        let res = await axios.post(`${apiURL}/api/get-store-admin`,{},{ withCredentials: true });
        return res.data;
      }
    } catch (error) {
    console.log(error);
  }
}
 
export const isSuperAdmin = async() =>{
  try {
    let res = await axios.post(`${apiURL}/api/get-super-admin`,{},{ withCredentials: true });
    return res.data.isSuperAdmin;
  } catch (error) {
    console.log(error);
  }
}

export const loginReq = async ({ email, password }) => {
  const data = { email, password };
  try {
    let res = await axios.post(`${apiURL}/api/signin`, data,  { withCredentials: true });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const signupReq = async ({ fullname, email, password, cPassword, mobile }) => {
  const data = { fullname, email, password, cPassword, mobile };
  try {
    let res = await axios.post(`${apiURL}/api/signup`, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const validateForgotPasswordToken = async(token)=>{
  const data = {uniqueId : token};
  try{
    if(token){
    let res = await axios.post(`${apiURL}/api/forgotPassword/check-validity`, data);
    return res.data
    }
  }catch(err){
    if(err.response && err.response.status ===403){
      return {error:"Not valid token"}
    }
    console.log(err)
  }
}

export const changePassword = async(data)=>{
  try{
    if(data){
    let res = await axios.post(`${apiURL}/api/forgotPassword/change-password`, data);
    return res.data
    }
  }catch(err){
    if(err.response && err.response.status ===403){
      return {error:"Something went wrong, please try after some time"}
    }
    console.log(err)
  }
}

export const generateLink = async(data)=>{
  try{
    if(data){
    let res = await axios.post(`${apiURL}/api/forgotPassword/send-reset-link`, data);
    return res.data
    }
  }catch(err){
    if(err.response && err.response.status ===403){
      return {error:"Something went wrong, please try after some time"}
    }
    console.log(err)
  }
}