import React,{ useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticate, isAdmin, isSuperAdmin } from "./fetchApi";

export const AdminProtectedRoute = ({ component: Component, ...rest }) => 
{
  const [isAdminUser, setIsAdminUser] = useState(true);
  const [storeName, setStoreName] = useState([]);
  const [loading, setLoading] = useState(true);

  const checkAdminStatus = async () => {
    try {
      const adminResponse = await isAdmin();
      if (adminResponse && adminResponse.isAdmin) {
        setIsAdminUser(true);
        setStoreName(adminResponse.storeNames)
      } else {
        setIsAdminUser(false);
      }
    } catch (error) {
      console.error("Error in fetching admin status: ", error);
      setIsAdminUser(false);
    } finally {
      setLoading(false); // Set loading to false after the check completes
    }
  };

  useEffect(() => {
    checkAdminStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Render loading indicator while checking status
  }

  return (
  <Route
    {...rest}
    render={(props) =>{
      const currentStore = props.match.params.storeName;
      if (isAdminUser && isAuthenticate()) {
        if (storeName.includes(currentStore)) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: `/stores/${storeName}/admin/dashboard`, // Redirect to authorized store's dashboard
                state: { from: props.location },
              }}
            />
          );
        }
      }
      else{
        return (
          <Redirect
            to={{
              pathname: "/user/profile",
              state: { from: props.location },
            }}
          />
        );
      }
    }}
  />
)};

export const SuperAdminProtectedRoute = ({ component: Component, ...rest }) =>{
  const [isSuperAdminUser, setIsSuperAdminUser] = useState(true);
  useEffect(() => {
    const checkSuperAdminStatus = async () => {
      try{
      const responseData = await isSuperAdmin();
      if (responseData) {
        setIsSuperAdminUser(true);
      }
      else{
        setIsSuperAdminUser(false)
      }
    }
    catch(error){
      console.error("Error in fetching super admin status: ", error);
      setIsSuperAdminUser(false);
    }
    };
    checkSuperAdminStatus();
  }, []);

  return (
  <Route
    {...rest}
    render={(props) =>
      isSuperAdminUser && isAuthenticate() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/user/profile",
            state: { from: props.location },
          }}
        />
      )
    }
  />
)
};

export default AdminProtectedRoute;
