import { getCartItems, addItemToWishlist, removeProductFromWishlist } from "../wishlist/FetchApi";

export const isWish = (id, wList) => {
  if (wList !== null && wList.includes(id) === true) {
    return true;
  }
  return false;
};

export const isWishReq = async (id) => {
    let list = await addItemToWishlist(id)
    list = list.wishlistProducts;
    console.log("WWWWWWWWWWWWWWWWWWWWWWWW :",list)
};

export const unWishReq = async ( id) => {
  let list = await removeProductFromWishlist(id)
    list = list.wishlistProducts;
};

export const nextSlide = (totalImg, slide, setSlide) => {
  if (slide === totalImg - 1 ) {
    setSlide(0);
  } else if (slide < totalImg) {
    setSlide(slide + 1);
  }
};

export const prevSlide = (totalImg, slide, setSlide) => {
  if (slide === 0) {
    setSlide(totalImg - 1);
  } else if (slide > 0 ) {
    setSlide(slide - 1);
  }
};
