import React, { Fragment } from "react";
import {Layout2} from "../layout";
import SingleWishProduct from "./SingleWishProduct";

const WishList = () => {
  return (
    <Fragment>
      <Layout2 children={<SingleWishProduct />} />
    </Fragment>
  );
};

export default WishList;
