import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getAllCategory } from "../../admin/categories/FetchApi";
import InstagramIcon from "@material-ui/icons/Instagram";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import returnIcon from "../../../images/return.png";
import cod from "../../../images/cod.png";
import delivery from "../../../images/delivery.png";
import paypal from "../../../images/paypal.png";


const apiURL = process.env.REACT_APP_API_URL;

const Footer = (props) => {
  
  const history = useHistory();
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let responseData = await getAllCategory();
      if (responseData && responseData.Categories) {
        setCategories(responseData.Categories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <footer
        style={{ background: "#303031", color: "#87898A" }}
        className="z-30 py-6 px-4 md:px-12 text-center overflow-hidden"
      >
        <div className="f1">
          <div className="f2">
            <div className="f3_title" style={{width: "10vw"}}>
              Help
            </div>
            <div className="f3">
              <div className="cursor-pointer" onClick={(e)=>{
                //history.push(``);
              }}>
                Contact Us <br/>
              </div>
              <div className="cursor-pointer" onClick={(e)=>{
                //history.push(``);
              }}>
                Privacy policy<br/>
              </div>
              <div className="cursor-pointer" onClick={(e)=>{
                //history.push(``);
              }}>
                Terms of Use<br/>
              </div>
              <div className="cursor-pointer" onClick={(e)=>{
                //history.push(``);
              }}>
                Security<br/>
              </div>
              <div className="cursor-pointer" onClick={(e)=>{
                //history.push(``);
              }}>
                Return policy <br/>
              </div>
            </div>
          </div>
          <div className="f2">
            <div className="f3_title" style={{width: "10vw"}}>
              Shop By
            </div>
            <div className="f3">
              {categories && categories.length > 0 ? (
                categories.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <div
                        onClick={(e) =>{
                          history.push(`/products/category/${item._id}`);
                          window.location.href = window.location.href;
                        }}
                        className="cursor-pointer"
                      >
                        
                        <div className="font-medium">{item.cName}</div>
                      </div>
                    </Fragment>
                  );
                })
              ) : (
                <div className="text-xl text-center my-4">No Category</div>
              )}
            </div>
          </div>
          <div className="f2">
            <div className="f3_title">
            Connect with Us
            </div>
            <div className="f3_1">
              <div className="f4 cursor-pointer" onClick={(e)=>{
                //history.push(``);
              }}>
                <InstagramIcon fontSize="large"/>
              </div>
              <div className="f4 cursor-pointer" onClick={(e)=>{
                //history.push(``);
              }}>
                <FacebookIcon fontSize="large"/>
              </div>
            </div>
            <div className="f3_1">
              <div className="f4 cursor-pointer" onClick={(e)=>{
                //history.push(``);
              }}>
                <YouTubeIcon fontSize="large"/>
              </div>
              <div className="f4 cursor-pointer" onClick={(e)=>{
                //history.push(``);
              }}>
                <TwitterIcon fontSize="large"/>
              </div>
              <div className="cursor-pointer" onClick={(e)=>{
                //history.push(``);
              }}>
                <MailOutlineIcon fontSize="large"/>
              </div>
              
            </div>
          </div>
          <div className="f2" style={{ alignItems:"center"}}>
            <div className="f3_2">
              <img src={returnIcon} alt="pic" className="returnImg"/>
              <div>
                Easy Returns <br/> within 15 days
              </div>
            </div>
            <div className="f3_2">
              <img src={delivery} alt="pic" className="returnImg"/>
              <div>
                Free Delivery for<br/> Orders {'>'}Rs.750 
              </div>
            </div>
            <div className="f3_1">
              <div >
                <img src={cod} className="returnImg"/>
              </div>
              <div >
                <img src={paypal} className="returnImg"/>
              </div>
            </div>

          </div>
        </div>
        <hr></hr>
        <div className="footerBottom">
          Develop & Design 4turtls.inc © Copyright {moment().format("YYYY")}
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
