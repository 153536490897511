export const blogState = {
  blog: [],
  addBlogModal: false,
  editBlogModal: {
    modal: false,
    bId: null,
    des: "",
  },
  loading: false,
};

export const blogReducer = (state, action) => {
  switch (action.type) {
    /* Get all Blog */
    case "fetchBlogAndChangeState":
      return {
        ...state,
        blogs: action.payload,
      };
    /* Create a Blog */
    case "addBlogModal":
      return {
        ...state,
        addBlogModal: action.payload,
      };
    /* Edit a Blog */
    case "editBlogModalOpen":
      return {
        ...state,
        editBlogModal: {
          modal: true,
          bId: action.bId,
          des: action.des,
        },
      };
    case "editBlogModalClose":
      return {
        ...state,
        editBlogModal: {
          modal: false,
          bId: null,
          des: "",
        },
      };
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
